import LayoutMobile from "../../../../components/LayoutMobile";
import { BsPlusCircle } from "react-icons/bs";
import { themeColor } from "../../../../constant/color";
import { useContext, useEffect, useState } from "react";
import Skimmer from "../../../../components/Loader/Skimmer";
import ChannelCard from "../../../../components/Channels/ChannelCard";
import { toast } from "react-hot-toast";
import Layout from "../../../../components/Layout";
import PaginationButton from "../../../../components/PaginationButton";
import axios from "../../../../utils/axios";
import BackBtn from "../../../../components/BackBtn";
import MeterCard from "../../../../components/Meter/MeterCard";
import { channelController } from "../../../../controllers/channelController";
const AllUserMeters = () => {
  const [limit, setlimit] = useState(12);
  const [offset, setoffset] = useState(0);
  const [page, setpage] = useState(1);
  const [loading, setloading] = useState(true);
  const [meter, setmeter] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let res = await axios.post("/submeter/getalluserdevices", {});
        let result = channelController(res);
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloading(false);
          return;
        }
        setmeter(result.message.body.slice(0, 20));
        setloading(false);
      } catch (err) {
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {};
  }, []);

  return (
    <Layout pageTitle={"Channels"}>
      <div className="pt-[10px] cursor-pointer ">
        <div
          className="hidden items-center justify-center border-[1px] border-secondary rounded-full p-1 "
          //   onClick={() => setaddmetermodal(true)}
        >
          <div className="">
            <BsPlusCircle size={20} color={themeColor.secondary} style={{}} />
          </div>
          <div className="text-secondary pl-2">Add meter</div>
        </div>
      </div>

      <div className="h-auto bg-white mb-[60px]">
        <div className="">
          <BackBtn text={"Go back"} />
        </div>
        <div className="px-3 py-2">
          <div className="font-semibold font-Kanit text-[17px]">
            Your meters.
          </div>
          <div className="text-gray-600 text-start font-normal">
            List of all the meter you have created
          </div>
        </div>
        <hr className="border-[0.5px] border-gray-200" />
        {loading ? (
          <div className=" p-2 grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 mt-4">
            {Array.from(Array(15)).map((_, i) => (
              <div className="h-[190px] drop-shadow-sm" key={i}>
                <Skimmer heigth={"100%"} />
              </div>
            ))}
          </div>
        ) : !meter.length ? (
          <div className="mt-[50px] flex flex-col justify-center items-center h-[80vh]">
            <div className="text-center font-semibold text-gray-700">
              You don't have any meter at the moment
            </div>
            <div
              className="w-[fit-content] mt-4 flex items-center justify-center border-[1px] border-secondary rounded-full p-1"
              // onClick={() => setaddmetermodal(true)}
            >
              <div className="">
                <BsPlusCircle
                  size={20}
                  color={themeColor.secondary}
                  style={{}}
                />
              </div>
              <div className="text-secondary pl-2">Add meter</div>
            </div>
          </div>
        ) : (
          <div className="mt-4 p-3">
            <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 ">
              {meter.map((i, index) => (
                <MeterCard key={index} data={i} />
              ))}
            </div>
            {/* <PaginationButton
              page={page}
              setoffset={setoffset}
              offset={offset}
              limit={limit}
              setlimit={setlimit}
              data={meter}
            /> */}
          </div>
        )}
      </div>
      <div className="h-[200px]"></div>
    </Layout>
  );
};

export default AllUserMeters;
