import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { NotificationFormatter } from "../../../../helperFuncs";
const CommandsTable = ({ data, loading }) => {
  const columns = [
    // {
    //   name: "Number",
    //   center: true,
    //   selector: (row) => row.id,
    //   grow: 1,
    // },
    {
      name: "Command type",
      selector: (row) => row.commandtype,
      center: true,

      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div>{NotificationFormatter(item.commandtype)}</div>
          </div>
        );
      },
    },
    {
      name: "Description",
      selector: (row) => row.command,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div>{item.command}</div>
          </div>
        );
      },
    },
    // {
    //   name: "Amount",
    //   selector: (row) => row.amount,
    //   center: true,
    //   grow: 2,
    // },
    // {
    //   name: "Description",
    //   center: true,
    //   selector: (row) => row.description,
    //   sortable: true,
    //   grow: 2,
    // },
    // {
    //   name: "Duration",
    //   center: true,
    //   selector: (row) => row.sales,
    //   sortable: true,
    //   grow: 2,
    // },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.dateissued,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[12px]">
              {" "}
              {moment(item.dateissued).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.deviceid,
    //   grow: 2,
    //   cell: (item) => {
    //     return (
    //       <div className="flex rounded-md items-center justify-between">
    //         <div className="p-1 border-[1px] border-gray-400 rounded-sm mr-3 cursor-pointer">
    //           Details
    //         </div>
    //         <div className="p-1 border-[1px] border-gray-400 rounded-sm cursor-pointer">
    //           Buy plan
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <DataTable
      style={{
        height: 400,
      }}
      progressPending={loading}
      columns={columns}
      data={data}
      pagination
      paginationPerPage={10}
    />
  );
};

export default CommandsTable;
