import Drawer from "../../../Drawer";
import { AiOutlineUser } from "react-icons/ai";
import { useState, useContext, useEffect } from "react";
import Rule from "../../../Rule/Index";
import { DatePicker, Select } from "antd";
import { BsClock } from "react-icons/bs";
import DaysSelector from "../../../Commands/DaysSelector";
import { Button } from "../../../Button";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { getToken } from "../../../../utils/token";
import { commandController } from "../../../../controllers/CommandController";
import axios from "../../../../utils/axios";
import ProcessingCard from "../../../Loader/ProcessingCard";
import { useMQTT } from "../../../../context/MqttContext";
import { BsGridFill } from "react-icons/bs";
import { ChannelContext } from "../../../../context/channelContext";
import { channelController } from "../../../../controllers/channelController";
import { FiSearch } from "react-icons/fi";
const ChannelGroups = ({
  title,
  open,
  setopen,
  caption,
  selectedchannel,
  refreshBtn,
  setselectedchannel,
}) => {
  const [email, setemail] = useState("");
  const location = useLocation();
  const [number, setnumber] = useState("");
  const [step, setstep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const { RangePicker } = DatePicker;
  const { id } = useParams();
  const [saving, setsaving] = useState(false);
  const [tab, settab] = useState(1);
  const controller = new AbortController();
  // const { getAllChannels } = useContext(ChannelContext);
  const [limit, setlimit] = useState(100);
  const [offset, setoffset] = useState(0);
  const [name, setname] = useState("");
  const [data, setdata] = useState([]);
  const [inputname, setinputname] = useState("");
  const [selectedgroup, setselectedgroup] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/submeter/getallchannels", {
          limit,
          offset,
          groupprefix:
            location.pathname == "/dashboard"
              ? ""
              : location.pathname.substring(15),
        });
        const result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        const item = result.message.body.filter(
          (item) => item?.type == "group"
        );
        setdata(item);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  const handleNew = async () => {
    try {
      let username = await getToken("spiral_username");

      let pathName = location.pathname.includes("/channel/group/")
        ? `${location.pathname
            .substring(15)
            .replaceAll("_", " ")
            .trim()}/${name.trim()}`
        : location.pathname.includes("/dashboard")
        ? `${name.trim()}`
        : name.trim();

      // console.log(pathName, "-pathName");
      setsaving(true);
      let res = await axios.post("/submeter/editchannelgroup", {
        channelid:
          selectedchannel.type == "group"
            ? selectedchannel.alias.trim()
            : selectedchannel.channelid.trim(),
        username: username,
        newgroup: pathName,
      });
      const result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setsaving(false);
        return;
      }
      toast.success(`Device added successfully.`);
      setsaving(false);
      refreshBtn((prev) => !prev);
      setopen(false);
      return;
    } catch (err) {
      toast.error(err.message);
      setsaving(false);
      return;
    }
  };

  const handleExisting = async () => {
    try {
      let username = await getToken("spiral_username");
      // let pathName = location.pathname.includes("/channel/group")
      //   ? "/" +
      //     location.pathname.substring(15).replaceAll("_", " ").trim() +
      //     "/" +
      //     selectedgroup.trim()
      //   : selectedgroup.trim();

      let pathName = location.pathname.includes("/channel/group/")
        ? `${location.pathname
            .substring(15)
            .replaceAll("_", " ")}/${selectedgroup}`
        : location.pathname.includes("/dashboard")
        ? `${selectedgroup}`
        : `${selectedgroup}`;

      // console.log(selectedchannel.channelid, "-channel details");
      // console.log(pathName, "-pathname");

      setsaving(true);

      let res = await axios.post("/submeter/editchannelgroup", {
        channelid:
          selectedchannel.type == "group"
            ? selectedchannel.channelid
            : selectedchannel.channelid,
        username: username,
        newgroup: pathName,
      });
      const result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setsaving(false);
        return;
      }
      toast.success(`Device added successfully.`);
      setsaving(false);
      refreshBtn((prev) => !prev);
      setopen(false);
    } catch (err) {
      toast.error(err.message);
      setsaving(false);
      return;
    }
  };
  // const handleAddToGroup = async () => {
  //   try {
  //     // console.log(pathName);
  //     let username = await getToken("spiral_username");

  //     let res = await axios.post("/submeter/editchannelgroup", {
  //       username,
  //       newgroup: username,
  //       channelid: selectedchannel?.channelid,
  //     });

  //     const result = channelController(res);
  //     if (result.type !== "success") {
  //       toast[result.type](result.message);
  //       return;
  //     }
  //   } catch (err) {
  //     toast.error(err.message);
  //   }
  // };

  return (
    <Drawer
      setopen={setopen}
      open={open}
      title={title}
      caption={"Add a device to a group or create a new one."}
    >
      <Rule />
      <div className="px-3 relative border-[1px] border-gray-300 rounded-md h-[85vh]">
        <div className="grid grid-cols-2 border-b-[2px] border-gray-100 mt-3">
          <div
            className={
              tab == 1
                ? "text-center py-2 cursor-pointer border-b-[2px] border-gray-800"
                : "text-center py-2 cursor-pointer"
            }
            onClick={() => settab(1)}
          >
            New group
          </div>
          <div
            className={
              tab == 2
                ? "text-center py-2 cursor-pointer border-b-[2px] border-gray-800"
                : "text-center py-2 cursor-pointer"
            }
            onClick={() => settab(2)}
          >
            Existing group
          </div>
        </div>
        {tab == 1 && (
          <div className="mt-4">
            <label className="text-gray-800 font-semibold">
              Enter new group name
            </label>
            <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
              <div className="d-inline flex items-center justify-center px-2">
                <BsGridFill
                  size={20}
                  color="text-primary"
                  className="text-primary"
                />
              </div>
              <div className="ml-2">
                <input
                  required
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  type={"text"}
                  placeholder={"enter you preferred name"}
                  className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                />
              </div>
            </div>
          </div>
        )}

        {tab == 2 && (
          <div className="">
            <div className="mt-4">
              <label className="text-gray-800 font-semibold">
                Select a group
              </label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[45px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <FiSearch
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    required
                    value={inputname}
                    onChange={(e) => setinputname(e.target.value)}
                    type={"text"}
                    placeholder={"Search for a group"}
                    className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            <div className="h-[55vh] mt-2 overflow-y-scroll">
              {!data.length ? (
                <div className="">
                  <div className=""></div>
                  <div className="font-kanit font-semibold text-center ">
                    You don't have any channel in this group
                  </div>
                </div>
              ) : !data.filter((i) =>
                  i.alias.toLowerCase().trim().includes(inputname.toLowerCase())
                ).length ? (
                <div className="">
                  <div className=""></div>
                  <div className="font-kanit font-semibold text-center mt-4">
                    You don't have any group with{" "}
                    <span className="font-semibold text-gray-700 italic">
                      "{inputname}"
                    </span>
                  </div>
                </div>
              ) : (
                data
                  .filter((i) =>
                    i.alias.toLowerCase().trim().includes(name.toLowerCase())
                  )
                  .map(({ alias, devicecount, type }, index) => (
                    <div
                      className="flex border-b-[1px] even:bg-gray-50 hover:bg-gray-100 border-gray-200 cursor-pointer p-2 my items-center justify-between"
                      key={index}
                      onClick={() => setselectedgroup(alias)}
                    >
                      <div className="">
                        <div className="font-Kanit font-semibold text-gray-800">
                          {alias}
                        </div>
                        <div className="">
                          Device count: {"  "} {devicecount}
                        </div>
                      </div>
                      <div className="">
                        {selectedgroup == alias ? (
                          <div className="w-[20px] h-[20px] rounded-full border-[1px] bg-green-400"></div>
                        ) : (
                          <div className="w-[20px] h-[20px] rounded-full border-[1px]"></div>
                        )}
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        )}
        <div className="absolute bottom-1 w-full">
          <div className="w-full">
            <Button
              text={"Add to group"}
              loading={saving}
              onClick={tab == 1 ? handleNew : handleExisting}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ChannelGroups;
