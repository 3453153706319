import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { data } from "./data";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../../utils/axios";
import { channelController } from "../../../../controllers/channelController";
import ViewPaymentPlan from "../../../PaymentPlan/Modals/ViewPaymentPlan";

const ChannelPaymentPlanTable = ({
  data,
  refreshBtn,
  open,
  setopen,
  plandetails,
  setplandetails,
}) => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [selectedplan, setselectedplan] = useState(null);
  const [modal, setmodal] = useState(false);

  const hanldeRemoveChannel = async (alias) => {
    try {
      setloading(true);
      let res = await axios.post(
        "/submeter/paymentplanassignment/removeplanfromchannel",
        {
          planalias: alias,
          channelid: id,
        }
      );
      const result = channelController(res);
      if (result.type != "success") {
        toast.error("Failed to assign plan to meter");
        setloading(false);
        return;
      }

      toast.success("Plan removed from meter successfully.");
      setloading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.err(err.message);
      setloading(false);
    }
  };
  const columns = [
    {
      name: "Plan name",
      selector: (row) => row.planalias,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div>{item.planalias}</div>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      center: true,
      grow: 2,
    },
    {
      name: "Description",
      center: true,
      selector: (row) => row.description,
      sortable: true,
      grow: 2,
    },

    {
      name: "Date created",
      center: true,
      selector: (row) => row.dateassigned,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[12px]">
              {" "}
              {moment(item.dateassigned).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-around">
            {/* <div className="p-1 border-[1px] border-gray-400 rounded-sm cursor-pointer">
              Buy plan
            </div> */}

            <div
              onClick={() => {
                setplandetails(item);
                setopen(true);
                // setselectedplan(item);
                // setmodal(true);
              }}
              className="p-1 ml-2 border-[1px] border-gray-400 rounded-sm cursor-pointer"
            >
              Buy plan
            </div>

            <div
              onClick={() =>
                loading ? null : hanldeRemoveChannel(item.planalias)
              }
              className="p-1 ml-2 text-red-400 border-[1px] border-red-400 rounded-sm mr-3 cursor-pointer"
            >
              {loading ? "Removing plan.." : "Remove plan"}
            </div>
            {/* <div className="absolute top-0  before:absolute before:z-40 bg-white rounded-md shadow-md">
              <div className="px-2 py-1 cursor-pointer hover:bg-gray-300 ">
                Plan details
              </div>
              <div className="px-2 py-1 cursor-pointer hover:bg-gray-300 ">
                Remove plan
              </div>
            </div> */}
            {/* <div className="p-1 text-red-400 ml-2 border-[1px] border-red-400 rounded-sm cursor-pointer">
              Remove plan
            </div> */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {modal && (
        <ViewPaymentPlan
          setopen={setmodal}
          open={modal}
          refreshBtn={null}
          details={selectedplan}
        />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
      />
    </>
  );
};

export default ChannelPaymentPlanTable;
