import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const PaginationButton = ({
  total,
  page,
  setpage,
  limit,
  offset,
  setoffset,
  setlimit,
  setrefresh,
  data,
}) => {
  let itemPerPage = 15;
  // const lastPage = Math.ceil(data?.length / limit);
  // const lastPage = Math.ceil(data?.length / limit);
  return (
    <div className="flex items-center justify-between mt-6">
      <div className="">
        Showing {data?.length} of {total} channels
      </div>
      <div className="flex items-center justify-end">
        {page != 1 && (
          <div
            onClick={() => {
              setoffset((prev) => prev - itemPerPage);
              setlimit((prev) => prev - itemPerPage);
              setpage((prev) => prev - 1);
              setrefresh((prev) => !prev);
            }}
            className="flex items-center justify-center border rounded-md p-2 cursor-pointer mx-2 "
          >
            <IoIosArrowBack />
            Prev
          </div>
        )}
        {/* > 15 */}
        {total > data?.length && (
          <div
            onClick={() => {
              setoffset((prev) => prev + itemPerPage);
              setpage((prev) => prev + 1);
              setrefresh((prev) => !prev);
              setlimit((prev) => prev + itemPerPage);
            }}
            className="flex items-center justify-center border rounded-md p-2 cursor-pointer mx-2"
          >
            Next
            <IoIosArrowForward />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginationButton;
