import { useState } from "react";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import CummulativeEnergyLimit from "../../Drawer/CummulativeEnergyLimit";
import DailyEnergyLimit from "../../Drawer/DailyEnergyLimit";
import { consumptionData } from "./data";
const ConsumptionModal = ({ open, setopen }) => {
  const [modal, setmodal] = useState("");
  const [type, settype] = useState("");
  return (
    <>
      {modal ? (
        <>
          {type == "del" && (
            <DailyEnergyLimit
              open={modal}
              setopen={setopen}
              title={"Daily energy limit"}
            />
          )}

          {type == "cel" && (
            <CummulativeEnergyLimit
              open={modal}
              setopen={setopen}
              title={"Cummulative energy limit"}
              caption={
                "Use the option below to set a cummulative energy limit on your channel."
              }
            />
          )}
        </>
      ) : (
        <Backdrop open={open}>
          <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
            <div className="w-[400px] bg-white drop-shadow-md rounded-sm p-3">
              <div className="flex items-start justify-content-between">
                <div className="">
                  <div className="font-Kanit font-semibold text-[17px]">
                    Control Consumption
                  </div>
                  <div className="w-[90%] text-gray-700 font-light">
                    Use any of the options below to limit energy consumption on
                    your channel <br />
                  </div>
                </div>
                <div className=" w-[200px] flex justify-end">
                  <CloseButton closeBtn={setopen} />
                </div>
              </div>
              <Rule />
              <div className="">
                {consumptionData.map(({ id, title, icon, caption, tag }) => (
                  <div
                    className="flex items-center odd:border-b-[1px] odd:border-gray-300 cursor-pointer hover:bg-gray-50 py-3 my-3"
                    key={id}
                    onClick={() => {
                      // setopen(false);
                      settype(tag);
                      setmodal(true);
                    }}
                  >
                    <div className="w-[40px] h-[40px] flex items-center justify-center">
                      {icon}
                    </div>
                    <div className="">
                      <div className="font-semibold text-gray-800">{title}</div>
                      <div className="font-light">{caption}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default ConsumptionModal;
