import moment from "moment";
import { NotificationFormatter } from "../../../../helperFuncs";
import { FcCalendar } from "react-icons/fc";

const PaymentPlanCard = ({
  data,
  plandetails,
  setplandetails,
  planmodal,
  setplanmodal,
}) => {
  return (
    <div
      className="my-2 border-b-[1px] py-2 cursor-pointer"
      onClick={() => {
        setplandetails(data);
        setplanmodal(true);
      }}
    >
      <div className="flex items-center justify-between ">
        <div className="">{data.planalias}</div>
        <div className="flex text-[13px] text-right font-light">
          <div className="flex pt-1 pr-2 items-start justify-center">
            <FcCalendar />
          </div>
          {moment(data.dateassigned).format("lll")}
        </div>
      </div>
      <div className="flex items-end mt-2 justify-between w-full">
        <div className="">
          <div className="text-[13px] font-light">Description</div>
          <div className="font-semibold">{data?.description}</div>
        </div>
        <div className="text-green-600 text-[14px] font-semibold">
          ₦{data?.amount.toLocaleString("en-US", { minimumFractionDigits: 2 })}{" "}
          <span className="underline text-[14px] text-black font-light">
            (View plan)
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlanCard;
