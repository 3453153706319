import { useState, useEffect } from "react";
import axios from "../../../../utils/axios";
import moment from "moment";
import { channelController } from "../../../../controllers/channelController";
import { toast } from "react-hot-toast";
import Rule from "../../../Rule/Index";
import CloseButton from "../../../CloseButton";
import { Button } from "../../../Button";
import Backdrop from "../../../Backdrop";
import { getToken } from "../../../../utils/token";

const RequestDetails = ({ open, setopen, details, setdetials, refreshBtn }) => {
  const [loading, setloading] = useState(false);
  const [name, setname] = useState("");
  const [alias, setalias] = useState("");
  const [showname, setshowname] = useState(false);
  const handleAccept = async () => {
    try {
      setloading(true);
      let res = await axios.post("/submeter/acceptchanneladdrequest", {
        channelid: details.channelid,
        alias: alias,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading((prev) => false);
        return;
      }
      if (result.message.response == "false") {
        toast.error("Operation failed. please try again.");
        setloading(false);
        return;
      } else {
        toast.success("Request accepted");
      }
      setopen(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };
  const handleDecline = async () => {
    try {
      setloading(true);
      let res = await axios.post("/submeter/cancelchanneladdrequest", {
        channelid: details.channelid,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading((prev) => false);
        return;
      }
      if (result.message.response == "false") {
        toast.error("Operation failed. please try again.");
        setloading(false);
        return;
      } else {
        toast.success("Request decline");
      }
      setopen(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        let username = await getToken("spiral_username");
        let res = await axios.post("/submeter/getchannelinfo", {
          username,
          channelid: details.channelid,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        console.log(res, "result,");
        // setname(result.message.body[0].devicealias);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, []);

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[400px] bg-white rounded-md p-3">
          <div className="flex items-start justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Incoming request details.
              </div>
              <div className="hidden text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className="w-[200px] flex items-center justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div className="">
            <div className="flex items-center justify-between my-2">
              <div className="">Date sent</div>
              <div className="">{moment(details.datesent).format("ll")}</div>
            </div>
            <div className="flex items-center justify-between my-2">
              <div className="">Sender</div>
              <div className="">{details.sender}</div>
            </div>
            <div className="flex items-center justify-between my-2">
              <div className="">Status</div>
              <div className="text-secondary">{details.status}</div>
            </div>
            <div className="flex items-center justify-between my-2">
              <div className="">Channel name</div>
              <div className="">{name}</div>
            </div>
            <div className="my-2">
              <div className="">Note</div>
              <div className="border-[1px] border-gray-300 rounded-md p-1 h-[100px] overflow-y-scroll">
                {details.note}
              </div>
            </div>
            {showname && (
              <div>
                <Rule />
                <div className="my-2">
                  <div className="">Assign name to channel</div>
                  <div className="border-[1px] border-gray-300 rounded-md p-1">
                    <input
                      value={alias}
                      onChange={(e) => setalias(e.target.value)}
                      required
                      type={"email"}
                      placeholder={"Enter channel name"}
                      className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px] h-[35px]"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {details.status != "accepted" && (
            <div className="w-full grid grid-cols-2 gap-4 mt-3">
              {!showname ? (
                <Button
                  onClick={handleDecline}
                  color={"tomato"}
                  text={"Decline"}
                  bg={"#fff"}
                  loading={loading}
                  border={"1px solid tomato"}
                />
              ) : (
                <Button
                  onClick={() => setshowname(false)}
                  color={"#000"}
                  text={"Back"}
                  bg={"#fff"}
                  loading={loading}
                  border={"1px solid #000"}
                />
              )}

              {!showname ? (
                <Button
                  onClick={() => setshowname(true)}
                  text={"Accept"}
                  loading={loading}
                  bg={"green"}
                />
              ) : (
                <Button
                  onClick={handleAccept}
                  text={"Continue"}
                  loading={loading}
                  bg={"green"}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default RequestDetails;
