import moment from "moment";
import { NotificationFormatter } from "../../../../helperFuncs";
import { FcCalendar } from "react-icons/fc";

const CommandCard = ({ data, tabstate, username }) => {
  return (
    <div className="my-2 border-b-[1px] py-2">
      <div className="flex items-center justify-between ">
        <div className="">{NotificationFormatter(data.commandtype)}</div>
        <div className="flex text-[13px] text-right font-light">
          <div className="flex pt-1 pr-2 items-start justify-center">
            <FcCalendar />
          </div>
          {moment(data.dateissued).format("lll")}
        </div>
      </div>
      <div className="flex items-end mt-2 justify-between w-full">
        <div className="">
          <div className="text-[13px] font-light">Issued by:</div>
          <div className="font-semibold">
            {tabstate == 2 ? username : data.sender}
          </div>
        </div>
        <div className="text-green-500 text-[14px] font-semibold">
          completed
        </div>
      </div>
    </div>
  );
};

export default CommandCard;
