import { BsFillLightbulbFill, BsFillLightbulbOffFill } from "react-icons/bs";
import { GiElectricalCrescent } from "react-icons/gi";
import { RiTimerFlashLine } from "react-icons/ri";
export const measurementCommands = [
  {
    id: 1,
    icon: "money",
    title: "Apparent power",
    desc: "Measure energy by apparent power.",
    cmType: "app",
  },
  {
    id: 2,
    icon: "bill",
    title: "Real power",
    desc: "Measure energy by real power.",
    cmType: "rep",
  },
];

export const powerData = [
  {
    id: 1,
    icon: <BsFillLightbulbFill size={21} />,
    title: "Power on",
    desc: "By using this command your will power on when there is external supply.",
    cmd: "poweron",
  },
  {
    id: 2,
    icon: <BsFillLightbulbOffFill size={21} />,
    title: "Power off",
    desc: "By using this command your will power down when there is external supply.",
    cmd: "poweroff",
  },
];

export const hardwareData = [
  {
    id: 1,
    icon: <GiElectricalCrescent size={21} />,
    title: "Clear Hardware Tamper",
    desc: "Use this hardcommand to clear existing temper in your meter",
    cmdType: "ctp",
  },
  {
    id: 2,
    icon: <GiElectricalCrescent size={21} />,
    title: "Erase all commands",
    desc: "Use this hardcommand to erase all commands on your meter",
    cmdType: "rst11",
  },
  {
    id: 3,
    icon: <GiElectricalCrescent size={21} />,
    title: "Erase control commands",
    desc: "Use this hardcommand to erase all control on your meter.",
    cmdType: "rst10",
  },
  {
    id: 4,
    icon: <GiElectricalCrescent size={21} />,
    title: "Restart Firmware",
    desc: "Use this hardcommand to restart firmware on your meter ",
    cmdType: "rst09",
  },
  {
    id: 5,
    icon: <RiTimerFlashLine size={21} />,
    title: "Reset SAIDI enquiry",
    desc: "Reset System Average Interruption Duration Index (SAIDI).",
    cmdType: "rsde",
  },
  {
    id: 6,
    icon: <RiTimerFlashLine size={21} />,
    title: "Reset SAIFI enquiry",
    desc: "Reset System Average Interruption Frequency Index (SAIFI).",
    cmdType: "rst04",
  },
];

export const enquiryData = [
  {
    id: 1,
    icon: <RiTimerFlashLine size={21} />,
    title: "SAIDI enquiry",
    desc: "Measure energy by apparent power.",
    cmdType: "sde",
  },
  {
    id: 2,
    icon: <RiTimerFlashLine size={21} />,
    title: "SAIFI enquiry",
    desc: "Measure energy by real power.",
    cmdType: "sfe",
  },
  {
    id: 3,
    icon: <GiElectricalCrescent size={21} />,
    title: "Get device Tamper status",
    desc: "Measure energy by apparent power.",
    cmdType: "gtp",
  },
];
