import axios from "../utils/axios";
import { createContext } from "react";
import { errorBlock } from "../controllers/errorBlock";
import { getToken } from "../utils/token";

export const MeterClassContext = createContext();

const meterClassReducer = () => {
  return {
    getAllMeterClass: async (limit, offset) => {
      try {
        let res = axios.post("/meterclass/getsavedmeterclasses", {
          offset: offset || 0,
          limit: limit || 5,
        });
        return res;
      } catch (err) {
        errorBlock(err);
      }
    },
    addMeterClassToMeter: async (meterid, meterclass) => {
      try {
        let user = await getToken("spiral_username");
        let res = axios.post("/commands/addmetertometerclass", {
          meterid: meterid,
          transactiontopic: user,
          meterclass: meterclass,
        });
        return res;
      } catch (err) {
        errorBlock(err);
      }
    },
  };
};

export const MeterClassProvider = ({ children }) => {
  return (
    <MeterClassContext.Provider value={meterClassReducer()}>
      {children}
    </MeterClassContext.Provider>
  );
};
