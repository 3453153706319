import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { themeColor } from "../../../../../constant/color";
const VendHistory = ({ paymentHistory }) => {
  const chartRef = useRef();
  const [gradientValue, setGradientValue] = useState("");
  const [data, setData] = useState("");
  const [labels, setLabels] = useState("");

  // moment(Date.now()).format('MMM DD, h:mm A z')
  return (
    <Line
      ref={chartRef}
      data={{
        labels: [23, 43, 20, 50, 34, 57], // x axis
        datasets: [
          {
            type: "bar",
            label: "",
            fill: true,
            data: [23, 43, 20, 50, 34, 57],
            backgroundColor: themeColor.secondary,
            radius: 2,
            borderRadius: 5,
            pointBorderWidth: 1,
            tension: 0.4,
          },
          //   {
          //     type: "line",
          //     label: "",
          //     fill: false,
          //     data: [10, 30, 20, 55, 43, 75],
          //     borderColor: "tomato",
          //     radius: 2,
          //     borderWidth: 2,
          //     pointBorderWidth: 1,
          //     tension: 0.4,
          //   },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: false,
          title: {
            display: false,
          },
          tooltip: {
            // callbacks: {
            //   label: function (tooltipItem, data) {
            //     return numFormtter(parseInt(tooltipItem.parsed.y));
            //   },
            // },
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default VendHistory;
