import { useContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { BillingContext } from "../../../../../../context/BillingContext";
import { billingController } from "../../../../../../controllers/BillingController";
import { Button } from "../../../../../Button";
import axios from "../../../../../../utils/axios";
import { getToken } from "../../../../../../utils/token";
// useS;

const BillingControl = ({ loading, setloading }) => {
  const { id } = useParams();
  const { disableBilling } = useContext(BillingContext);
  const handleDisableBilling = async () => {
    try {
      setloading(true);
      let user = await getToken("spiral_username");
      let res = await disableBilling(id);
      let result = billingController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }
      toast[result.type]("Billing deactivated successfully");
      setloading(false);
      // closeBtn((prev) => false);

      // var time = setTimeout(() => {
      //   toast.success("Your command is being processed");
      //   setprocessing(false);
      //   return;
      // }, Number(process.env.REACT_APP_TIME));

      // mqttEvents.on("message", (message) => {
      //   if (message.topic === `mqtt_${user}`) {
      //     if (!message.payloadString) return null;
      //     if (JSON.parse(message.payloadString).type == "OFF") {
      //       if (JSON.parse(message.payloadString).deviceid == id) {
      //         setpowerstatus(
      //           JSON.parse(message.payloadString).status == "SUCCESS" ? 0 : 1
      //         );
      //         setprocessing(false);
      //       }
      //       return clearTimeout(time);
      //     }
      //   }
      // });
      return;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
      }
      toast.error(err.message, {
        toastId: "ewfwdv3998",
      });
      setloading(false);
      return;
    }
  };

  return (
    <div className="w-[80%] mx-auto">
      <div className="mt-10 w-full">
        <div className="font-Kanit text-center text-secondary font-semibold text-[17px]">
          Update Billing status
        </div>
        <div className=" text-center text-gray-700 font-light">
          click on the button to disable billing on your channel. <br />
          When you do this users you share this channel with won't be able to
          purchase payment plans on the channel
        </div>
      </div>

      <div className="mt-20 mb-3">
        <Button
          text={"Disable billing"}
          loading={loading}
          onClick={handleDisableBilling}
        />
      </div>
    </div>
  );
};

export default BillingControl;
