import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./screens/Auth/Login";
import { Navigate, Route, Routes, useNavigate, Outlet } from "react-router-dom";
import ResetPassword from "./screens/Auth/ResetPassword";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import CompleteRegistration from "./screens/Auth/CompleteRegistration";
import UserDashboard from "./screens/Dashboard/UserDashboard";

import CreateAccount from "./screens/Auth/CreateAccount";
import toast, { Toaster } from "react-hot-toast";
import EmailVerification from "./screens/Auth/EmailVerification";
import SignIn from "./screens/Auth/SignIn";
import { ChannelContextProvider } from "./context/channelContext";
import AllChannels from "./screens/channels/Allchannels";
import MChannelDetails from "./screensMobile/Channels/MChannelDetails";
import ChannelDetails from "./screens/channels/ChannelDetails";

import Users from "./screens/Users";
import EnergyAnalytics from "./screens/Charts/EnergyAnalytics";
import RUP from "./screens/Users/Charts/RUP";
import { BillingContextProvider } from "./context/BillingContext";
import { MeterClassProvider } from "./context/MeterClassContext";
import UsersManagement from "./screens/Users/UserManagement";
import { MQTTProvider } from "./context/MqttContext";
import Groupedchannels from "./screens/channels/Groupedchannels";
import NotFound from "./screens/NotFound";

import AddMeterClass from "./screens/MeterClass/AddMeterClass";

import AllUserMeters from "./screens/Users/Meter/AllUserMeters";

import Settings from "./screens/Settings";
import PaymentPlan from "./screens/Users/PaymentPlan";
import { PaymentPlanProvider } from "./context/PaymentPlanContext";
import MeterClass from "./screens/MeterClass";

import Wallet from "./screens/Wallet";
import ManagerMeter from "./screens/ManageMeter";
import { getToken, setToken } from "./utils/token";
import Notification from "./screens/Notification";
import IncomingRequest from "./screens/IncomingRequest";
import ChannelPaymentPlan from "./components/Channels/PaymentPlan/ChannelPaymentPlan";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "./redux/actions/auth.action";
import { ProfileContext } from "./context/profileContext";
import { ProfileProvider } from "./context/ProfileContextN";
import ChannelUsers from "./screens/channels/ChannelUsers";
import MeterDetails from "./screens/Users/Meter/MeterDetails";
import RealtimeConsumptionMeter from "./screens/MeterClass/Chart/RealtimeConsumptionMeter";
import AddPlanToChannel from "./screens/channels/AddPlanToChannel";
import { TimeContextProvider } from "./context/TimeContext";

function App() {
  const [loading, setloading] = useState(false);
  const [usertoken, setusertoken] = useState(" ");
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  // useEffect(() => {
  //   callInt();
  //   return () => {};
  // }, []);

  // const callInt = async () => {
  //   try {
  //     let token = await getToken("spiral_token");
  //     if (!token || !authState?.loggedIn) {
  //       navigate("/");
  //       return;
  //     }
  //     // dispatch(postLogin());
  //   } catch (err) {
  //     toast.err(err.message);
  //     setloading(false);
  //   }
  // };

  // loading ? (
  //   <div className="w-screen h-screen flex items-center justify-center">
  //     <div className="font-kanit font-semibold text-grey-800">
  //       Spiral Tech...
  //     </div>
  //   </div>
  // ) :

  return !authState?.loggedIn ? (
    <>
      <Routes>
        {/* Auth */}
        <Route path="/" element={<SignIn />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/emailverification" element={<EmailVerification />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/completeregistration"
          element={<CompleteRegistration />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster />
    </>
  ) : (
    <MQTTProvider>
      <TimeContextProvider>
        <PaymentPlanProvider>
          <MeterClassProvider>
            <BillingContextProvider>
              <ChannelContextProvider>
                <ProfileProvider>
                  <Routes>
                    <Route path="/" element={<SignIn />} />

                    {/* User routes */}
                    {/* <Route element={<ProtectedRoute />}> */}
                    <Route path="/dashboard" element={<UserDashboard />} />
                    <Route path="/allchannels" element={<AllChannels />} />

                    <Route path="/allmeters" element={<AllUserMeters />} />
                    <Route path="/usermeter/:id" element={<MeterDetails />} />
                    <Route
                      path="/channel/group/*"
                      element={<Groupedchannels />}
                    />
                    <Route path="/channel/:id" element={<ChannelDetails />} />
                    {/* plans on channel */}
                    <Route
                      path="/channeladdplan/:id"
                      element={<AddPlanToChannel />}
                    />
                    {/* Real time analytics */}
                    <Route
                      path="/channel/energy-analytics/:id"
                      element={<EnergyAnalytics />}
                    />
                    <Route
                      path="/channel/realtimeconsumption/:id"
                      element={<RUP />}
                    />
                    <Route
                      path="/meter/realtimeconsumption/:id"
                      element={<RealtimeConsumptionMeter />}
                    />

                    <Route
                      path="/channel/paymentplan/:id"
                      element={<ChannelPaymentPlan />}
                    />

                    <Route
                      path="/channel/usermanagement"
                      element={<UsersManagement />}
                    />

                    <Route
                      path="/channel/usermanagement/:id"
                      element={<ChannelUsers />}
                    />

                    {/* Meter Class */}
                    <Route path={"/meterclass"} element={<MeterClass />} />
                    <Route
                      path={"/addmeterclass"}
                      element={<AddMeterClass />}
                    />
                    <Route
                      path={"/editmeterclass"}
                      element={<AddMeterClass />}
                    />
                    <Route path="/allpaymentplans" element={<PaymentPlan />} />

                    {/* <Route path="/revenue" element={<SalesAnalytics />} /> */}

                    <Route path="/manage-meter" element={<ManagerMeter />} />

                    {/* All routes */}
                    <Route path="settings" element={<Settings />} />
                    <Route path="notification" element={<Notification />} />
                    <Route path="request" element={<IncomingRequest />} />
                    <Route path="wallet" element={<Wallet />} />
                    {/* </Route> */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  <Toaster />
                </ProfileProvider>
              </ChannelContextProvider>
            </BillingContextProvider>
          </MeterClassProvider>
        </PaymentPlanProvider>
      </TimeContextProvider>
    </MQTTProvider>
  );
}

export default App;
const ProtectedRoute = () => {
  const [userToken, setUserToken] = useState(false);
  const [loading, setloading] = useState(true);
  const authState = useSelector((state) => state.authReducer);
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        let token = await getToken("spiral_token");
        if (authState?.loggedIn && token) {
          setUserToken(true);
        }
        // if (!token) {
        //   setUserToken(false);
        //   return;
        // }
        // setUserToken(true);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  return !userToken ? <Outlet /> : <Navigate to="/" />;
};
