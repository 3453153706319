import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useRef } from "react";

const EnergyCharts = () => {
  const chartRef = useRef();

  return (
    <Bar
      ref={chartRef}
      data={{
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ], // x axis
        datasets: [
          {
            // type: "line",
            label: "office",
            fill: true,
            backgroundColor: "#2A84FF",
            data: [10, 30, 20, 55, 43, 75, 12],
            radius: 5,
            borderRadius: 3,
            pointBorderWidth: 5,
            tension: 0.4,
          },

          {
            // type: "line",
            label: "Store",
            fill: true,
            backgroundColor: "#B3E7FC",
            data: [10, 30, 20, 55, 43, 75, 34],
            radius: 5,
            borderRadius: 3,
            pointBorderWidth: 5,
            tension: 0.4,
          },
          {
            // type: "line",
            label: "Hostel",
            fill: true,
            backgroundColor: "#FFBD99",
            data: [10, 30, 20, 55, 43, 75, 20],
            radius: 5,
            borderRadius: 3,
            pointBorderWidth: 5,
            tension: 0.4,
          },
          {
            // type: "line",
            label: "Room",
            fill: true,
            backgroundColor: "orange",
            data: [10, 30, 20, 55, 43, 75, 29],
            radius: 5,
            borderRadius: 5,
            pointBorderWidth: 5,
            tension: 0.4,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: false,
          },
          tooltip: {},
        },
        scales: {
          x: {
            display: true,
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            display: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default EnergyCharts;
