import Skimmer from "../../Loader/Skimmer";
import { IoPower } from "react-icons/io5";
import { useEffect, useState } from "react";
import { getToken } from "../../../utils/token";
import { channelController } from "../../../controllers/channelController";
import { toast } from "react-hot-toast";
import { useMQTT } from "../../../context/MqttContext";
import axios from "../../..//utils/axios";
import { useParams } from "react-router-dom";
import { commandController } from "../../../controllers/CommandController";
import ProcessingCard from "../../Loader/ProcessingCard";
import SwitchImg from "../../../assets/png/icons8-switch-64.png";

const PowerStateCard = ({
  loading,
  data,
  setopenshedule,
  setpowerstatus,
  showButton,
  setshowButton,
  powerstatus,
}) => {
  const { mqttEvents } = useMQTT();
  const [processing, setprocessing] = useState(false);
  const { id } = useParams();

  // TON
  const handleOn = async () => {
    try {
      setprocessing(true);
      let user = await getToken("spiral_username");
      let res = await axios.post("/commands/immediateturnon", {
        meterid: id,
        transactiontopic: `mqtt_${user}`,
      });
      const result = commandController(res);
      if (result.type !== "success") {
        toast[result.type](`${result.message}`);
        return;
      }

      var time = setTimeout(() => {
        toast.success("Your command is being processed");
        setprocessing(false);
        return;
      }, Number(process.env.REACT_APP_TIME));

      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${user}`) {
          if (!message.payloadString) return null;
          if (JSON.parse(message.payloadString).type == "TON") {
            if (JSON.parse(message.payloadString).deviceid == id) {
              console.log(message, "TON ");
              setpowerstatus(
                JSON.parse(message.payloadString).status == "SUCCESS" ? 1 : 0
              );
              setprocessing(false);
            }
            return clearTimeout(time);
          }
        }
      });
    } catch (err) {
      if (err?.response) {
        toast.error(err.response.data.response);
      }
      toast.message(err?.message);
    }
  };

  const handleOff = async () => {
    try {
      setprocessing(true);
      let user = await getToken("spiral_username");
      let res = await axios.post("/commands/immediateturnoff", {
        meterid: id,
        transactiontopic: `mqtt_${user}`,
      });
      const result = commandController(res);

      if (result.type !== "success") {
        toast[result.type](`${result.message}`);
        setprocessing(false);
        return;
      }

      var time = setTimeout(() => {
        toast.success("Your command is being processed");
        setprocessing(false);
        return;
      }, Number(process.env.REACT_APP_TIME));

      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${user}`) {
          if (!message.payloadString) return null;
          if (JSON.parse(message.payloadString).type == "OFF") {
            if (JSON.parse(message.payloadString).deviceid == id) {
              setpowerstatus(
                JSON.parse(message.payloadString).status == "SUCCESS" ? 0 : 1
              );
              setprocessing(false);
            }
            return clearTimeout(time);
          }
        }
      });

      return;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleSwitch = (e) => {
    if (e == 1) {
      // off
      handleOff();
    } else if (e == 0) {
      // on
      handleOn();
    }
  };

  return (
    <>
      {processing && <ProcessingCard open={processing} />}
      <div className="h-[120px] border-b-[2px] border-green-400  p-2 bg-white rounded-md drop-shadow-md relative">
        <div className="pt-1">
          <div className="flex items-center justify-between">
            <div className="">
              <div className="font-light text-gray-700">Name of meter</div>
              <div className="font-semibold">
                {loading.channel ? (
                  <Skimmer width={"120px"} heigth={"30px"} />
                ) : (
                  data?.devicealias
                )}
              </div>
            </div>
            <div className="w-[50px] h-[50px] hidden items-center justify-center ">
              <img src={SwitchImg} className="w-full h-full" />
            </div>
          </div>
        </div>

        <div
          onClick={() => setopenshedule(true)}
          className="font-normal absolute bottom-[-5px] pb-3 text-[13px] pt-4 left-2 underline cursor-pointer w-[60%]"
        >
          Control meter
        </div>
        {showButton &&
          (powerstatus == 1 ? (
            <div
              onClick={() => handleSwitch(1)}
              className="flex items-center absolute bottom-[15px] right-[15px] w-[45px] h-[45px] bg-white drop-shadow-lg cursor-pointer justify-center p-2 rounded-full border-[2px] border-red-500"
            >
              <IoPower color="tomato" size={23} />
            </div>
          ) : (
            <div
              onClick={() => handleSwitch(0)}
              className="flex items-center absolute bottom-[15px] right-[15px] w-[45px] h-[45px] bg-white drop-shadow-lg cursor-pointer justify-center p-2 rounded-full border-[2px] border-green-500"
            >
              <IoPower color="green" size={23} />
            </div>
          ))}
      </div>
    </>
  );
};

export default PowerStateCard;
