const AdvancedSettings = ({
  setsharemodal,
  setbillingmodal,
  setcommandsModal,
  setoptionssection,
}) => {
  const data = [
    { id: 1, title: "Share meter", icon: "" },
    { id: 2, title: "Update billing", icon: "" },
    { id: 3, title: "Configure Meter", icon: "" },
  ];
  return (
    <div className="absolute top-8 z-40 w-full h-[auto] bg-white rounded-sm shadow-md">
      {data.map(({ id, title, icon }) => (
        <div
          className="flex px-2 cursor-pointer hover:bg-gray-200  py-1 mt-2 items-center "
          key={id}
          onClick={() => {
            if (id === 1) {
              setsharemodal(true);
            } else if (id === 2) {
              setbillingmodal(true);
            } else {
              setcommandsModal(true);
            }
          }}
        >
          <div className=""></div>
          <div className="">{title}</div>
        </div>
      ))}
    </div>
  );
};

export default AdvancedSettings;
