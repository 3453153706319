import axios from "../utils/axios";
import { createContext } from "react";
import { getToken } from "../utils/token";
import { errorBlock } from "../controllers/errorBlock";
import { channelController } from "../controllers/channelController";
import { toast } from "react-hot-toast";

export const PaymentPlanContext = createContext();

const paymentPlanReducer = () => {
  return {
    getAllPaymentPlan: async (planalias) => {
      try {
        let res = axios.post("/submeter/paymentplan/getallpaymentplans", {
          planalias: planalias,
        });

        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }

        console.log(result, "from result,");
        return result;
      } catch (err) {
        errorBlock(err);

        console.log(err, '"""');
      }
    },
    assignPaymentPlanToChannel: async (channelid, planalias) => {
      try {
        let res = axios.post(
          "submeter/paymentplanassignment/assignplantochannel",
          {
            planalias: planalias,
            channelid: channelid,
          }
        );
        return res;
      } catch (err) {
        errorBlock(err);
      }
    },
    getallAssignedPlansToChannel: async (channelid) => {
      try {
        let res = axios.post(
          "/submeter/paymentplanassignment/getallassignedplans",
          {
            channelid: channelid,
          }
        );
        return res;
      } catch (err) {
        errorBlock(err);
      }
    },
    //
    removePlanFromChannel: async (channelid, planalias) => {
      try {
        let res = axios.post(
          "/submeter/paymentplanassignment/removeplanfromchannel",
          {
            channelid: channelid,
            planalias: planalias,
          }
        );
        return res;
      } catch (err) {
        errorBlock(err);
      }
    },

    planInfo: async (planalias) => {
      try {
        let res = axios.post(
          "/submeter/paymentplan/getspecificpaymentplaninfo",
          {
            planalias: planalias,
          }
        );
        return res;
      } catch (err) {
        errorBlock(err);
      }
    },
  };
};

export const PaymentPlanProvider = ({ children }) => {
  return (
    <PaymentPlanContext.Provider value={paymentPlanReducer()}>
      {children}
    </PaymentPlanContext.Provider>
  );
};
