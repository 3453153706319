import axios from "../utils/axios";
import { createContext } from "react";
import { errorBlock } from "../controllers/errorBlock";
import { getToken } from "../utils/token";

export const BillingContext = createContext();

const billingReducer = () => {
  return {
    setBilling: async (channelid, rate, units) => {
      try {
        let user = await getToken("spiral_username");
        let res = axios.post("/submeterbilling/setbillingmode", {
          username: user,
          channelid: channelid,
          rate: rate,
          minvend: units,
        });
        return res;
      } catch (err) {
        errorBlock(err.message);
      }
    },
    disableBilling: async (channelid) => {
      try {
        let username = await getToken("spiral_username");
        let res = axios.post("/submeterbilling/disablekwhbilling", {
          username,
          channelid: channelid,
        });
        return res;
      } catch (err) {
        errorBlock(err);
      }
    },
  };
};

export const BillingContextProvider = ({ children }) => {
  return (
    <BillingContext.Provider value={billingReducer()}>
      {children}
    </BillingContext.Provider>
  );
};
