import { useEffect, useState } from "react";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import ProcessingCard from "../../../Loader/ProcessingCard";
import ProcessingLoader from "../../../ProcessingLoader";
import BillingControl from "./Tabs/BillingControl";
import MeterClassTab from "./Tabs/MeterClassTab";
import RechargeTab from "./Tabs/RechargeTab";
import UnitSettings from "./Tabs/UnitSettings";
const BillingModal = ({ open, setopen }) => {
  const [tabstate, settabstate] = useState(1);
  const [loading, setloading] = useState(false);
  return loading ? (
    <ProcessingCard open={loading} />
  ) : (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[500px] lg:w-[650px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start justify-content-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Billing Settings
              </div>
              <div className="w-[80%] text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div
              className={
                tabstate == 1
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(1)}
            >
              Update billing price
            </div>
            <div
              className={
                tabstate == 2
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(2)}
            >
              Meter class
            </div>
            <div
              className={
                tabstate == 3
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(3)}
            >
              Billing control
            </div>
            <div
              className={
                tabstate == 4
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(4)}
            >
              Recharge enquiry
            </div>
          </div>
          <hr className="border-[0.5px] border-gray-400 w-full" />
          <div className="">{tabstate == 1 && <UnitSettings />}</div>
          <div className="">
            {tabstate == 2 && (
              <MeterClassTab
                loading={loading}
                setloading={setloading}
                closeBtn={setopen}
              />
            )}
          </div>
          <div className="">
            {tabstate == 3 && (
              <BillingControl
                loading={loading}
                setloading={setloading}
                closeBtn={setopen}
              />
            )}
          </div>
          <div className="">
            {tabstate == 4 && (
              <RechargeTab
                loading={loading}
                setloading={setloading}
                closeBtn={setopen}
              />
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default BillingModal;
