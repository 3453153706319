import "./style.css";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import Backdrop from "../../Backdrop";
import { getToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import CloseButton from "../../CloseButton";
import Rule from "../../Rule/Index";
import { Button } from "../../Button";
import { useMQTT } from "../../../context/MqttContext";
import { IoCloseOutline } from "react-icons/io5";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";
import { FiPower } from "react-icons/fi";
import Skimmer from "../../Loader/Skimmer";
import ConnectionImg from "../../../assets/png/Screenshot 2023-04-07 at 19.27.57.png";

const RST10 = ({
  responsedata,
  setresponsedata,
  responseType,
  setresponseType,
  restype,
  open,
  setopen,
  handleNoti,
}) => {
  const { mqttEvents } = useMQTT();
  const [loading, setLoading] = useState(false);
  const [genState, setGenState] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [length, setLength] = useState(0);
  const [name, setname] = useState("");
  const supply = (item) => {
    return item == "01" ? "off" : "on";
  };
  const { deviceid, status } = responsedata;
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let username = await getToken("spiral_username");
        let res = await axios.post("/submeter/getchannelinfo", {
          username,
          channelid: deviceid,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }
        setname(result.message.body[0].devicealias);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
    return () => {};
  }, []);

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[400px] bg-white drop-shadow-md roundeds-sm p-3">
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Erase control commands
              </div>
              <div className="hidden w-[100%] text-gray-700 font-light">
                Request to power supply status on your device .<br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <div
                onClick={() => {
                  setresponseType(null);
                  setresponsedata("");
                  setopen(false);
                  handleNoti();
                }}
                className="flex items-center justify-center pt-2 text-secondary cursor-pointer font-light text-[13px]"
              >
                Close
              </div>
            </div>
          </div>
          <Rule />
          <div className="pt-1">
            <div className="flex items-center justify-center flex-col">
              {status == "SUCCESS" ? (
                <div className="text-gray-700 text-center pb-3">
                  You command to erase control command was executed
                  successfully.
                </div>
              ) : (
                <div className="">{status}</div>
              )}
              <div className="flex items-center w-[60px] h-[60px] bg-white drop-shadow-lg cursor-pointer justify-center p-2 rounded-full">
                <img src={ConnectionImg} className="" alt="" />
              </div>
            </div>
            <div className="mt-2 pb-8">
              <div className="text-gray-700 text-center">Meter name:</div>
              <div className="font-semibold text-center font-Kanit text-gray-800">
                {loading ? <Skimmer heigth={40} /> : name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default RST10;
