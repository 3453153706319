import { useState } from "react";
import RenameModal from "../Modals/RenameModal";

const ChannelOption = ({
  selectedmodal,
  setselectedmodal,
  channelId,
  setselectOption,
  showrename,
  setshowrename,
  setselectedchannel,
  showgroup,
  setshowgroup,
}) => {
  const option = ["Add to group", "Rename", "Recharge"];
  const [modal, setmodal] = useState(false);

  return (
    <>
      <div
        className={`bg-white drop-shadow rounded absolute right-[-5px] z-10 top-9 animate__animated animate__fadeInUp`}
        style={{
          display: selectedmodal == channelId ? "block" : "none",
        }}
      >
        <div
          className="px-5 text-[12px] even:bg-gray-50 hover:even:bg-gray-50 cursor-pointer py-2 my-2 text-primary  font-light"
          onClick={() => {
            setshowgroup(true);
            setselectedmodal(null);
          }}
        >
          Add to group
        </div>
        <div
          className="px-5 text-[12px] even:bg-gray-50 hover:even:bg-gray-50 cursor-pointer py-2 my-2 text-primary  font-light"
          onClick={() => {
            setshowrename(true);
            setselectedmodal(null);
          }}
        >
          Rename
        </div>
        <div
          className="px-5 hidden text-[12px] even:bg-gray-50 hover:even:bg-gray-50 cursor-pointer py-2 my-2 text-primary  font-light"
          onClick={() => {
            // setselectedchannel(null);
            setselectOption();
          }}
        >
          Recharge
        </div>
      </div>
    </>
  );
};

export default ChannelOption;
