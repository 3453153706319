import Chart from "chart.js/auto";
import moment from "moment";
import { Line } from "react-chartjs-2";

const UserMeterChart = ({ data }) => {
  // console.log(data , "---- data")
  return (
    <Line
      data={{
        labels: !data ? [] : data.map((item) => item.pqdatetime),
        datasets: [
          {
            // lineTension: 0.3,
            borderColor: "green",
            fill: false,
            borderCapStyle: "butt",
            borderDash: [],
            tension: 0.1,
            label: "Frequency",
            data: !data ? [] : data.map((item) => item.frequencyl1),
          },
          {
            // lineTension: 0.3,

            fill: false,
            borderColor: "dodgerblue",
            borderCapStyle: "butt",
            borderDash: [],
            label: "Power",
            tension: 0.1,
            data: !data ? [] : data.map((item) => item.pfl1),
          },
          {
            // lineTension: 0.3,
            borderColor: "red",
            fill: false,

            borderDash: [],
            tension: 0.1,
            pointRadius: 4,
            label: "Voltage",
            borderWidth: 1,
            data: !data ? [] : data.map((item) => item.voltageln1),
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: "#869aff1e",
        fill: true,
        label: "",
        plugins: {
          title: {
            display: true,
          },
          legend: {
            display: true,
            position: "bottom",
          },
          tooltip: {
            intersect: true,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 3,
              color: "white",
            },
          },
          y: {
            display: true,
            grid: {
              display: true,
              borderWidth: 0,
            },
          },
        },
      }}
    />
  );
};

export default UserMeterChart;
