import { useNavigate, useParams } from "react-router-dom";
import { useMQTT } from "../../../../context/MqttContext";
import axios from "../../../../utils/axios";
import { commandController } from "../../../../controllers/CommandController";
import { toast } from "react-hot-toast";
import { getToken } from "../../../../utils/token";
import { useState } from "react";
import ProcessingLoader from "../../../ProcessingLoader";
import ProcessingCard from "../../../Loader/ProcessingCard";
import { FaLessThanEqual } from "react-icons/fa";
// import {clients, mqttEvents} from useMQTT()

const MeterInstructions = () => {
  const { client, mqttEvents } = useMQTT();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  const data = [
    // {
    //   id: 1,
    //   title: "View consumption in realtime",
    //   desc: "Monitor consumption on you channel in realtime",
    //   // link: "/meter/realtimeconsumption/",
    // },
    {
      id: 2,
      title: "Meter class enquiry",
      desc: "Check list of meter class on your meter",
    },
    {
      id: 3,
      title: "Power state of meter",
      desc: "Get the current power state of all device on meter",
      link: " ",
    },
    {
      id: 4,
      title: "Reset time on meter",
      desc: "Set time on meter to default.",
    },
  ];
  const handleCommand = async (item) => {
    const user = await getToken("spiral_token");
    setloading(true);
    try {
      switch (item) {
        case 1:
          client?.subscribe(`r_${id}`);
          let res = await axios.post("/commands/realtimedeviceupdate", {
            dcuid: id,
            transactiontopic: `r_${id}`,
          });
          let result = commandController(res);
          if (result.type !== "success") {
            toast.error(result.message);
            setloading(false);
            return;
          }
          navigate(`/meter/realtimeconsumption/${id}`);
          break;
        case 2:
          try {
            let res = await axios.post("/commands/meterclassenquiry", {
              transactiontopic: `mqtt_${user}`,
              dcuid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(FaLessThanEqual);
              // setLoading({ saifiLoading: false });
              // setSelectedCommand(null);
              return;
            }

            var time = setTimeout(() => {
              toast.success("Your command is being processed");
              setloading(false);
              return;
            }, Number(process.env.REACT_APP_TIME));

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (JSON.parse(message.payloadString).type == "MCE") {
                  if (JSON.parse(message.payloadString).deviceid == id) {
                    setloading(false);
                  }
                  return clearTimeout(time);
                }
              }
            });
            // navigate(`/meter/realtimeconsumption/${id}`);
          } catch (err) {
            console.log(err.message);
          }
          break;
        case 4:
          try {
            let res = await axios.post("/commands/resettimeondcu", {
              transactiontopic: `mqtt_${user}`,
              dcuid: id,
            });

            let result = commandController(res);

            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              // setLoading({ mebapLoading: false });
              // setSelectedCommand(null);
              return;
            }

            var time = setTimeout(() => {
              toast.success("Your command is being processed");
              setloading(false);
              return;
            }, Number(process.env.REACT_APP_TIME));

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (JSON.parse(message.payloadString).type == "RST03") {
                  if (JSON.parse(message.payloadString).deviceid == id) {
                    setloading(false);
                  }
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            console.log(err.message);
          }
          // setResponse({
          //   rsaifiResponse: res.data[Object.keys(res.data)[1]],
          // });
          // toast.success("Your command is being processed", {
          //   toastId: "32o3o23c7u",
          // });
          // setLoading({ command: true });
          // var time = setTimeout(() => {
          //   setLoading({ command: false });
          //   toast.success("Your request is been processed");
          // }, 5000);
          // mqttEvents.on("message", (message) => {
          //   if (message.topic === `mqtt_${user}`) {
          //     if (!message.payloadString) return null;
          //     if (JSON.parse(message.payloadString).type == "RSTDC") {
          //       setLoading({ command: false });
          //       return clearTimeout(time);
          //     }
          //   }
          // });
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
  return (
    <div className="">
      {loading && <ProcessingCard open={loading} />}
      {data.map((i) => (
        <div
          onClick={() => handleCommand(i.id)}
          className="my-4 cursor-pointer border-[1px] rounded-md p-2"
          key={i.id}
        >
          <div className="font-semibold">{i.title}</div>
          <div className="font-light text-[13px]">{i.desc} </div>
        </div>
      ))}
    </div>
  );
};

export default MeterInstructions;
