import { TimePicker } from "antd";
import axios from "../../../../utils/axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { BsFillCheckCircleFill } from "react-icons/bs";
// import { Button } from "../../Button";
// import ConnectionSatate from "../ConnectionState";
// import DaysSelector from "../DaysSelector";
import { useParams } from "react-router-dom";
// import { commandController } from "../../../controllers/CommandController";
import { getToken } from "../../../../utils/token";
import { useMQTT } from "../../../../context/MqttContext";
// import ProcessingCard from "../../Loader/ProcessingCard";
// ProcessingCard
// import Drawer from "../../Drawer";
import { commandController } from "../../../../controllers/CommandController";
import ProcessingCard from "../../../Loader/ProcessingCard";
import Drawer from "../../../Drawer";
import DaysSelector from "../../../Commands/DaysSelector";
import ConnectionSatate from "../../../Commands/ConnectionState";
import { Button } from "../../../Button";

const DIS_ALL = ({ selectedpowerstate, setopen, open, aliases }) => {
  const { id } = useParams();
  const [step, setstep] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);
  const [frequency, setFrequency] = useState(1);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [daybinaries, setdaybinaries] = useState([
    {
      id: 1,
      title: "Sunday",
      value: 1,
    },
    {
      id: 2,
      title: "Monday",
      value: 1,
    },
    {
      id: 3,
      title: "Tuesday",
      value: 1,
    },
    {
      id: 4,
      title: "Wednesday",
      value: 1,
    },
    {
      id: 5,
      title: "Thursday",
      value: 1,
    },
    {
      id: 6,
      title: "Friday",
      value: 1,
    },
    {
      id: 7,
      title: "Saturday",
      value: 0,
    },
  ]);

  let alias = aliases.filter((i) => i?.checked);

  const { mqttEvents } = useMQTT();

  const handleSubmit = async () => {
    try {
      // console.log(frequency, "frequency");
      if (!startTime) return toast.error("Please select a start time");
      if (!endTime) return toast.error("Please select an end time");

      if (!frequency) return toast.error("Please select a frequency.");

      setLoading(true);
      let user = await getToken("spiral_username");
      let request = await axios.post(
        selectedpowerstate == "con"
          ? "/commands/scheduledconnect"
          : "/commands/scheduleddisconnect",
        {
          meterid: id,
          daybinaries: daybinaries.map((i) => i.value).join(""),
          starttime: moment(startTime._d).format("HH:mm").replaceAll(":", ""),
          endtime: moment(endTime._d).format("HH:mm").replaceAll(":", ""),
          onetimeaction: frequency,
          transactiontopic: `mqtt_${user}`,
        }
      );

      const result = commandController(request);
      if (result.type !== "success") {
        toast[result.type](`${result.message}`);
        setLoading(false);
        return;
      }

      var time = setTimeout(() => {
        toast.success("Your command is being processed", {
          toastId: "vw389",
        });
        setLoading((prev) => false);
        setopen(false);
      }, Number(process.env.REACT_APP_TIME));

      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${user}`) {
          if (!message.payloadString) return null;
          if (JSON.parse(message.payloadString).deviceid == id) {
            let formatJSON = JSON.parse(message.payloadString);
            if (JSON.parse(message.payloadString).type == "CON") {
              if (formatJSON?.status == "SUCCESS") {
                toast.success(
                  "You schedule connection action has been issued successfully."
                );
              }
              setLoading((prev) => false);
              clearInterval(time);
              setopen(false);
              return;
            } else if (JSON.parse(message.payloadString).type == "DIS") {
              if (formatJSON?.status == "SUCCESS") {
                toast.success(
                  "You schedule disconnection action has been issued successfully."
                );
              }
              setLoading((prev) => false);
              clearInterval(time);
              setopen(false);
              return;
            }
          }
        }
      });
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
      return;
    }
  };
  return loading ? (
    <ProcessingCard open={loading} />
  ) : (
    <Drawer
      open={open}
      setopen={setopen}
      title={
        selectedpowerstate == "con"
          ? "Schedule a connection time"
          : "Schedule a disconnection time"
      }
      caption={
        selectedpowerstate == "con"
          ? "Fill in the field to set a connection time on your channel"
          : "Fill in the field to set a disconnection time on your channel"
      }
    >
      <div className="w-full relative h-[85vh]">
        <div className="items-center justify-start pt-3  grid-cols-4 hidden">
          <div className="flex items-center justify-start">
            <BsFillCheckCircleFill color={"#bbb"} size={23} />
            <div className="w-full h-[2px] bg-gray-700"></div>
          </div>
          <div className="flex items-center justify-start">
            <BsFillCheckCircleFill color={"#bbb"} size={23} />
            <div className="w-full h-[2px] bg-gray-700"></div>
          </div>
          <div className="flex items-center justify-start">
            <BsFillCheckCircleFill color={"#bbb"} size={23} />
            <div className="w-full h-[2px] bg-gray-700"></div>
          </div>
          <div className="flex items-center justify-start">
            <BsFillCheckCircleFill color={"#bbb"} size={23} />
            <div className="w-full h-[2px] bg-gray-700"></div>
          </div>
        </div>
        <div className="border-[1px] mt-4 border-[#eee] rounded-lg h-[90vh] p-3">
          {/* step */}
          {step == 1 && (
            <div className="">
              <div className="">
                <div className="font-Kanit font-normal text-gray-800 text-[17px]">
                  Start time.
                  <br />
                  What time of the day would you like to start?.
                </div>
              </div>
              <div className="w-full rounded-md overflow-hidden drop-shadow-md mt-2">
                <TimePicker
                  className=""
                  style={{
                    height: 45,
                    width: "100%",
                  }}
                  value={startTime}
                  format={"HH:mm"}
                  onChange={(e) => {
                    setStartTime(e);
                  }}
                />
              </div>
            </div>
          )}
          {step == 2 && (
            <div className="">
              <div className="">
                <div className="font-Kanit font-normal text-gray-800 text-[17px]">
                  End time.
                  <br />
                  What time should it stop?
                </div>
              </div>
              <div className="w-full rounded-md overflow-hidden drop-shadow-md mt-2">
                <TimePicker
                  className=""
                  style={{
                    height: 45,
                    width: "100%",
                  }}
                  value={endTime}
                  format={"HH:mm"}
                  onChange={(e) => {
                    setEndTime(e);
                  }}
                />
              </div>
            </div>
          )}
          {step == 3 && (
            <div className="">
              <div className="">
                <div className="font-Kanit font-normal text-gray-800 text-[17px]">
                  Weekend days
                  <br />
                  What days of the week should this happen?
                </div>
              </div>
              <div className="w-full flex item-center justify-center flex-wrap rounded-md overflow-hidden drop-shadow-md mt-3">
                <DaysSelector setFd={setdaybinaries} fd={daybinaries} />
              </div>
            </div>
          )}

          {step == 4 && (
            <div className="">
              <div className="">
                <div className="font-Kanit font-normal text-gray-800 text-[17px]">
                  Frequency
                  <br />
                  How often should this occurs
                </div>
              </div>
              <div className="w-full flex item-center justify-center flex-wrap rounded-md overflow-hidden drop-shadow-md mt-3">
                <ConnectionSatate
                  leftContent={"Repeat everyweek"}
                  rightContent={"One time"}
                  setFS={setFrequency}
                  fs={frequency}
                />
              </div>
            </div>
          )}
        </div>

        <div className="absolute bottom-2 grid grid-cols-2 gap-8 w-full">
          {step !== 1 && (
            <div
              className="border-[1px] text-center border-gray-600 drop-shadow-md px-6 rounded-md py-2 mx-2 bg-white cursor-pointer"
              onClick={() => {
                if (step == 1) return null;
                setstep((prev) => prev - 1);
              }}
            >
              Prev
            </div>
          )}
          {step !== 4 ? (
            <div
              className="drop-shadow-md text-center px-6 rounded-md py-2 mx-2 bg-gray-900 text-white cursor-pointer"
              onClick={() => setstep((prev) => prev + 1)}
            >
              Next
            </div>
          ) : (
            <div className="">
              <Button text={"Save"} onClick={handleSubmit} loading={loading} />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default DIS_ALL;
