import moment from "moment";
import { stringShorter } from "../../../helperFuncs";
import { FcCalendar } from "react-icons/fc";
const PaymentPlanTable = ({
  data,
  plandetails,
  setplandetails,
  planmodal,
  setplanmodal,
}) => {
  return (
    <div className="w-full mt-4">
      <table className="w-full">
        <thead className="border-b-[1px] border-b-[gray]">
          <th className="font-normal p-1 text-center text-gray-800">#</th>
          <th className="font-normal p-1 text-center text-gray-800">
            Plan Name
          </th>
          <th className="font-normal p-1  text-center text-gray-800">
            Description
          </th>
          <th className="font-normal p-1 text-center text-gray-800 w-[200px]">
            Price
          </th>
          <th className="font-normal p-1 text-center text-gray-800 w-[200px]">
            Date created
          </th>
          <th className="font-normal p-1 text-center text-gray-800">Action</th>
        </thead>
        <tbody>
          {data.map((i, index) => (
            <tr
              key={index}
              className={"border-none even:bg-[#f5f5f6] cursor-pointer"}
            >
              <td className="text-center text-gray-700">{index + 1}</td>
              <td className="text-center text-gray-700">{i?.planalias}</td>
              <td className="text-center text-gray-700">{i?.description}</td>
              <td className="text-center   text-secondary">
                ₦
                {i?.amount.toLocaleString("es-ES", {
                  maximumFractionDigits: 2,
                })}
              </td>
              <td className="text-center text-gray-700 ">
                <div className="flex items-center justify-center">
                  <FcCalendar />
                  {moment(i?.dateassigned).format("lll")}
                </div>
              </td>
              <td className="text-center flex item-center justify-center text-gray-700">
                <div className="border-[1px] hidden item-center justify-center mr-3  border-secondary rounded-md text-center px-3 py-2 cursor-pointer">
                  <div className="text-secondary">View</div>
                </div>
                <div
                  className="border-[0.5px] flex item-center justify-center px-3 py-2 border-gray-700 text-gray-700 rounded-md"
                  onClick={() => {
                    setplandetails(i);
                    setplanmodal(true);
                  }}
                >
                  <div className="">Buy Plan</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentPlanTable;
