import Chart from "chart.js/auto";
import moment from "moment";
import { Line } from "react-chartjs-2";

const EnergyBarChart = ({ data }) => {
  const date = data?.map((i) => moment(i?.pqdatetime).format("ll"));
  const ene = data?.map((i) => i?.activeenergyln1);
  const cur = data?.map((i) => i?.currentln1);
  const vol = data?.map((i) => i?.voltageln1);
  return (
    <div className="w-full h-[500px]">
      <Line
        data={{
          labels: date, // x axis
          datasets: [
            {
              type: "bar",
              label: "Voltage",
              fill: false,
              backgroundColor: "#0183d0e2",
              data: vol,
              borderColor: "#0183d0",
              radius: 1,
              borderWidth: 0,
              pointBorderWidth: 2,
              tension: 0.4,
            },
            {
              type: "bar",
              label: "Current",
              fill: false,
              backgroundColor: "tomato",
              data: cur,
              borderColor: "tomato",
              radius: 1,
              borderWidth: 0,
              pointBorderWidth: 2,
              tension: 0.4,
            },
            {
              type: "bar",
              label: "Energy",
              fill: false,
              backgroundColor: "orange",
              data: ene,
              borderColor: "",
              radius: 1,
              borderWidth: 0,
              pointBorderWidth: 2,
              tension: 0.4,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: true,
            title: {
              display: true,
              text: "Consumption chart",
            },
            tooltip: {
              // callbacks: {
              //   label: function (tooltipItem, data) {
              //     return numFormtter(parseInt(tooltipItem.parsed.y));
              //   },
              // },
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: true,
              },
            },
            y: {
              display: true,
              grid: {
                display: true,
                borderDash: [10],
                borderDashOffset: 20,
                borderWidth: 0,
                color: "#eee",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default EnergyBarChart;
