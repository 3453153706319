import Drawer from "../../Drawer";
import { useForm } from "react-hook-form";
import { AiOutlineUser } from "react-icons/ai";
import { useState } from "react";
import { DatePicker, TimePicker, Select } from "antd";
import moment from "moment";
import { IoFlash } from "react-icons/io5";
import DaysSelector from "../../Commands/DaysSelector";
import { Button } from "../../Button";
import ErrorText from "../../Errors/ErrorText";
import { billingController } from "../../../controllers/BillingController";
import { toast } from "react-hot-toast";
import { getToken } from "../../../utils/token";
import axios from "../../../utils/axios";
const DailyPowerplan = ({ refreshBtn, setopen }) => {
  const { Option } = Select;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [startTime, setStartTime] = useState("");
  const [loading, setLoading] = useState(false);

  const [endTime, setEndTime] = useState("");
  const [daysCount, setDaysCount] = useState("");
  const [daysLength, setDaysLength] = useState("");
  const [step, setstep] = useState(1);

  const { RangePicker } = DatePicker;

  const [daybinaries, setdaybinaries] = useState([
    {
      id: 1,
      title: "Sunday",
      value: 0,
    },
    {
      id: 2,
      title: "Monday",
      value: 0,
    },
    {
      id: 3,
      title: "Tuesday",
      value: 0,
    },
    {
      id: 4,
      title: "Wednesday",
      value: 0,
    },
    {
      id: 5,
      title: "Thursday",
      value: 0,
    },
    {
      id: 6,
      title: "Friday",
      value: 0,
    },
    {
      id: 7,
      title: "Saturday",
      value: 0,
    },
  ]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let res = await axios.post(
        "/submeter/paymentplan/createdailypowerlimitplan",
        {
          planalias: `${data?.name} (${data?.limit}W)`,
          description: data?.desc,
          daybinaries: daybinaries.map((i) => i.value).join(""),
          watt: data?.limit,
          duration: data?.daycount,
          durationlength: daysLength,
          amount: data?.price,
          starttime: moment(startTime).format("HH:mm").replace(":", ""),
          endtime: moment(endTime).format("HH:mm").replace(":", ""),
        }
      );
      let result = billingController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setopen(false);
      refreshBtn((prev) => !prev);
      toast.success("Payment plan created.");
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="px-3 mt-4 relative border-[1px] border-gray-300 rounded-md h-[85vh]"
    >
      {step == 2 && (
        <div className="pt-3">
          <label className="text-primary font-semibold text-sm">
            Set limit
          </label>
          <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px]">
            <div className="d-inline flex items-center justify-center px-2">
              <IoFlash
                size={20}
                color="text-primary"
                className="text-primary"
              />
            </div>
            <div className="ml-2">
              <input
                {...register("limit", { required: true })}
                placeholder={"what should the limit be "}
                className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
              />
            </div>
          </div>
          <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
        </div>
      )}
      {step == 1 && (
        <div className="mt-4">
          <label className="text-primary font-semibold text-sm">
            Plan name
          </label>
          <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px]">
            <div className="d-inline flex items-center justify-center px-2">
              <AiOutlineUser
                size={20}
                color="text-primary"
                className="text-primary"
              />
            </div>
            <div className="ml-2">
              <input
                {...register("name", { required: true })}
                placeholder={"Enter a name for this plan"}
                className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
              />
            </div>
          </div>
          {errors.name && <ErrorText text={"Please enter a name"} />}
          <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
        </div>
      )}
      {step == 3 && (
        <div className="mt-4">
          <label className="text-primary font-semibold text-sm">
            Plan description
          </label>
          <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[auto] p-2">
            <textarea
              {...register("desc", { required: true })}
              placeholder={"Enter a description"}
              className=" placeholder:text-sm h-[100px] resize-none placeholder:font-thin w-full outline-none border-[0px]"
            />
          </div>
          {errors.descrice && <ErrorText text={"Please enter a description"} />}
          <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
        </div>
      )}
      {/*  */}
      {step == 4 && (
        <div className="mt-4">
          <label className="text-primary font-semibold text-sm">
            Active days
          </label>
          <div className="flex p-2 flex-wrap bg-white items-center rounded-md w-100 border-[1px] border-gray-200 mt-4">
            <DaysSelector setFd={setdaybinaries} fd={daybinaries} />
          </div>
          <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
        </div>
      )}
      {step == 5 && (
        <div className="mt-4">
          <label className="text-primary font-semibold text-sm">Time</label>
          <div className="flex flex-wrap bg-white items-center rounded-md w-100 border-[1px] border-gray-200 mt-4">
            <TimePicker.RangePicker
              format={"HH:mm"}
              value={[startTime, endTime]}
              onChange={(e) => {
                setStartTime((prev) => e[0]);
                setEndTime((prev) => e[1]);
              }}
              style={{
                width: "100%",
                fontSize: "14px",
                height: 45,
              }}
              bordered={false}
            />
          </div>
          <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
        </div>
      )}
      {step == 6 && (
        <div className="mt-4">
          <label className="text-primary font-semibold text-sm">Duration</label>
          <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px]">
            <div className="grid grid-cols-3" style={{}}>
              <div className="col-span-1 border-r-[1px] border-r-gray-600 ">
                <input
                  type={"number"}
                  {...register("daycount", { required: true })}
                  className=" p-1 placeholder:text-sm placeholder:text-gray-600 placeholder:font-thin w-full outline-none border-[0px]"
                  placeholder="Enter duration "
                />
              </div>
              <div className="col-span-2 ">
                <Select
                  bordered={false}
                  defaultValue={"day"}
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setDaysLength(e)}
                >
                  <Option
                    value="day"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Day(s)
                  </Option>
                  <Option
                    value="week"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Week(s)
                  </Option>
                  <Option
                    value="month"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Month(s)
                  </Option>
                  <Option
                    value="year(s)"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Year(s)
                  </Option>
                </Select>
              </div>
            </div>
          </div>
          {errors.price && <ErrorText text={"Please enter a price"} />}
          <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
        </div>
      )}
      {step == 7 && (
        <div className="mt-4 pb-[30px]">
          <label className="text-primary font-semibold text-sm">Price</label>
          <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px]">
            <div className="d-inline flex items-center justify-center px-2">
              <AiOutlineUser
                size={20}
                color="text-primary"
                className="text-primary"
              />
            </div>
            <div className="ml-2">
              <input
                required
                {...register("price", { required: true })}
                placeholder={"Enter a name for this plan"}
                className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
              />
            </div>
          </div>
          {errors.price && <ErrorText text={"Please enter a price"} />}
          <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
        </div>
      )}
      <div className="absolute bottom-2 grid grid-cols-2 gap-6 left-0 mx-auto w-full">
        {step !== 1 && (
          <div
            className="border-[1px] text-center border-gray-600 drop-shadow-md px-6 rounded-md py-2 mx-2 bg-white cursor-pointer"
            onClick={() => {
              if (step == 1) return null;
              setstep((prev) => prev - 1);
            }}
          >
            Prev
          </div>
        )}
        {step !== 7 ? (
          <div
            className="drop-shadow-md text-center px-6 rounded-md py-2 mx-2 bg-gray-900 text-white cursor-pointer"
            onClick={() => setstep((prev) => prev + 1)}
          >
            Next
          </div>
        ) : (
          <div className="">
            <Button text={"Save"} loading={loading} />
          </div>
        )}
      </div>
    </form>
  );
};

export default DailyPowerplan;
