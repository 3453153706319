import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineUser } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { BillingContext } from "../../../../../../context/BillingContext";
import { billingController } from "../../../../../../controllers/BillingController";
import { Button } from "../../../../../Button";
import { MdOutlineAdUnits } from "react-icons/md";
import { IoIosCash } from "react-icons/io";
import { useMQTT } from "../../../../../../context/MqttContext";
const UnitSettings = () => {
  const { setBilling, disableBilling } = useContext(BillingContext);
  const [units, setUnits] = useState(500);
  const [rate, setRate] = useState("");
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const { mqttEvents } = useMQTT();
  const handleBilling = async () => {
    try {
      if (units < 500) {
        toast.error(
          "The minimum units that can be assigned to your devices is 500"
        );
        return;
      }
      if (!rate) {
        toast.error("Please enter a valid rate");
        return;
      }
      setloading(true);
      let res = await setBilling(id, rate, units);
      let result = billingController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }

      toast.success("Operation completed");
      setloading(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
        return;
      }
      toast.error(err.message);
      setloading(false);
    }
  };

  return (
    <div className="w-[80%] mx-auto">
      <div className="mt-4">
        <label className="text-primary font-normal text-sm">
          Minimum amount rechargable
        </label>
        <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 w-full h-[45px]">
          <div className="d-inline flex items-center justify-center px-2 ">
            <MdOutlineAdUnits
              size={20}
              color="text-primary"
              className="text-primary"
            />
          </div>
          <div className="ml-2 w-full">
            <input
              required
              value={units}
              onChange={(e) => setUnits(e.target.value)}
              type={"text"}
              placeholder={"Enter a name"}
              className="placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <label className="text-primary font-normal text-sm">
          Price per unit
        </label>
        <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 w-full h-[45px]">
          <div className="d-inline flex items-center justify-center px-2 ">
            <IoIosCash
              size={20}
              color="text-primary"
              className="text-primary"
            />
          </div>
          <div className="ml-2 w-full">
            <input
              required
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              type={"text"}
              placeholder={"Enter a billing rate"}
              className="placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
            />
          </div>
        </div>
        {rate && units && (
          <div className="italic text-secondary text-[12px] pt-1">
            Your device will be billed ₦{rate}/{units} units
          </div>
        )}
        <div className="mt-16 mb-3">
          <Button text={"Update"} loading={loading} onClick={handleBilling} />
        </div>
      </div>
    </div>
  );
};

export default UnitSettings;
