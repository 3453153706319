import { Select } from "antd";
import axios from "../../../utils/axios";
import { useContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import AcceptedUserTable from "../../../components/Channels/Users/Tables/AcceptedUserTable";
import UserCard from "../../../components/Channels/Users/UserCard";
import Layout from "../../../components/Layout";
import Rule from "../../../components/Rule/Index";
import TableSkimmer from "../../../components/TableSkimmer";
import { ChannelContext } from "../../../context/channelContext";
import { channelController } from "../../../controllers/channelController";
import { errorBlock } from "../../../controllers/errorBlock";
import PendingUserTable from "../../../components/Channels/Users/Tables/PendingUserTable";
import CancelledUserTable from "../../../components/Channels/Users/Tables/CancelledUserTable";
import RejectedUserTable from "../../../components/Channels/Users/Tables/RejectedUserTable";
import AllUserTable from "../../../components/Channels/Users/Tables/AllUserTable";
import RequestDetails from "../../../components/Channels/Users/Modals/RequestDetails";
import Skimmer from "../../../components/Loader/Skimmer";
import RequestCard from "../../../components/Channels/Cards/RequestCard";
import EmptyState from "../../../components/EmptyState";
import { HiUserGroup } from "react-icons/hi";
const UsersManagement = ({}) => {
  const [limit, setlimit] = useState(20);
  const [offset, setoffset] = useState(0);
  const { getSentChannelAddrequest } = useContext(ChannelContext);
  const [loading, setloading] = useState(true);
  const [userdetails, setuserdetails] = useState({});

  const [modal, setmodal] = useState(false);
  const [data, setdata] = useState({
    accepted: [],
    request: [],
    rejected: [],
    cancelled: [],
    request: [],
  });
  const [filter, setfilter] = useState("accepted");
  const filterFunction = (arr, filtertext) => {
    return arr?.filter((i) => i.status == filtertext);
  };
  // accepted
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/submeter/getsentchanneladdrequests", {
          limit: limit || 10,
          offset: offset || 0,
        });
        let result = channelController(res);
        const { body } = result.message;
        setdata((prev) => {
          return {
            accepted: filterFunction(body, "accepted"),
            request: filterFunction(body, "request"),
            rejected: filterFunction(body, "rejected"),
            cancelled: filterFunction(body, "canceled"),
            // request: body,
          };
        });
        setloading(false);
      } catch (err) {
        errorBlock(err);
        setloading(false);
      }
    })();
  }, []);
  return (
    <Layout>
      {modal && (
        <RequestDetails data={userdetails} open={modal} setopen={setmodal} />
      )}
      <div className="mt-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6 w-full  mx-auto">
          <UserCard
            value={data.request.length}
            title={"Pending request"}
            caption="All pending request on your channel"
          />
          <UserCard
            value={data.accepted.length}
            title={"Accepted request"}
            caption="All accepted request on your channel"
          />
          <UserCard
            value={data.rejected.length}
            title={"Rejected request"}
            caption="All rejected request on your channel"
          />
          <UserCard
            value={data.cancelled.length}
            title={"Cancelled request"}
            caption="All cancelled request on your channel"
          />
        </div>
        <div className="bg-white drop-shadow-md rounded p-3 mt-5">
          <div className="flex items-start justify-between">
            <div className="font-semibold text-gray-800">User Management</div>
            <div className="w-[200px]">
              <Select
                style={{
                  width: "100%",
                }}
                defaultValue={"accepted"}
                placeholder={"Select a filter"}
                options={[
                  {
                    label: "Accepted",
                    value: "accepted",
                  },
                  {
                    label: "Pending",
                    value: "request",
                  },
                  {
                    label: "Cancelled",
                    value: "cancelled",
                  },
                  {
                    label: "Rejected",
                    value: "rejected",
                  },
                ]}
                onChange={(e) => setfilter(e)}
              />
            </div>
          </div>
          <Rule />
          <div className="hidden md:block">
            {loading ? (
              <TableSkimmer entries={10} col={7} />
            ) : filter == "accepted" ? (
              <AcceptedUserTable
                data={data.accepted}
                setuserdetails={setuserdetails}
                open={modal}
                setopen={setmodal}
              />
            ) : filter == "request" ? (
              <PendingUserTable
                data={data.request}
                setuserdetails={setuserdetails}
                open={modal}
                setopen={setmodal}
              />
            ) : filter == "cancelled" ? (
              <CancelledUserTable
                data={data.cancelled}
                setuserdetails={setuserdetails}
                open={modal}
                setopen={setmodal}
              />
            ) : filter == "cancelled" ? (
              <AllUserTable
                data={data.request}
                setuserdetails={setuserdetails}
                open={modal}
                setopen={setmodal}
              />
            ) : filter == "rejected" ? (
              <RejectedUserTable
                data={data.rejected}
                setuserdetails={setuserdetails}
                open={modal}
                setopen={setmodal}
              />
            ) : null}
          </div>
          <div className="block md:hidden">
            {loading ? (
              [1, 2, 3, 4, 5, 6].map((_, i) => (
                <div key={i} className="rounded-md overflow-hidden my-2">
                  <Skimmer heigth={100} />
                </div>
              ))
            ) : data[filter].length == 0 ? (
              <EmptyState>
                <div className="flex flex-col item-center justify-center h-[400px]">
                  <div className="flex item-center justify-center">
                    <HiUserGroup size={40} />
                  </div>
                  <div className="font-semibold">
                    You don't have any request at the moment
                  </div>
                </div>
              </EmptyState>
            ) : (
              data[filter].map((i, index) => (
                <RequestCard
                  data={i}
                  key={index}
                  setuserdetails={setuserdetails}
                  open={modal}
                  setopen={setmodal}
                />
              ))
            )}
          </div>
        </div>
        <div className="h-[100px]"></div>
      </div>
    </Layout>
  );
};

export default UsersManagement;
