import moment from "moment";
import Backdrop from "../../../../Backdrop";
import { FcCalendar } from "react-icons/fc";
import CloseButton from "../../../../CloseButton";
const RequestDetails = ({ data, open, setopen }) => {
  return (
    <Backdrop open={open}>
      <div className="h-screen w-screen flex items-center justify-center">
        <div className="bg-white rounded drop-shadow-sm w-[95%] md:w-[450px] h-auto animate__fadeInUp animate__animated p-3">
          <div className="flex justify-between">
            <div className="font-semibold">Request details</div>
            <div className="w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <div className="">
            <div className="flex py-1 my-1 justify-between">
              <div className="">Recipient:</div>
              <div className="">{data?.recipient}</div>
            </div>
            <div className="flex py-1 my-1 justify-between">
              <div className="">Access given:</div>
              <div className="">{data?.accesscontrol}</div>
            </div>
            <div className="flex py-1 my-1 justify-between">
              <div className="">Status:</div>
              <div className="">{data?.status}</div>
            </div>
            <div className="flex py-1 my-1 justify-between">
              <div className="">Note:</div>
              {!data?.note ? (
                <div className="">No description was added to this request</div>
              ) : (
                <div className=" max-h-[200px] overflow-y-scroll ">
                  {data?.note}
                </div>
              )}
            </div>
            <div className="flex py-1 my-1 justify-between">
              <div className="">Date issued:</div>
              <div className="flex">
                <FcCalendar size={18} />
                {!data.datesent
                  ? "No date"
                  : moment(data?.datesent).format("lll")}
              </div>
            </div>
            <div className="flex py-1 my-1 justify-between">
              <div className="">Date accepted:</div>
              <div className="flex">
                <FcCalendar size={18} />
                {!data.dateactedupon ? "No date" : data?.dateactedupon}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default RequestDetails;
