import { Switch } from "antd";
import ChannelImg from "../../../assets/png/channelimg.png";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
const MeterCard = ({ data, refreshBtn }) => {
  const [modal, setmodal] = useState(false);
  const [selectedchannel, setselectedchannel] = useState(null);
  const option = ["Add to group", "Rename", "Recharge"];
  return (
    <div className="drop-shadow-md h-[180px] rounded-lg border bg-white p-1 relative">
      <div className="flex items-center justify-center mt-1">
        <div className="flex items-center justify-start">
          <div className="w-[50px] h-[50px] overflow-hidden">
            <img src={ChannelImg} className="w-100 h-100" />
          </div>
        </div>
        <div
          className="mt-[-10px] hidden cursor-pointer"
          // onClick={() => {
          //   if (selectedchannel) {
          //     return setselectedchannel(null);
          //   } else if (!selectedchannel) {
          //     return setselectedchannel(data?.channelid);
          //   }
          // }}
        >
          <BiDotsHorizontalRounded size={28} color={"black"} />
        </div>
      </div>

      <Link
        to={`/usermeter/${data?.deviceid}?name=${data?.devicealias}`}
        className=" mt-3 absolute h-[100px] top-[20px] w-full pt-8"
      >
        <div className="text-[14px] text-gray-600 font-light text-center ">
          {data?.devicetype}
        </div>
        <div className=" text-gray-800 font-semibold text-center text-[16px]">
          {data?.devicealias}
        </div>
      </Link>

      <div className="text-[11px] mt-4 w-full absolute bottom-2 translate-x-[-50%] left-[50%] px-4 flex items-center justify-between">
        <div className="">Date added</div>
        <div className="">{moment(data?.dateadded).format("ll")}</div>
        {/* <Switch
          size="default"
          defaultChecked
          onChange={(e) => {
            console.log(e);
          }}
        /> */}
      </div>
    </div>
  );
};

export default MeterCard;
