import axios from "../../../utils/axios";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import Layout from "../../../components/Layout";
import PaymentPlanTableMain from "../../../components/PaymentPlan/PaymentPlanTableMain";
import Rule from "../../../components/Rule/Index";
import { channelController } from "../../../controllers/channelController";
import { billingController } from "../../../controllers/BillingController";
import { errorBlock } from "../../../controllers/errorBlock";
import TableSkimmer from "../../../components/TableSkimmer";
import ViewPaymentPlan from "../../../components/PaymentPlan/Modals/ViewPaymentPlan";
import AddPlan from "../../../components/PaymentPlan/Drawer/AddPlan";
import PlanType from "../../../components/PaymentPlan/Modals/PlanType";
import Skimmer from "../../../components/Loader/Skimmer";
import MainPaymentPlanCard from "../../../components/PaymentPlan/Cards/MainPaymentPlanCard";
const PaymentPlan = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const [addplanmodal, setaddplanmodal] = useState(false);
  const [selectedplantype, setselectedplantype] = useState("");
  const [opendrawer, setopendrawer] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/submeter/paymentplan/getallpaymentplans", {
          planalias: "",
        });
        setdata(res.data.body);
        setloading(false);
      } catch (err) {
        if (err?.response) {
          toast.error(err.response?.data?.response);
          setloading(false);
          return;
        }
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {};
  }, [refresh]);

  return (
    <>
      <AddPlan
        open={opendrawer}
        setopen={setopendrawer}
        refreshBtn={setrefresh}
        selectedplantype={selectedplantype}
      />
      {modal && (
        <ViewPaymentPlan
          details={details}
          setopen={setmodal}
          open={modal}
          refreshBtn={setrefresh}
        />
      )}
      {addplanmodal && (
        <PlanType
          open={addplanmodal}
          setopen={setaddplanmodal}
          setselectedplantype={setselectedplantype}
          opendrawer={setopendrawer}
        />
      )}
      <Layout>
        <div className="mt-10">
          <div className="bg-white drop-shadow-md rounded-lg mb-24 ">
            <div className="flex items-center justify-between">
              <div className=" pt-3 px-3">
                <div className="font-Kanit text-[17px] font-normal text-gray-800">
                  Payment plans
                </div>
                <div className="text-gray-600">
                  All the payment plans you have created
                </div>
              </div>
              <div className="w-[150px]" onClick={() => setaddplanmodal(true)}>
                <Button text={"Add plan"} />
              </div>
            </div>

            <Rule />
            <div className="p-3 hidden md:block">
              {loading ? (
                <TableSkimmer entries={10} col={7} />
              ) : (
                <PaymentPlanTableMain
                  data={data}
                  setdetails={setdetails}
                  setmodal={setmodal}
                />
              )}
            </div>
            <div className="block md:hidden px-3">
              {loading
                ? [1, 2, 3, 4, 5].map((_, i) => (
                    <div className="rounded-md overflow-hidden" key={i}>
                      <Skimmer heigth={100} />
                    </div>
                  ))
                : data.map((i, index) => (
                    <MainPaymentPlanCard
                      data={i}
                      key={index}
                      plandetail={details}
                      setplandetails={setdetails}
                      planmodal={modal}
                      setplanmodal={setmodal}
                    />
                  ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PaymentPlan;
