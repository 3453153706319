import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import MoneyBgImage from "../../../assets/png/v300batch2-nunoon-13.jpg";

const WalletBalance = () => {
  return (
    // border-[1px] p-2 border-gray-300
    <div className="h-[auto] overflow-hidden relative border-[1px] border-gray-300  p-2 rounded-md">
      <div className="flex z-40 items-center justify-between">
        <div className="text-bold text-">Wallet balance</div>
        {/* bg-[#1414a214] */}
        <div className="flex w-[auto] border-[1px] border-gray-300 rounded-md items-center justify-between ">
          <div className="border-r-[1px] cursor-pointer border-gray-300 p-1">
            <IoIosArrowRoundBack size={20} />
          </div>
          <div className="flex items-center cursor-pointer px-1">
            <div className="h-[20px] w-[20px] ">
              <img
                src="https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ec.svg"
                className="h-full w-full pr-1"
              />
            </div>
            <div className="">NGN</div>
          </div>
          <div className="border-l-[1px] cursor-pointer border-gray-300 p-1">
            <IoIosArrowRoundForward size={20} />
          </div>
        </div>
      </div>
      <div className="text-bold font-bold pt-1 text-[30px] mt-3 ">
        ₦30,204.00
      </div>
      <div className="grid grid-cols-1 mt-3">
        <div className="w-full cursor-pointer p-2 mt-3 bg-black text-white border-[2px] text-center rounded-md border-gray-500">
          Add money
        </div>
      </div>
    </div>
  );
};

export default WalletBalance;
