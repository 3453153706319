import Backdrop from "../../Backdrop";
import { AiOutlineUser } from "react-icons/ai";
import CloseButton from "../../CloseButton";
import { useState } from "react";
import Rule from "../../Rule/Index";
import { Button } from "../../Button";
import { toast } from "react-hot-toast";
import { channelController } from "../../../controllers/channelController";
import axios from "../../../utils/axios";
import { Select } from "antd";
import { accessType } from "../../../constant";
import { useParams } from "react-router-dom";

const ShareChannel = ({ closeBtn, setopen, open }) => {
  const { id } = useParams();
  const [tabstate, settabstate] = useState(false);
  const [username, setusername] = useState("");
  const [access, setAccess] = useState("");
  const [note, setNote] = useState("");
  const [loading, setloading] = useState({
    details: false,
    sendLoading: false,
  });
  const [userdetails, setuserdetails] = useState({});
  const handleVerify = async () => {
    try {
      setloading((prev) => {
        return { ...prev, details: true };
      });
      if (!username) return toast.error("Please a username.");
      let res = await axios.post("/auth/account/getuser", {
        username,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading((prev) => {
          return { ...prev, details: false };
        });
        return;
      }

      if (!Object.entries(result.message).length) {
        toast.error("User doesn't exist. Please try again");
        setloading((prev) => {
          return { ...prev, details: false };
        });
        return;
      }
      settabstate(true);
      setuserdetails(result?.message);
      setloading((prev) => {
        return { ...prev, details: false };
      });
    } catch (err) {
      if (err?.response) {
        toast.error(err?.response?.data?.response);
        setloading((prev) => {
          return { ...prev, details: true };
        });
        return;
      }
      toast.error(err.message);
      setloading((prev) => {
        return { ...prev, details: true };
      });
    }
  };

  const handleSendRequest = async () => {
    try {
      if (!note) {
        toast.error("Please add a note");
        return;
      }
      if (!access) {
        toast.error("Please select the access type for this user.");
        return;
      }

      setloading((prev) => {
        return { ...prev, sendLoading: true };
      });

      let res = await axios.post("/submeter/assignchannel", {
        targetusername: userdetails?.username,
        channelid: id,
        note: note,
        accesslevel: access,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](`${result.message}`);
        setloading((prev) => {
          return { ...prev, sendLoading: false };
        });
        // console.log(result);
        return;
      }
      setloading((prev) => {
        return { ...prev, sendLoading: false };
      });
      toast.success("Request sent successfully");
      closeBtn(false);
      console.log(result);
    } catch (err) {
      toast.error(err.message);
      setloading((prev) => {
        return { ...prev, sendLoading: false };
      });
      return;
    }
  };

  return (
    <Backdrop setopen={setopen} open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[500px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex align-items-start justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Share Channel
              </div>
              <div className="w-full text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className="">
              <CloseButton closeBtn={closeBtn} />
            </div>
          </div>
          <Rule />
          {!tabstate && (
            <div className="">
              <div className="">
                <div className="font-semibold  text-center text-secondary">
                  User verification
                </div>
                <div className="text-center text-gray-700 font-light">
                  Fill in the the field below to search for the user you want to
                  send a request to
                </div>
              </div>
              <div className="mt-4">
                <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 w-full h-[45px]">
                  <div className="d-inline flex items-center justify-center px-2 ">
                    <AiOutlineUser
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2 w-full">
                    <input
                      required
                      value={username}
                      onChange={(e) => setusername(e.target.value)}
                      type={"text"}
                      placeholder={"Enter a name"}
                      className="placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                    />
                  </div>
                </div>
                <div className="mt-16 mb-3">
                  <Button
                    text={"Verify"}
                    loading={loading.details}
                    onClick={handleVerify}
                  />
                </div>
              </div>
            </div>
          )}

          {tabstate && (
            <div className="">
              <div className="">
                <div className="font-semibold  text-center text-secondary">
                  Compose request
                </div>
                <div className="text-center text-gray-700 font-light">
                  Fill in the the field below
                </div>
              </div>
              <div className="mt-4">
                <label className="text-primary font-normal text-sm">
                  Full name
                </label>
                <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineUser
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2">
                    <div className="">
                      {userdetails?.firstname} {userdetails?.lastname}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <label className="text-primary font-normal text-sm">
                    Access type
                  </label>
                  <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-1 h-[45px]">
                    <div className="w-full ">
                      <Select
                        className=""
                        bordered={false}
                        placeholder="Select access type"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        onChange={(e) => setAccess(e)}
                        options={accessType}
                      />
                    </div>
                  </div>
                  {access == "single" && (
                    <div className="text-[12px] italic">
                      This user will have sole access to control and monitor
                      this channel
                    </div>
                  )}
                  {access == "multiple" && (
                    <div className="text-[12px] italic">
                      You and{" "}
                      <span className="font-semibold">
                        {userdetails?.firstname} {userdetails?.lastname}
                      </span>{" "}
                      will have access to control and monitor this channel
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <label className="text-primary font-normal text-sm">
                    Write a note
                  </label>
                  <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-1 h-[120px]">
                    <textarea
                      value={note}
                      className="resize-none h-full border-none outline-none p-2 text-gray-800 font-light w-full"
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                  <div className=""></div>
                </div>
                <div className="mt-16 mb-3 grid lg:grid-cols-2 gap-4">
                  <div
                    className="border-[1px] h-[43px] flex items-center justify-center border-gray-800 rounded-md bg-white cursor-pointer"
                    onClick={() => settabstate(false)}
                  >
                    <div className="text-gray-800">New user</div>
                  </div>
                  <Button
                    text={"Send request"}
                    loading={loading.sendLoading}
                    onClick={handleSendRequest}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default ShareChannel;
