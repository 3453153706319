export const cardController = (res) => {
  if (res.data.response == "invalid session") {
    return {
      message: "Invalid sessionid",
      type: "error",
    };
  } else if (res.data.response == "failure") {
    return {
      message: "Operation failed. Please try again",
      type: "error",
    };
  } else if (res.data.response === "true") {
    return {
      type: "success",
      message: res.data.response,
    };
  } else if (res.data.response == "failed") {
    return {
      type: "error",
      message: "Operation failed please try again",
    };
  } else if (res.data.response == "unauthorized") {
    return {
      type: "error",
      message: "You don't have access to make this request",
    };
  } else if (res.data.transactionid) {
    if (res.data.transactionid == "failed") {
      return {
        message: "Operation failed. Please try again",
        type: "error",
      };
    } else {
      return {
        message: res.data,
        type: "success",
      };
    }
  }
};
