export const dateOptions = [
  { value: "today", label: "Today" },
  { value: "week", label: "This week" },
  { value: "month", label: "This month" },
  { value: "year", label: "This year" },
];

export const accessType = [
  {
    value: "single",
    label: "Single",
  },
  {
    value: "multiple",
    label: "Multiple",
  },
];
