import { createContext, useState, useEffect, useContext, useRef } from "react";
import { getToken } from "../utils/token";
import { channelController } from "../controllers/channelController";
import axios from "../utils/axios";
import { toast } from "react-hot-toast";
import ProfileModal from "../components/ProfileModal";
const ProfileContext = createContext();

export const useMQTT = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [open, setopen] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const status = await getToken("spiral_registration", "notverified");
        if (status == "notverified" || !status) {
          setopen(true);
        }
        // let username = await getToken("spiral_username");
        // let res = await axios.post("/auth/account/getuser", {
        //   username,
        // });

        // let result = channelController(res);
        // console.log(result, "result hello");
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.response);
          return;
        }
        toast.error(err.message);
      }
    })();
    return () => {};
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        loading,
      }}
    >
      <ProfileModal open={open} setopen={setopen} />
      {children}
    </ProfileContext.Provider>
  );
};
