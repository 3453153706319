import Layout from "../../../components/Layout";
import { HiRectangleGroup } from "react-icons/hi2";
import { themeColor } from "../../../constant/color";
import { Switch } from "antd";
import { Button } from "../../../components/Button";
import { Link, useFetcher } from "react-router-dom";
import { IoAddCircleOutline } from "react-icons/io5";
import AddToGroup from "../../../components/Channels/Groups/AddToGroup";
import { useContext, useEffect, useState } from "react";
import ChannelCard from "../../../components/Channels/ChannelCard";
import AddMeter from "../../../components/Meter/AddMeter";
import MUserDashboard from "../../../screensMobile/MobileDashboard/MUserDashboard";
import { ChannelContext } from "../../../context/channelContext";
import { errorBlock } from "../../../controllers/errorBlock";
import { useDispatch, useSelector } from "react-redux";
import { updateChannels } from "../../../redux/slice/channelSlice";
import axios from "../../../utils/axios";
import ChannelPill from "../../../components/Channels/ChannelPill";
import ChannelMoreBtn from "../../../components/Channels/ChannelMoreBtn";
import { channelController } from "../../../controllers/channelController";
import { toast } from "react-hot-toast";
import Skimmer from "../../../components/Loader/Skimmer";
import { BsPlusCircle } from "react-icons/bs";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import ChannelImg from "../../../assets/png/channelimg.png";
import PaginationButton from "../../../components/PaginationButton";
import RenameModal from "../../../components/Channels/Modals/RenameModal";
import ChannelGroups from "../../../components/Channels/Drawer/ChannelGroups";
import { getToken } from "../../../utils/token";
import { useMQTT } from "../../../context/MqttContext";
import EnergyCharts from "../../../components/Dashboards/Charts/EnergyChart";
import VendHistory from "../../../components/Dashboards/Charts/Transactions/VendHistory";
import DashboardRevenueHistory from "../../../components/Dashboards/Charts/Transactions/Revenue";
import { Radio, Select, Space } from "antd";
import { GiReceiveMoney } from "react-icons/gi";
import WalletBalance from "../../../components/Dashboards/WalletBalance";
import { BsMenuButton } from "react-icons/bs";
const UserDashboard = () => {
  const [size, setSize] = useState("middle");
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const [modal, setmodal] = useState({
    group: false,
  });
  const { mqttEvents } = useMQTT();
  const [metermodal, setmetermodal] = useState(false);
  const [groupmodal, setgroupmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [limit, setlimit] = useState(15);
  const [data, setdata] = useState([]);
  const [offset, setoffset] = useState(0);
  const [page, setpage] = useState(1);
  const [groupprefix, setgroupprefix] = useState("");
  const [messages, setmessages] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [selectedoption, setselectedoption] = useState("");
  const [username, setusername] = useState("");
  // input date
  const [inputfield, setinputfield] = useState("");
  // rename state
  const [showrename, setshowrename] = useState(false);
  const [renamemodal, setrenamemodal] = useState(true);
  const [selectedchannel, setselectedchannel] = useState({});
  // group states

  const [showgroup, setshowgroup] = useState(false);

  // set number of max listeners
  mqttEvents.setMaxListeners(30);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const user = await getToken("spiral_username");
  //       mqttEvents.on("message", (message) => {
  //         if (message.topic === `mqtt_${user}`) {
  //           if (!message.payloadString) return null;
  //           if (JSON.parse(message.payloadString).type == "GEN") {
  //             const { type, deviceid, status } = JSON.parse(
  //               message.payloadString
  //             );

  //             let poweredChannels = data.map((item) => {
  //               if (
  //                 type == "GEN" &&
  //                 deviceid == item.channelid &&
  //                 status == 1
  //               ) {
  //                 return {
  //                   ...item,
  //                   powerstate: 1,
  //                 };
  //               } else {
  //                 return item;
  //               }
  //             });

  //             setdata(poweredChannels);
  //           }
  //         }
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  //   return () => {};
  // }, [data]);

  // get all meters
  // GEN
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setloading(true);
  //       let res = await axios.post("/submeter/getallchannels", {
  //         groupprefix,
  //         limit,
  //         offset,
  //       });
  //       const result = channelController(res);
  //       if (result.type !== "success") {
  //         toast.error(result.message);
  //         setloading(false);
  //         return;
  //       }

  //       // console.log(result, "this is the result ");
  //       const devicePowerState = result.message.body.map((item, index) => {
  //         if (item.type == "group") {
  //           return {
  //             alias: item.alias,
  //             channelid: item.alias,
  //             type: item.type,
  //             billingactive: false,
  //           };
  //         } else if (item.type == "single") {
  //           return {
  //             alias: item.alias,
  //             channelid: item.channelid,
  //             type: item.type,
  //             billingactive: item.billingactive,
  //             powerstate: 0,
  //           };
  //         }
  //       });

  //       // make gen all
  //       // take out single
  //       setdata(devicePowerState);
  //       setloading(false);

  //       // for await (const channel of devicePowerState) {
  //       //   if (channel.type == "single") {
  //       //     console.log("ran");
  //       //     let res = await axios.post("/commands/getpowerstatesofmeter", {
  //       //       meterid: channel.channelid,
  //       //       transactiontopic: `mqtt_${username}`,
  //       //     });
  //       //     let result = channelController(res);
  //       //     if (result.type !== "success") {
  //       //       toast[result.type](result.message);
  //       //       return;
  //       //     }
  //       //   }
  //       // }

  //       // mqttEvents.on("message", (message) => {
  //       //   if (message.topic == `mqtt_${username}`) {
  //       //     console.log(message, ".//GEN");
  //       //     const { payloadString } = message;
  //       //     if (JSON.parse(payloadString)?.type == "GEN") {
  //       //       let poweredChannels = data.map((item) => {
  //       //         if (
  //       //           JSON.parse(payloadString)?.type == "GEN" &&
  //       //           JSON.parse(payloadString)?.deviceid == item.channelid &&
  //       //           JSON.parse(payloadString)?.status == 1
  //       //         ) {
  //       //           return {
  //       //             ...item,
  //       //             powerstate: 1,
  //       //           };
  //       //         } else {
  //       //           return item;
  //       //         }
  //       //       });
  //       //       setdata(poweredChannels);
  //       //     }
  //       //   }
  //       // });
  //     } catch (err) {
  //       errorBlock(err);
  //       setloading(false);
  //     }
  //   })();
  //   return () => {};
  // }, [refresh]);
  // username
  useEffect(() => {
    (async () => {
      try {
        let res = await getToken("spiral_username");
        setusername(res);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let res = await axios.post(
          "/submeter/getreceivedchanneladdrequests",
          {}
        );
        const result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setloading(false);
          return;
        }
        setmessages(result.message.body);
        setloading(false);
      } catch (err) {
        console.log(err);
        setloading(false);
      }
    })();
  }, []);

  return (
    <>
      {/* <div className="">
        <MUserDashboard />
      </div> */}
      <Layout>
        {groupmodal && <AddToGroup closeBtn={setgroupmodal} />}
        {metermodal && (
          <AddMeter closeBtn={setmetermodal} refreshBtn={setrefresh} />
        )}
        {showrename && (
          <RenameModal
            open={showrename}
            setopen={setshowrename}
            refreshBtn={setrefresh}
            closeBtn={setrenamemodal}
            selectedchannel={selectedchannel}
            setselectedchannel={setselectedchannel}
          />
        )}
        {showgroup && (
          <ChannelGroups
            title={"Device Groups"}
            open={showgroup}
            setopen={setshowgroup}
            refreshBtn={setrefresh}
            selectedchannel={selectedchannel}
            setselectedchannel={setselectedchannel}
          />
        )}
        <div className="pb-[200px]">
          {/* Top */}
          <div className="flex flex-wrap items-center justify-between mt-1">
            <div className="flex items-center">
              <div className="text-3xl pr-4">⛅</div>
              <div className="">
                <div className="text-primary font-semibold text-[16px]">
                  Welcome {username}
                </div>
                <div className="text-[13px]">
                  Overview of all the meters you manage.
                </div>
              </div>
            </div>

            <div
              className="w-full h-[40px] md:w-[150px] lg:w-[150px] flex mt-4 items-center cursor-pointer justify-center  bg-secondary rounded-md p-1"
              onClick={() => setmetermodal(true)}
            >
              <div className="">
                <BsPlusCircle size={20} color={themeColor.white} style={{}} />
              </div>
              <div className="text-white px-4 text-[14px]">Add device</div>
            </div>
            <Link to="/userroles" className="lg:w-[190px] w-[100%] my-4 hidden">
              <Button text={"Manage account"} />
            </Link>
          </div>
          {/* Add to group */}
          <div className="mt-8 hidden">
            <div className="flex items-center justify-between px-2">
              <div className="font-semibold text-primary">Grouped Channels</div>
              <div
                className="flex items-center justify-center cursor-pointer "
                onClick={() => setgroupmodal(true)}
              >
                <div className="flex items-center justify-center">
                  <IoAddCircleOutline className="text-secondary" />
                </div>
                <div className="text-secondary pl-1">New group</div>
              </div>
            </div>
            <div className="mt-4 max-w-full overflow-x-scroll overflow-y-hidden w-[100%]">
              <div className="flex items-start overflow-x-scroll overflow-y-hidden w-[1500px]">
                {Array.from(Array(7)).map((_, i) => (
                  <ChannelPill key={i} />
                ))}
                <ChannelMoreBtn />
              </div>
            </div>
          </div>
          <hr className="my-4 border-black-600 border-2" />

          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <div className="bg-white p-2 rounded-md shadow-md h-[auto]">
                <div className="font-bold">
                  Energy Consumption across meters
                </div>
                <div className="h-[450px]">
                  <EnergyCharts />
                </div>
              </div>
            </div>
            <div className="col-span-1 bg-white shadow-md p-2 rounded-md ">
              <div className="grid grid-col-1 h-[180px] z-10 bg-white ">
                <WalletBalance />
              </div>
              <div className="col-span-1 mt-3 grid grid-cols-2 gap-4">
                <div className="border-[1px] border-gray-300 rounded-md h-[280px]"></div>
                <div className="border-[1px] border-gray-300 rounded-md h-[280px]"></div>
              </div>
            </div>
          </div>
          {/* voltage drop */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="col-span-2 ">
              <div className="bg-white p-2 rounded-md shadow-md h-[290px]">
                <div className="flex items-center justify-between">
                  <div className="">
                    <div className="font-bold">Faults on meters</div>
                    <div className="font-normal">
                      The devices below have experienced a voltage drop
                    </div>
                  </div>
                  <div className="flex items-center justify-end">
                    <div className="flex items-center mr-4">
                      <div className="h-[10px] w-[10px] mr-2 rounded-full bg-red-600"></div>
                      <div className="">High voltage</div>
                    </div>
                    <div className="flex items-center">
                      <div className="h-[10px] w-[10px] mr-2 rounded-full bg-orange-300"></div>
                      <div className="">Low voltage</div>
                    </div>
                  </div>
                </div>
                <div className="h-[210px] flex gap-4 pt-3 px-2 ">
                  {[100, 270, 10].map((i, index) => (
                    <div
                      key={index}
                      className="border-[1px] overflow-hidden shadow-md relative w-[220px] border-gray-300 rounded-md h-full"
                    >
                      <div className="flex flex-col items-center w-full justify-between">
                        <div className="bg-gray-200 text-[13px] text-center p-1 w-full">
                          3 fault detected
                        </div>
                        <div className="font-bold p-2 text-center text-[16px]">
                          Office Lights
                        </div>
                        <div
                          className={
                            i > 200
                              ? "text-red-600 text-[40px] text-o font-bold"
                              : "text-orange-300 text-[40px] text-o font-bold"
                          }
                        >
                          {i} <sub className="text-[13px]">volts</sub>
                        </div>
                        <div className="text-center absolute top-[140px] text-[13px] p-2 border-t-[1px] border-t-gray-300">
                          Device experienced a voltage drop
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-1 p-2 bg-white rounded-md shadow-md h-[290px]">
              <div className="flex items-center justify-between">
                <div className="font-semibold">
                  <span className="pr-2">📩</span>Incoming Request
                </div>
                <Link to="/request" className="">
                  View all
                </Link>
              </div>
              <div className="mt-2">
                {loading ? (
                  <>
                    {[1, 2, 3, 4].map((_, i) => (
                      <div className="my-1" key={i}>
                        <Skimmer heigth={40} />
                      </div>
                    ))}
                  </>
                ) : messages.length == 0 ? (
                  <div className="flex w-full h-[200px] flex-col items-center justify-center">
                    <div className="">📩</div>
                    <div className="">
                      You don't have any message at the moment.
                    </div>
                  </div>
                ) : (
                  [1, 2, 3].map((_, i) => (
                    <div className="p-2 even:bg-[#f7f6f6] cursor-pointer">
                      <div className=" flex items-center justify-between">
                        <div className="">
                          Sender: <span className="font-semibold">Zannu</span>
                        </div>
                        <div className="">
                          Device name:{" "}
                          <span className="font-semibold">Bank 1</span>{" "}
                        </div>
                      </div>
                      <div className="mt-1">
                        Note: This is the channel you can access...
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {/* Vend history */}
          <div className="grid grid-cols-2 gap-4 mt-4 ">
            <div className="bg-white p-2 rounded-md shadow-md">
              <div className="flex align-items justify-between">
                <div className="font-bold">Revenue generate across meters</div>
                <div className="">
                  <Select
                    defaultValue="today"
                    style={{
                      width: 120,
                    }}
                    // onChange={handleChange}
                    options={[
                      {
                        value: "today",
                        label: "Today",
                      },
                      {
                        value: "week",
                        label: "This week",
                      },
                      {
                        value: "month",
                        label: "This month",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="h-[400px]">
                <DashboardRevenueHistory />
              </div>
            </div>
            <div className="bg-white p-2 rounded-md shadow-md">
              <div className="flex items-center justify-between ">
                <div className="font-bold">Vend history</div>
                <div className="">
                  <Select
                    defaultValue="today"
                    style={{
                      width: 120,
                    }}
                    // onChange={handleChange}
                    options={[
                      {
                        value: "today",
                        label: "Today",
                      },
                      {
                        value: "week",
                        label: "This week",
                      },
                      {
                        value: "month",
                        label: "This month",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="h-[400px]">
                <VendHistory />
              </div>
            </div>
          </div>

          {/*  */}
          {/* channels  */}
          <div className="hidden h-auto bg-white drop-shadow-md rounded-md mb-[60px]">
            <div className="px-3 pt-2 pb-3 flex flex-col md:flex-row md:justify-between">
              <div className="py-2">
                <div className="font-semibold text-gray-800">
                  Recently added devices.
                </div>
                <div className="text-gray-600 text-start font-normal">
                  List of all the channels you have and the ones shared with
                  you.
                </div>
              </div>
              {/* Search bar  */}
              <div className="flex overflow-hidden md:w-[200px] lg:w-[200px] items-center w-full border-[1px] rounded-md border-gray-300 mt-2 h-[40px] w-100">
                <div className="ml-2 w-100">
                  <input
                    type={"text"}
                    placeholder={"Search for device..."}
                    onChange={(e) => setinputfield(e.target.value)}
                    className="placeholder:text-sm block w-100 text-[13] placeholder:font-normal outline-none border-[0px]"
                  />
                </div>
              </div>
              <div
                className="w-[fit-content] hidden mt-4 items-center cursor-pointer justify-center border-[1px] border-secondary rounded-full p-1"
                onClick={() => setmetermodal(true)}
              >
                <div className="">
                  <BsPlusCircle
                    size={20}
                    color={themeColor.secondary}
                    style={{}}
                  />
                </div>
                <div className="text-secondary pl-2">Add meter</div>
              </div>
            </div>

            <hr className="border-[0.5px] border-gray-200" />
            {loading ? (
              <div className=" p-2 grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 mt-4">
                {Array.from(Array(15)).map((_, i) => (
                  <div className="h-[190px] drop-shadow-sm" key={i}>
                    <Skimmer heigth={"100%"} />
                  </div>
                ))}
              </div>
            ) : !data.length ||
              data.filter((i) =>
                i.alias.toLowerCase().includes(inputfield.toLowerCase())
              ).length == 0 ? (
              <div className="mt-[50px] pb-10 flex flex-col justify-center items-center px-10">
                <div className="border-[0.5px] border-gray-500 rounded-md h-[500px] p-10 flex flex-col justify-center items-center w-full">
                  <div className="h-[100px] w-[100px]">
                    <img src={ChannelImg} className="w-full h-full " />
                  </div>
                  <div className="text-center font-semibold text-gray-700">
                    {inputfield
                      ? `You haven't added any device called '${inputfield}'`
                      : "You haven't added any device at the moment"}
                  </div>
                  <div
                    className="w-[fit-content] cursor-pointer mt-4 flex items-center justify-center border-[1px] border-secondary rounded-full p-1"
                    // onClick={() => setaddmetermodal(true)}
                  >
                    <div className="">
                      <BsPlusCircle
                        size={20}
                        color={themeColor.secondary}
                        style={{}}
                      />
                    </div>
                    <div
                      className="text-secondary pl-2"
                      onClick={() => setmetermodal(true)}
                    >
                      Add device
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4 p-3">
                <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 ">
                  {data
                    .filter((i) =>
                      i.alias.toLowerCase().includes(inputfield.toLowerCase())
                    )
                    .map((i, index) => (
                      <ChannelCard
                        key={index}
                        data={i}
                        showrename={showrename}
                        setshowrename={setshowrename}
                        selectedchannel={selectedchannel}
                        setselectedchannel={setselectedchannel}
                        showgroup={showgroup}
                        setshowgroup={setshowgroup}
                      />
                    ))}
                </div>
                <PaginationButton
                  page={page}
                  setpage={setpage}
                  setoffset={setoffset}
                  offset={offset}
                  limit={limit}
                  setlimit={setlimit}
                  data={data}
                  refresh={refresh}
                  setrefresh={setrefresh}
                />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UserDashboard;
