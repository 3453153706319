import moment from "moment-timezone";
import { useContext, createContext } from "react";

export const TimeContext = createContext();

const timeReducer = () => {
  return {
    timeObject: (
      startdate = "",
      enddate = "",
      dateFormat = "YYYY-MM-DD, HH:mm"
    ) => {
      let usertimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (usertimezone != "Africa/Lagos") {
        console.log(startdate, "--worked");
        startdate = startdate
          ? moment(startdate).tz("Africa/Lagos", false).format(dateFormat)
          : "";
        enddate = enddate
          ? moment(enddate).tz("Africa/Lagos", false).format(dateFormat)
          : "";
      } else {
        startdate = startdate ? moment(startdate).format(dateFormat) : "";
        enddate = enddate ? moment(enddate).format(dateFormat) : "";
      }
      return {
        startdate,
        enddate,
        dateFormat,
      };
    },
  };
};

export const TimeContextProvider = ({ children }) => {
  return (
    <TimeContext.Provider value={timeReducer()}>
      {children}
    </TimeContext.Provider>
  );
};
