import LayoutMobile from "../../../components/LayoutMobile";
import { BsPlusCircle } from "react-icons/bs";
import { themeColor } from "../../../constant/color";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChannelContext } from "../../../context/channelContext";
import Skimmer from "../../../components/Loader/Skimmer";
import ChannelCard from "../../../components/Channels/ChannelCard";
import { updateChannels } from "../../../redux/slice/channelSlice";
import { toast } from "react-hot-toast";
import Layout from "../../../components/Layout";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import BackBtn from "../../../components/BackBtn";
import PaginationButton from "../../../components/PaginationButton";
import RenameModal from "../../../components/Channels/Modals/RenameModal";
import ChannelGroups from "../../../components/Channels/Drawer/ChannelGroups";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";
import { useMQTT } from "../../../context/MqttContext";
import { getToken } from "../../../utils/token";
import { BsMenuButtonWideFill } from "react-icons/bs";
import { Button, Checkbox } from "antd";
import FreezeModal from "../../../components/Channels/Modals/FreezeModal";
import DIS_ALL from "../../../components/Dashboards/modals/DIS_ALL";

const AllChannels = () => {
  const { getAllChannels } = useContext(ChannelContext);
  const [limit, setlimit] = useState(100);
  const { mqttEvents } = useMQTT();
  const [offset, setoffset] = useState(0);
  const [loading, setloading] = useState(true);
  const [showgroup, setshowgroup] = useState(false);
  const [showrename, setshowrename] = useState(false);
  const [renamemodal, setrenamemodal] = useState(true);
  const [selectedchannel, setselectedchannel] = useState({});
  const [inputfield, setinputfield] = useState("");
  const [data, setdata] = useState([]);
  const [page, setpage] = useState(1);
  const [toggleCheckbox, settoggleCheckbox] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const [showfreezemodal, setshowfreezemodal] = useState(false);
  const [opendis, setopendis] = useState(false);
  // const [hold, sethold] = useState([]);
  const [selectedMeter, setselectedMeter] = useState([]);
  // set number of maximum listeners
  const [channelcount, setchannelcount] = useState(0);
  mqttEvents.setMaxListeners(30);

  useEffect(() => {
    (async () => {
      try {
        const user = await getToken("spiral_username");
        mqttEvents.on("message", (message) => {
          if (message.topic === `mqtt_${user}`) {
            if (!message.payloadString) return null;
            if (
              JSON.parse(message.payloadString).type == "GEN" ||
              JSON.parse(message.payloadString).type == "TON" ||
              JSON.parse(message.payloadString).type == "OFF"
            ) {
              const { type, deviceid, status } = JSON.parse(
                message.payloadString
              );
              // console.log(status, "-- status for TON");
              // console.log(hold, "--prev channels");
              let poweredChannels = data.map((item) => {
                if (
                  // if we have a GEN call or user tries to TON a channel
                  type === "GEN" &&
                  deviceid === item.channelid
                  // status === 1
                ) {
                  return {
                    ...item,
                    powerstate: Number(status),
                  };
                } else if (type === "TON" && deviceid === item.channelid) {
                  if (status == "SUCCESS") {
                    return {
                      ...item,
                      powerstate: 1,
                    };
                  } else {
                    return {
                      ...item,
                      powerstate: 0,
                    };
                  }
                } else if (type === "OFF" && deviceid === item.channelid) {
                  if (status == "SUCCESS") {
                    return {
                      ...item,
                      powerstate: 0,
                    };
                  } else {
                    return {
                      ...item,
                      powerstate: 0,
                    };
                  }
                } else {
                  return item;
                }
              });
              // console.log(poweredChannels, " --- updated channels");
              setdata(poweredChannels);
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    })();
    return () => {};
  }, [data]);

  // count of all channels
  useEffect(() => {
    let ignore = false;
    (async () => {
      try {
        let res = await axios.post("/submeter/countgetallchannels", {});
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setloading(false);
          return;
        }
        setchannelcount(result.message.count);
      } catch (err) {
        console.log(err);
      }
    })();
    return () => {
      ignore = true;
    };
  });

  // Get all channel
  useEffect(() => {
    (async () => {
      try {
        let username = await getToken("spiral_username");

        let res = await axios.post("/submeter/getallchannels", {
          groupprefix: "",
          limit: limit,
          offset: offset,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setloading(false);
          return;
        }
        // console.log(result, "this is the result ");
        const devicePowerState = result.message.body.map((item, index) => {
          if (item.type == "group") {
            return {
              alias: item.alias,
              channelid: item.alias,
              type: item.type,
              billingactive: false,
              checked: false,
            };
          } else if (item.type == "single") {
            return {
              alias: item.alias,
              channelid: item.channelid,
              type: item.type,
              billingactive: item.billingactive,
              powerstate: 0,
              checked: false,
            };
          }
        });

        // take out single
        setdata(devicePowerState);
        // sethold(devicePowerState);

        setloading(false);

        for await (const channel of devicePowerState) {
          if (channel.type == "single") {
            let res = await axios.post("/commands/getpowerstatesofmeter", {
              meterid: channel.channelid,
              transactiontopic: `mqtt_${username}`,
            });
            let result = channelController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              return;
            }
          }
        }
      } catch (err) {
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {};
  }, [refresh]);

  // handle Add meters
  const handleAdd = (alias) => {
    // selected toggle the selected meter checked status
    let updatedData = data.map((i) => {
      if (i?.alias == alias) {
        return {
          ...i,
          checked: i?.checked ? false : true,
        };
      } else {
        return i;
      }
    });

    setdata(updatedData);

    // console.log(updatedData, "update data");
    // loop through the meters and groups alias
    // if the alias is == to the selected alias
    // update the checked status
    // and add to the all checked meter array
  };
  return (
    <Layout pageTitle={"Channels"}>
      {showrename && (
        <RenameModal
          open={showrename}
          setopen={setshowrename}
          refreshBtn={setrefresh}
          closeBtn={setrenamemodal}
          selectedchannel={selectedchannel}
          setselectedchannel={setselectedchannel}
        />
      )}

      {showfreezemodal && (
        <FreezeModal
          data={data}
          open={showfreezemodal}
          setopen={setshowfreezemodal}
          refreshBtn={setrefresh}
          closeBtn={setshowfreezemodal}
          opendis={opendis}
          setopendis={setopendis}
        />
      )}
      {showgroup && (
        <ChannelGroups
          title={"Device Groups"}
          open={showgroup}
          setopen={setshowgroup}
          refreshBtn={setrefresh}
          selectedchannel={selectedchannel}
          setselectedchannel={setselectedchannel}
        />
      )}

      {opendis && (
        <DIS_ALL
          selectedpowerstate={"dis"}
          setopen={setopendis}
          open={opendis}
          aliases={data}
        />
      )}

      <div className="pt-[10px] cursor-pointer ">
        <div
          className="hidden items-center justify-center border-[1px] border-secondary rounded-full p-1 "
          // onClick={() => setaddmetermodal(true)}
        >
          <div className="">
            <BsPlusCircle size={20} color={themeColor.secondary} style={{}} />
          </div>
          <div className="text-secondary pl-2">Add device</div>
        </div>
      </div>

      <div className="h-auto bg-white rounded-md shadow-md p-2">
        <div className="">
          <BackBtn text={"Go back"} />
        </div>
        <div className="flex items-center justify-between">
          <div className="px-3 py-2">
            <div className="font-semibold font-Kanit text-[17px]">
              All meters.
            </div>
            <div className="text-gray-700 text-start font-normal">
              List of all the meters you own and the ones shared with you.
            </div>
          </div>
          <div className="flex items-center ">
            <div className="flex overflow-hidden md:w-[200px] lg:w-[200px] items-center w-full border-[1px] rounded-md border-gray-700 h-[40px] w-100">
              <div className="ml-2 w-100">
                <input
                  type={"text"}
                  placeholder={"Search for device..."}
                  onChange={(e) => setinputfield(e.target.value)}
                  className="placeholder:text-sm block w-100 text-[13px] placeholder:font-normal outline-none border-[0px] "
                />
              </div>
            </div>
            {/* <div className="flex cursor-pointer ml-4 bg-gray-800 w-[45px] items-center border-[1px] rounded-md border-gray-700 h-[35px] justify-center">
              <BsMenuButtonWideFill size={22} color="white" />
            </div> */}
          </div>
        </div>

        <hr className="border-[0.5px] border-gray-200" />
        <div className="h-[30px] px-4 bg-gray-100 flex justify-end items-center">
          {data.filter((i) => i?.checked).length > 0 && (
            <>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setshowfreezemodal(true);
                }}
              >
                <div className=""></div>
                <div className="">Freeze meters</div>
              </div>
              <div className="mx-4">|</div>
            </>
          )}
          <Checkbox
            className=""
            onChange={() => {
              settoggleCheckbox(!toggleCheckbox);
            }}
          >
            {toggleCheckbox ? "Select meters" : "Deselect meters"}
          </Checkbox>
        </div>
        {loading ? (
          <div className=" p-2 grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 mt-4">
            {Array.from(Array(15)).map((_, i) => (
              <div className="h-[190px] drop-shadow-sm" key={i}>
                <Skimmer heigth={"100%"} />
              </div>
            ))}
          </div>
        ) : !data.length ||
          data.filter((i) =>
            i.alias.toLowerCase().includes(inputfield.toLowerCase())
          ).length == 0 ? (
          <div className="mt-[50px] flex flex-col justify-center items-center">
            <div className=""></div>
            <div className="text-center font-normal pb-10 text-gray-700">
              {inputfield
                ? `You haven't added any device called '${inputfield}'`
                : "You haven't added any device at the moment"}
            </div>
            <div
              className="w-[fit-content] mt-4 hidden items-center justify-center border-[1px] border-secondary rounded-full p-1"
              // onClick={() => setaddmetermodal(true)}
            >
              <div className="">
                <BsPlusCircle
                  size={20}
                  color={themeColor.secondary}
                  style={{}}
                />
              </div>
              <div className="text-secondary pl-2">Add meter</div>
            </div>
          </div>
        ) : (
          <div className="mt-4 p-3">
            <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 ">
              {data
                .filter((i) =>
                  i.alias.toLowerCase().includes(inputfield.toLowerCase())
                )
                .map((i, index) => (
                  <ChannelCard
                    key={index}
                    data={i}
                    toggleCheckbox={toggleCheckbox}
                    showrename={showrename}
                    setshowrename={setshowrename}
                    selectedchannel={selectedchannel}
                    setselectedchannel={setselectedchannel}
                    showgroup={showgroup}
                    handleAdd={handleAdd}
                    setshowgroup={setshowgroup}
                  />
                ))}
            </div>
            <PaginationButton
              total={channelcount}
              page={page}
              setpage={setpage}
              setoffset={setoffset}
              offset={offset}
              limit={limit}
              setlimit={setlimit}
              data={data}
              refresh={refresh}
              setrefresh={setrefresh}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AllChannels;
