import LimitImg from "../../../../assets/png/Screenshot 2023-03-18 at 14.38.46.png";

export const consumptionData = [
  {
    id: 1,
    title: "Daily energy limit",
    caption: "Set a daily energy limit device.",
    tag: "del",
    icon: <img src={LimitImg} className="" />,
  },
  {
    id: 2,
    title: "Cummulative energy limit",
    tag: "cel",
    caption: "Set a cummulative energy limit device.",
    icon: <img src={LimitImg} className="" />,
  },
];
