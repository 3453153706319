import { useEffect, useState } from "react";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import ProcessingCard from "../../../Loader/ProcessingCard";
import { Button } from "../../../Button";
import { Link, navigate, useNavigate } from "react-router-dom";
const PaymentPlanStatus = ({ open, setopen, data }) => {
  const [tabstate, settabstate] = useState(1);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const createLink = () => {
    navigate("/allpaymentplans?open=true");
  };
  console.log(data, "data");
  return loading ? (
    <ProcessingCard open={loading} />
  ) : (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[500px] lg:w-[500px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-center justify-between">
            <div className="font-Kanit font-semibold text-[17px]">
              Add payment plan to meter
            </div>
            <div className=" w-[fit-content] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <hr className="border-[0.5px] border-gray-400 w-full" />
          <input
            type={"text"}
            placeholder={"Search for plan..."}
            // onChange={(e) => setinputfield(e.target.value)}
            className="border-gray-600 h-[40px]  mt-4 w-full px-2 placeholder:text-sm block w-100 text-[13px] placeholder:font-normal rounded-sm border-[1px] "
          />
          <div className="w-[90%] mb-4 text-center mt-4 text-gray-700 font-normal">
            You haven't added any payment plan on this meter,
            <br /> click on the link below to create a new payment plan.
          </div>

          <Button text={"Create plan"} onClick={createLink} />
        </div>
      </div>
    </Backdrop>
  );
};

export default PaymentPlanStatus;
