import React, { useState } from "react";
import "./style.css";

const DaysSelector = ({ fd, setFd, height, border }) => {
  const handleSelectedDay = (i) => {
    let data = [...fd];
    if (data[i].value === 0) {
      data[i].value = 1;
      setFd((prev) => [...data]);
    } else {
      data[i].value = 0;
      setFd((prev) => [...data]);
    }
    // let mapArr = daysData.map((day) => day.value);
    // setFd((prev) => [...mapArr]);
    // fd == [...mapArr];
  };

  return fd.map((item, i) => (
    <div
      className={
        item.value === 1
          ? "px-3 py-2 mx-2 rounded-md mt-2 bg-secondary text-white hover:bg-secondary hover:border-secondary cursor-pointer transition-all ease-in "
          : "px-3 py-2 mx-2 rounded-md border-[1px] border-gray-400 mt-2 bg-white cursor-pointer "
      }
      style={{
        height: height || "40px",
        width: "fit-content",
        fontSize: 15,
      }}
      key={i}
      onClick={() => handleSelectedDay(i)}
    >
      {item.title}
    </div>
  ));
};

export default DaysSelector;
