import {
  RiDashboardFill,
  RiWallet3Fill,
  RiSettings5Fill,
} from "react-icons/ri";
import { HiDeviceMobile } from "react-icons/hi";
import { GiChemicalTank, GiWallet } from "react-icons/gi";
import { IoLogOut } from "react-icons/io5";
import { ImUser } from "react-icons/im";
import {
  FaBuilding,
  FaUsers,
  FaUserFriends,
  FaMoneyCheck,
} from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import { MdMoreTime } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { IoHardwareChip } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { AiOutlineFieldTime } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { themeColor } from "../../../constant/color";
import { FaUsersCog } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
export const NavIcon = ({ title, link }) => {
  const location = useLocation();
  switch (title) {
    case "Dashboard":
      return (
        <RiDashboardFill
          size={21}
          color={location.pathname == "/dashboard" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Devices":
      return (
        <IoHardwareChip
          size={21}
          // color={"white"}
          color={location.pathname == "/allchannels" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Cusomter Profile":
      return (
        <RiDashboardFill
          size={21}
          color={
            location.pathname.includes == "/alldevices" ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Billing":
      return (
        <RiDashboardFill
          size={21}
          color={
            location.pathname.includes == "/dashboard/systemadmin"
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "User Management":
      return (
        <HiUsers
          size={21}
          color={
            location.pathname == "/channel/usermanagement" ? "#fff" : "#6c6c6c"
          }
        />
      );
    // case "Payment plans":
    //   return (
    //     <RiDashboardFill
    //       size={21}
    //       color={location.pathname == "/allpaymentplans" ? "#fff" : "#6c6c6c"}
    //     />
    //   );
    case "Customer Profile":
      return (
        <FaUsers
          size={21}
          color={location.pathname.includes("/users") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Customer profile":
      return (
        <FaUsers
          size={21}
          color={location.pathname.includes("/users") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Customers Meters":
      return (
        <FaUsersCog
          size={21}
          color={location.pathname == "manage-meters" ? "#fff" : "#6c6c6c"}
        />
      );
    // // case "Customer Meters":
    // //   return (
    // //     <FaUsersCog
    // //       size={21}
    // //       color={
    // //         location.pathname == "/salesagent/customer"
    // //           ? "#fff"
    // //           : "#6c6c6c"
    // //       }
    // //     />
    //   );
    case "Wallet":
      return (
        <GiWallet
          size={21}
          color={location.pathname == "/wallet" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Profile":
      return (
        <FaUserTie
          size={19}
          color={location.pathname == "/settings" ? "#fff" : "#6c6c6c"}
        />
      );

    case "Roles":
      return (
        <FaUsers
          size={21}
          color={location.pathname.includes("/role") ? "#fff" : "#6c6c6c"}
        />
      );

    case "Meters":
      return (
        <IoHardwareChip
          size={21}
          color={
            location.pathname.includes("/allmeters") ||
            location.pathname.includes("/usermeters")
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Project sites":
      return (
        <FaBuilding
          size={21}
          color={
            location.pathname == "/dashboard/systemadmin/projectsite"
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    // case "Meter class":
    //   return (
    //     <MdMoreTime
    //       size={21}
    //       color={location.pathname == "/meterclass" ? "#fff" : "#6c6c6c"}
    //     />
    //   );
    case "Revenue":
      return <IoWallet size={21} color={"#6c6c6c"} />;
    case "Meter Settings":
      return (
        <RiSettings5Fill
          size={21}
          color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Settings":
      return (
        <RiSettings5Fill
          size={21}
          color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Sales Analytics":
      return (
        <RiWallet3Fill
          size={21}
          color={
            location.pathname.includes("/salesanalytics/") ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Recharge History":
      return (
        <RiWallet3Fill
          size={21}
          color={
            location.pathname.includes("/recharge-history") ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Meter Activities":
      return (
        <RiWallet3Fill
          size={21}
          color={
            location.pathname.includes("/customer-meter/meteractivities")
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Transactions":
      return (
        <GiWallet
          size={21}
          color={
            location.pathname.includes("/allcustomer-transactions")
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Log out":
      return <IoLogOut size={21} color={"#6c6c6c"} />;
    default:
      return null;
  }
};

export default NavIcon;
