import axios from "../../../../../../utils/axios";
import { toast } from "react-hot-toast";
import { BsSoundwave } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useMQTT } from "../../../../../../context/MqttContext";
import { getToken } from "../../../../../../utils/token";
import { hardwareData, measurementCommands } from "../../data";
import { commandController } from "../../../../../../controllers/CommandController";
const HardwareTab = ({ loading, setloading, closeBtn }) => {
  const { id } = useParams();
  const { mqttEvents, clients } = useMQTT();
  const handleCommand = (cmd) => {
    switch (cmd) {
      case "ctp":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/clearhardwaretamper", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "CTP" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      case "rst04":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/resetsaifi", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "RST04" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      case "rst03":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/resetsaidi", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "RST03" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;

      case "rst09":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/restartfirmware", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "RST09" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      case "rst10":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/erasecontrolcommands", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "RST09" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;

      case "rst11":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/eraseallcommands", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "RST11" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      default:
        break;
    }
  };
  return (
    <div>
      <div className="mt-4">
        <div className="font-Kanit text-center text-secondary font-semibold text-[17px]">
          Hardware(device) Commands.
        </div>
        <div className=" text-center text-gray-700 font-light w-[70%] mx-auto ">
          Use the settings below to determine how you want to measure energy
          consumed on your device.
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-6 place-content-center">
        {hardwareData.map(({ title, id, desc, icon, cmdType }) => (
          <div
            onClick={() => handleCommand(cmdType)}
            key={id}
            className="bg-white rounded-md drop-shadow-xl p-2 border-b-[2px] transition-all ease-out border-gray-600 hover:border-secondary cursor-pointer relative"
          >
            <div className=""> {icon}</div>
            <div className="font-semibold pt-1">{title}</div>
            <div className="font-light">{desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HardwareTab;
