import moment from "moment";
import { NotificationFormatter } from "../../../../helperFuncs";
import { FcCalendar } from "react-icons/fc";

const RequestCard = ({ data, setuserdetails, open, setopen }) => {
  return (
    <div
      className="my-2 border-b-[1px] py-2 cursor-pointer"
      onClick={() => {
        setuserdetails(data);
        setopen(true);
      }}
    >
      <div className="flex items-center justify-between ">
        <div className="">{data?.recipient}</div>
        <div className="flex text-[13px] text-right font-light">
          <div className="flex pt-1 pr-2 items-start justify-center">
            <FcCalendar className="mt-[2px]" />
            {moment(data?.datesent).format("lll")}
          </div>
        </div>
      </div>
      <div className="flex items-end mt-2 justify-between w-full">
        <div className="">
          <div className="text-[13px] font-light"></div>
          <div className="font-semibold">{data?.note}</div>
        </div>
        <div className="text-green-600 text-[14px] font-light">
          <span className="underline text-[12px">{data?.accesscontrol}</span>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
