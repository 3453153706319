import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";

import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import EmptyState from "../../EmptyState";
import ViewPaymentPlan from "../Modals/ViewPaymentPlan";

const PaymentPlanTableMain = ({ data = [], setdetails, setmodal }) => {
  return (
    <div>
      {!data.length ? (
        <EmptyState>
          <div className="h-[70vh] w-full">
            <div className="flex items-center justify-center mt-[200px]">
              <HiUserGroup size={30} />
            </div>
            <div className="font-normal text-[15px] text-center">
              You don't have any request
            </div>
          </div>
        </EmptyState>
      ) : (
        <table className="w-full border-collapse">
          <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
            <th className="font-semibold text-gray-800">S/N</th>
            <th className="font-semibold text-gray-800">Plan Name</th>
            <th className="font-semibold text-gray-800">Amount</th>
            <th className="font-semibold text-gray-800">Description</th>
            <th className="font-semibold text-gray-800">Active period</th>
            <th className="font-semibold text-gray-800">Date created</th>
            {/* <th className="font-semibold text-gray-800">Time range</th> */}
            <th className="font-semibold text-gray-800">Action</th>
          </thead>
          <tbody>
            {data.map((i, index) => (
              <tr
                className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
                key={i?.roleid}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{i?.planalias}</td>
                <td className="text-center text-secondary">
                  ₦{i?.amount.toLocaleString()}
                </td>
                <td className="rounded-lg text-center">{i?.description}</td>
                <td className="rounded-lg text-center">
                  {i?.duration} {i?.durationlength}
                  {+i?.duration > 1 ? "s" : ""}
                </td>
                <td className="text-center">
                  <div className="flex items-center justify-center">
                    <div className="pl-2">
                      {moment(i?.datecreated).format("lll")}
                    </div>
                  </div>{" "}
                </td>

                <td className="text-center flex justify-between">
                  <div className="border-[1px] hidden rounded border-orange-600 text-[12px] text-orange-600 font-light px-4 py-2">
                    Edit
                  </div>
                  <div
                    onClick={() => {
                      setdetails(i);
                      setmodal(true);
                    }}
                    className="border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2"
                  >
                    View
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PaymentPlanTableMain;
