import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ChannelContext } from "../../../../context/channelContext";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { IoFlash } from "react-icons/io5";
import { Button } from "../../../Button";
import { useSafeDispatch } from "../../../../utils/Hooks/useSafeDispatch";
import Skimmer from "../../../Loader/Skimmer";
import BillImg from "../../../../assets/svg/7952173_receipt_paper_transation_check_sale_icon.svg";
import { getToken } from "../../../../utils/token";
import { commandController } from "../../../../controllers/CommandController";
import axios from "../../../..//utils/axios";
import { cardController } from "../../../../controllers/CardController";
import { useMQTT } from "../../../../context/MqttContext";
import moment from "moment";

const BuyPlan = ({ open, setopen, plan }) => {
  const { id } = useParams();
  const { mqttEvents } = useMQTT();
  const [loading, setloading] = useState(false);
  const handleSubmit = async () => {
    try {
      setloading(true);
      let res = await axios.post("/submeter/cardpayment/acquiretransactionid", {
        creator: plan?.creator,
        planalias: plan?.planalias,
        channelid: id,
        paymentgateway: "demo",
        amount: parseInt(plan?.amount),
      });
      let result = cardController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading((prev) => false);
        return;
      }

      if (result.message.transactionid == "failed") {
        toast.error("Operation failed. Please try again.");
        setloading((prev) => false);
        return;
      }

      let commit = await axios.post("/submeter/cardpayment/committransaction", {
        transactionid: result.message.transactionid,
      });
      let resCommit = cardController(commit);
      if (resCommit.type !== "success") {
        toast[resCommit.type](resCommit.message);
        setloading((prev) => false);
        return;
      }

      if (resCommit.message.status == "Failed") {
        toast.error(resCommit.message.status);
        setloading((prev) => false);
        setopen(false);
        return;
      }
      setloading((prev) => false);
      toast.success(resCommit.message.status);
      setopen(false);
      // console.log(resCommit, "///result");

      // var time = setTimeout(() => {
      //   toast.success("Your request is being processed", {
      //     toastId: "vw389",
      //   });
      //   setopen(false);
      // }, 5000);
      // mqttEvents.on("message", (message) => {
      //   if (message.topic === `mqtt_${creator}`) {
      //     if (!message.payloadString) return null;
      //     if (JSON.parse(message.payloadString).type == "END") {
      //       setopen(false);
      //       return clearTimeout(time);
      //     }
      //   }
      // });
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[400px] bg-white rounded-md p-3">
          <div className="flex items-start justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Plan details
              </div>
              <div className="text-gray-700 hidden font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className="w-[200px] flex items-center justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div className="">
            <div className="my-2 py-1">
              Plan Name: <span>{plan?.planalias}</span>
            </div>
            <div className="my-2 py-1">Description: {plan?.description}</div>
            <div className="my-2 py-1">
              Amount: ₦{plan?.amount.toLocaleString()}
            </div>
            <div className="my-2 py-1">Created by : {plan?.creator}</div>
            <div className="my-2 py-1">
              Date created : {moment(plan?.dateassigned).format("lll")}
            </div>
          </div>
          <div className="w-full mt-3">
            <Button
              onClick={handleSubmit}
              text={"Buy plan"}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default BuyPlan;
