import { useState } from "react";
import CloseButton from "../../../CloseButton";
import ErrorText from "../../../Errors/ErrorText";
import Rule from "../../../Rule/Index";
import { AiOutlineUser } from "react-icons/ai";
import Backdrop from "../../../Backdrop";
import { getToken } from "../../../../utils/token";
import { useParams } from "react-router-dom";
import { channelController } from "../../../../controllers/channelController";
import axios from "../../../../utils/axios";
import { errorBlock } from "../../../../controllers/errorBlock";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Button } from "../../../Button";

const RenameModal = ({
  open,
  setopen,
  refreshBtn,
  selectedchannel,
  setselectedchannel,
}) => {
  const [name, setname] = useState("");
  const [loading, setloading] = useState(false);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setloading(true);
      let username = await getToken("spiral_username");
      console.log(selectedchannel);

      if (selectedchannel?.type == "single") {
        let res = await axios.post("/submeter/editchannelalias", {
          username,
          newalias: data.name,
          channelid: selectedchannel?.channelid,
        });

        let result = channelController(res);
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloading(false);
          return;
        }
        refreshBtn((prev) => !prev);
        toast.success("Meter name updated successfully");
        setloading(false);
        setopen(false);
        return;
      } else {
        let res = await axios.post("/submeter/editchannelgroup", {
          username,
          newgroup: data.name,
          channelid: selectedchannel?.channelid,
        });

        let result = channelController(res);
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloading(false);
          return;
        }
        refreshBtn((prev) => !prev);
        toast.success("Meter name updated successfully");
        setloading(false);
        setopen(false);
        return;
      }
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="w-[400px] bg-white rounded-md p-3 animate__animated animate__fadeInUp">
          <div className="flex items-start justify-content-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Update Meter name
              </div>
              <div className=" text-gray-700 font-light">
                Please fill in the fields to update your Meter name.
                <br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* firstname */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">
                Meter name
              </label>
              <div className="flex bg-white items-center w-full border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                <div className="d-inline flex items-center justify-center px-2 ">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    type={"name"}
                    {...register("name", {
                      required: true,
                    })}
                    placeholder={"Enter your a new name for your device."}
                    className="w-full placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
              {errors.name && <ErrorText text={"Please enter a name"} />}
            </div>

            <div className="mt-6 mb-3">
              <Button text={"Submit"} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </Backdrop>
  );
};

export default RenameModal;
