import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { themeColor as color } from "../../../constant/color";
import { Button } from "../../Button";
import CardTitle from "../../CardTitle";
import "./styles.css";

const TOUModal = ({ closeModal, stf, itou }) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [billPercentage, setBillPercentage] = useState("");
  const handleAdd = () => {
    let num = 0;
    if (!startTime || !endTime || !billPercentage) {
      toast.error("All values are required");
      return;
    }
    const formatedBillPerentage =
      billPercentage < 10
        ? `00${billPercentage}`
        : billPercentage > 10 && billPercentage < 99
        ? `0${billPercentage}`
        : billPercentage;

    if (!itou.length) {
      stf((prev) => [
        ...prev,
        {
          st: moment(startTime._d).format("HH:mm:ss"),
          et: moment(endTime._d).format("HH:mm:ss"),
          bp: formatedBillPerentage,
        },
      ]);
      closeModal((prev) => !prev);
      return;
    } else {
      stf((prev) => [
        ...prev,
        {
          st: moment(startTime._d).format("HH:mm:ss"),
          et: moment(endTime._d).format("HH:mm:ss"),
          bp: formatedBillPerentage,
        },
      ]);
      closeModal((prev) => !prev);
      return;
    }
  };
  return (
    <div className="backdrop">
      <div className="tou-modal animate__animated animate__fadeInUp bg-white rounded-1 shadow-sm p-3">
        <CardTitle title={"Select time of use"} />
        <div className="tou-modal__entry p-2 my-3 row d-flex align-items-center justify-content-between w-100 ">
          <div className="tou-modal__title w-50">Start date:</div>
          <div className="tou-modal__select rounded-1 w-50">
            <TimePicker
              defaultValue={moment("00:00:00", "HH:mm:ss")}
              format={"HH:mm:ss"}
              style={{
                width: "100%",
                color: color.baseColor,
              }}
              value={startTime}
              onChange={(e) => setStartTime(e)}
            />
          </div>
        </div>
        <div className="tou-modal__entry p-2 my-3 row d-flex align-items-center justify-content-between w-100 ">
          <div className="tou-modal__title w-50">End date:</div>
          <div className="tou-modal__select rounded-1 w-50">
            <TimePicker
              defaultValue={moment("00:00:00", "HH:mm:ss")}
              format={"HH:mm:ss"}
              style={{
                width: "100%",
                color: color.baseColor,
              }}
              value={endTime}
              onChange={(e) => setEndTime(e)}
            />
          </div>
        </div>
        <div className="tou-modal__entry p-2 my-3 row d-flex align-items-center justify-content-between w-100 ">
          <div className="tou-modal__title w-50">Billing %:</div>
          <div className="tou-modal__title w-50">
            <input
              type={"number"}
              className="w-100 px-2 py-1 rounded-1 tou-input"
              placeholder="Enter number"
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength) {
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                }
              }}
              maxLength={3}
              value={billPercentage}
              onChange={(e) => {
                setBillPercentage(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="tou-modal__btn row d-flex align-items-center justify-content-between">
          <div className="col-5" onClick={() => closeModal(false)}>
            <Button
              text={"Cancel"}
              bg={color.btnFadeColor}
              color={color.baseColor}
              height={"35px"}
            />
          </div>
          <div className="col-5" onClick={handleAdd}>
            <Button
              text={"Save"}
              bg={color.baseColor}
              color={color.whiteColor}
              height={"35px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TOUModal;
