import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import EmptyState from "../../../../EmptyState";
import { HiUserGroup } from "react-icons/hi";
import RequestDetails from "../../Modals/RequestDetails";
import { useState } from "react";
const PendingUserTable = ({ data = [], setuserdetails, open, setopen }) => {
  const [modal, setmodal] = useState(false);

  return !data.length ? (
    <EmptyState>
      <div className="flex flex-col item-center justify-center h-[400px]">
        <div className="flex item-center justify-center">
          <HiUserGroup size={40} />
        </div>
        <div className="font-semibold">You don't have any pending request</div>
      </div>
    </EmptyState>
  ) : (
    <>
      <table className="w-full border-collapse">
        <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
          <th className="font-semibold text-gray-800">S/N</th>
          <th className="font-semibold text-gray-800">Recipient</th>
          <th className="font-semibold text-gray-800">Access granted</th>
          <th className="font-semibold text-gray-800">Status</th>
          <th className="font-semibold text-gray-800">Date sent</th>
          <th className="font-semibold text-gray-800">Date accepted</th>
          <th className="font-semibold text-gray-800">Action</th>
        </thead>
        <tbody>
          {data.map((i, index) => (
            <tr
              className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
              key={i?.roleid}
            >
              <td className="text-center">{index + 1}</td>
              <td className="text-center">{i?.recipient}</td>
              <td className="text-center">{i?.accesscontrol}</td>
              <td className="text-orange-500 rounded-lg text-center">
                {i?.status}
              </td>
              <td className="text-center">
                <div className="flex items-center justify-center">
                  <div className="flex items-center justify-center pt-[2px]">
                    <FcCalendar size={21} />
                  </div>
                  <div className="pl-2">
                    {moment(i?.datesent).format("lll")}
                  </div>
                </div>{" "}
              </td>
              <td className="text-center">
                <div className="flex items-center justify-center">
                  <div className="flex items-center justify-center pt-[2px]">
                    <FcCalendar size={21} />
                  </div>
                  <div className="pl-2">
                    {!i?.dateactedupon
                      ? "not accepted"
                      : moment(i?.dateactedupon).format("lll")}
                  </div>
                </div>{" "}
              </td>
              <td className="text-center">
                <div
                  onClick={() => {
                    setuserdetails(i);
                    setopen(true);
                  }}
                  className="border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2"
                >
                  View details
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PendingUserTable;
