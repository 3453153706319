import { Link, useParams } from "react-router-dom";
// import { toast } from "react-hot-toast";
import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { React, useRef, useEffect, useState } from "react";
import { useMQTT } from "../../../../context/MqttContext";
import Layout from "../../../../components/Layout";
import PageTitle from "../../../../components/PageTitle";
import BackBtn from "../../../../components/BackBtn";
import Rule from "../../../../components/Rule/Index";
// import ChartStreaming from "chartjs-plugin-streaming";
import { StreamingPlugin, RealTimeScale } from "chartjs-plugin-streaming";
// import * as mqtt from "react-paho-mqtt";
// import moment from "moment";
// import "chartjs-adapter-moment";
import "chartjs-adapter-luxon";
import { Button } from "../../../../components/Button";
// Chart.register(ChartStreaming);
Chart.register(StreamingPlugin, RealTimeScale);

const RUP = () => {
  const [gradientValue, setGradientValue] = useState("");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [animation, setAnimation] = useState({});
  const [message, setMessage] = useState("");
  const { mqttEvents } = useMQTT();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [vol, setVol] = useState([]);
  const [date, setDate] = useState("");
  const [freq, setFreq] = useState([]);
  const [cur, setCur] = useState([]);
  const [pf, setPf] = useState([]);
  const [q1, setq1] = useState([]);
  const [q2, setq2] = useState([]);
  const [q3, setq3] = useState([]);
  const [q4, setq4] = useState([]);
  const [acp, setacp] = useState([]);
  const [app, setapp] = useState([]);
  const [ape, setape] = useState([]);
  const [aee, setaee] = useState([]);
  const [aie, setaie] = useState([]);
  const [ace, setace] = useState([]);
  const [rep, setrep] = useState([]);
  const [ree, setree] = useState([]);
  const [are, setare] = useState([]);
  const [pie, setpie] = useState([]);
  const [pee, setpee] = useState([]);
  const myChartRef = useRef();
  // console.log(  "client");

  useEffect(() => {
    // getData();

    mqttEvents.on("message", (message) => {
      // if (!message.payloadString) return null;
      console.log(message.topic, "Topic");
      if (message.topic == `r_${id}`) {
        console.log(message.payloadString, "/rup values");
        // voltage
        let arr = [];
        arr.push({
          x: message.payloadString.split("##")[
            message.payloadString.split("##").length - 1
          ],
          y: message.payloadString
            .split("##")
            .filter((item) => item.includes("vol"))
            .map((item) => item.split(":")[1])
            .join(""),
        });
        setVol((prev) => [...prev, ...arr]);

        // power | voltage | frequency | energy

        let freqV = [];
        freqV.push({
          x: message.payloadString.split("##")[
            message.payloadString.split("##").length - 1
          ],
          y: message.payloadString
            .split("##")
            .filter((item) => item.includes("fre"))
            .map((item) => item.split(":")[1])
            .join(""),
        });
        setFreq((prev) => [...prev, ...freqV]);

        let cuArr = [];
        cuArr.push({
          x: message.payloadString.split("##")[
            message.payloadString.split("##").length - 1
          ],
          y: message.payloadString
            .split("##")
            .filter((item) => item.includes("cur"))
            .map((item) => item.split(":")[1])
            .join(""),
        });
        setCur((prev) => [...prev, ...cuArr]);
        // vol:240.84##cur:10.00##fre:51.14##pf:0.75##acp:2408.40##app:3211.20
        // #rep:0.00##ace:0.001##ape:0.001##ree:0.000##aie:0.000000##aee:-0.000000##
        // q1:0.000018##q2:0.000008##q3:0.000000##q4:0.000000##pie:0.000018##pee:0.000008
        // ##2022-06-02 18:49:28
        // let pfArr = [];
        // pfArr.push({
        //   x: message.payloadString.split("##")[
        //     message.payloadString.split("##").length - 1
        //   ],
        //   y: message.payloadString
        //     .split("##")
        //     .filter((item) => item.includes("pf"))
        //     .map((item) => item.split(":")[1])
        //     .join(""),
        // });
        // setCur((prev) => [...prev, ...pfArr]);
        // let acpArr = [];
        // acpArr.push({
        //   x: message.payloadString.split("##")[
        //     message.payloadString.split("##").length - 1
        //   ],
        //   y: message.payloadString
        //     .split("##")
        //     .filter((item) => item.includes("acp"))
        //     .map((item) => item.split(":")[1])
        //     .join(""),
        // });
        // setacp((prev) => [...prev, ...acpArr]);

        // let aceArr = [];
        // aceArr.push({
        //   x: message.payloadString.split("##")[
        //     message.payloadString.split("##").length - 1
        //   ],
        //   y: message.payloadString
        //     .split("##")
        //     .filter((item) => item.includes("ace"))
        //     .map((item) => item.split(":")[1])
        //     .join(""),
        // });
        // setace((prev) => [...prev, ...aceArr]);

        // let aeeArr = [];
        // aeeArr.push({
        //   x: message.payloadString.split("##")[
        //     message.payloadString.split("##").length - 1
        //   ],
        //   y: message.payloadString
        //     .split("##")
        //     .filter((item) => item.includes("aee"))
        //     .map((item) => item.split(":")[1])
        //     .join(""),
        // });
        // setaee((prev) => [...prev, ...aeeArr]);

        // let aieArr = [];
        // aieArr.push({
        //   x: message.payloadString.split("##")[
        //     message.payloadString.split("##").length - 1
        //   ],
        //   y: message.payloadString
        //     .split("##")
        //     .filter((item) => item.includes("aie"))
        //     .map((item) => item.split(":")[1])
        //     .join(""),
        // });
        // setaie((prev) => [...prev, ...aieArr]);
        // console.log(myChartRef.current, "/ref");
        // myChartRef.current?.update("quiet");
      }
    });
    return () => {
      // ADD command to stop RUP from coming
      // client.unsubscribe("r_" + id);
      // mqttEvents.removeListener("message");
    };
  }, []);

  // console.log(myChartRef.current, "/ref");

  const datasets = [
    // { name: "vol", color: "#eabd3b" },
    // { name: "cu", color: "#de542c" },
    // { name: "fre", color: "#1de4bd" },
    // { name: "pf", color: "#ea7639" },
    { name: "acp", color: "#1ac9e6" },
    { name: "app", color: "#af4bce" },
    { name: "frequency", color: "#142459" },
    { name: "rep", color: "#820401" },
    { name: "ace", color: "#e7e34e" },
    { name: "ape", color: "#e7e24e" },
    { name: "ree", color: "#e9834e" },
    { name: "aie", color: "#e7e34e" },
    { name: "aee", color: "#eea34e" },
    { name: "q1", color: "#e7e34e" },
    { name: "q2", color: "#e1e34e" },
    { name: "q3", color: "#e7e34e" },
    { name: "q4", color: "#eca34e" },
    { name: "pie", color: "#b2e34e" },
    { name: "pee", color: "#e7024e" },
  ];

  return (
    <Layout>
      <div className="mt-6 mb-[100px]">
        <BackBtn text={"Go back to energy consumption page"} />
        <div className="bg-white drop-shadow-md p-3 rounded-md h-auto  ">
          <div className="flex align-items justify-between">
            <PageTitle
              title={"Real consumption"}
              caption={"Analyze real consumption on your channel."}
            />
            <Button
              color={"white"}
              text={"Result"}
              onClick={() => console.log(vol, "///values")}
            />
          </div>
          <Rule />
          <div className="h-[90vh]">
            <Line
              ref={myChartRef}
              data={{
                datasets: [
                  {
                    type: "line",
                    label: "Voltage",
                    fill: false,
                    backgroundColor: "dodgerblue",
                    data: vol,
                    borderColor: "dodgerblue",
                    radius: 1,
                    borderWidth: 1,
                    pointBorderWidth: 1,
                    tension: 0.4,
                  },
                  {
                    type: "line",
                    label: "Frequency",
                    fill: false,
                    backgroundColor: "orange",
                    data: freq,
                    borderColor: "orange",
                    radius: 1,
                    borderWidth: 1,
                    pointBorderWidth: 1,
                    tension: 0.4,
                  },
                  {
                    type: "line",
                    label: "Frequency",
                    fill: false,
                    backgroundColor: "tomato",
                    data: cur,
                    borderColor: "tomato",
                    radius: 1,
                    borderWidth: 1,
                    pointBorderWidth: 1,
                    tension: 0.4,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                animation: {},
                plugins: {
                  legend: {
                    position: "bottom",
                    display: true,
                  },
                  title: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    type: "realtime",
                    realtime: {
                      delay: 4000,
                      duration: 160000,
                      frameRate: 90,
                      refresh: 1000,
                    },
                    grid: {
                      display: false,
                    },
                    time: {
                      parser: "YYYY-MM-DD HH:mm:ss", // Adjust the format according to your timestamp format
                      tooltipFormat: "YYYY-MM-DD HH:mm:ss", // Adjust the tooltip format if needed
                      unit: "second",
                      // stepSize: 1,
                      displayFormats: {
                        second: "HH:mm:ss",
                        minute: "HH:mm",
                        hour: "HH:mm",
                        day: "MMM DD",
                        month: "MMM YYYY",
                        year: "YYYY",
                      },
                      timezone: "Africa/Lagos", // Specify the desired timezone
                    },
                  },
                  y: {
                    grid: {
                      display: true,
                      borderDash: [10],
                      borderDashOffset: 20,
                      borderWidth: 1,
                      color: "#eee",
                      z: 4,
                    },
                    title: {
                      display: false,
                      text: "people seated",
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="pb-[100px]"></div>
      </div>
    </Layout>
  );
};

export default RUP;

// <Line
//               ref={myChartRef}
//               data={{
//                 datasets: [
//                   {
//                     type: "line",
//                     label: "Volt",
//                     fill: false,
//                     backgroundColor: "green",
//                     data: vol,
//                     borderColor: "green",
//                     radius: 1,
//                     borderWidth: 1,
//                     pointBorderWidth: 1,
//                     tension: 0.4,
//                   },
//                 ],
//               }}
//               options={{
//                 maintainAspectRatio: false,
//                 responsive: true,
//                 animation: {},
//                 plugins: {
//                   legend: {
//                     position: "bottom",
//                     display: false,
//                   },
//                   title: {
//                     display: false,
//                   },
//                 },
//                 scales: {
//                   y: {
//                     grid: {
//                       display: true,
//                       borderDash: [10],
//                       borderDashOffset: 20,
//                       borderWidth: 1,
//                       color: "#eee",
//                       z: 4,
//                     },
//                     title: {
//                       display: false,
//                       text: "people seated",
//                     },
//                   },
//                 },
//               }}
//             />
