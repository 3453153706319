import { Button } from "../../../Button";
import Drawer from "../../../Drawer";
import { useForm } from "react-hook-form";
import { AiOutlineUser } from "react-icons/ai";
import { useState, useEffect } from "react";
import { DatePicker, TimePicker, Select } from "antd";
import moment from "moment";
import { IoFlash } from "react-icons/io5";
import { toast } from "react-hot-toast";
import { billingController } from "../../../../controllers/BillingController";
import ErrorText from "../../../Errors/ErrorText";
import axios from "../../../../utils/axios";
import { commandController } from "../../../../controllers/CommandController";
import { AiOutlineDelete } from "react-icons/ai";
import { useMQTT } from "../../../../context/MqttContext";
import { getToken } from "../../../../utils/token";

const AddMeterClass = ({ refreshBtn, setopen, open, loading, setLoading }) => {
  const { Option } = Select;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [dcu, setdcu] = useState([]);
  const [dcuid, setdcuid] = useState("");
  const [daysCount, setDaysCount] = useState("");
  const [desc, setdesc] = useState("");
  const [step, setstep] = useState(1);
  const [name, setname] = useState("");
  const [tou, setTOU] = useState([]);
  const handleDelete = (n) => {
    const filteredTou = tou.filter((item, index) => index != n);
    setTOU((prev) => filteredTou);
  };
  const { mqttEvents } = useMQTT();
  const format = "HH:mm:ss";
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [billPercentage, setBillPercentage] = useState("");
  const [result, setResult] = useState([]);
  const handleAdd = () => {
    let num = 0;
    if (!startTime || !endTime || !billPercentage) {
      toast.warn("All values are required");
      return;
    }
    const formatedBillPerentage =
      billPercentage < 10
        ? `00${billPercentage}`
        : billPercentage == 10
        ? `0${billPercentage}`
        : billPercentage > 10 && billPercentage < 99
        ? `0${billPercentage}`
        : billPercentage == 99
        ? `0${billPercentage}`
        : billPercentage;

    if (!tou.length) {
      setTOU((prev) => [
        ...prev,
        {
          st: moment(startTime._d).format("HH:mm:ss"),
          et: moment(endTime._d).format("HH:mm:ss"),
          bp: formatedBillPerentage,
        },
      ]);
    } else {
      setTOU((prev) => [
        ...prev,
        {
          st: moment(startTime._d).format("HH:mm:ss"),
          et: moment(endTime._d).format("HH:mm:ss"),
          bp: formatedBillPerentage,
        },
      ]);
    }

    setStartTime("");
    setEndTime("");
    setBillPercentage("");
  };
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/submeter/getalluserdevices", {});
        let result = commandController(res);
        if (result.type !== "success") {
          toast[result.type](`${result.message}`);
          return;
        }
        setdcu((prev) => [...res.data.body]);
        return;
      } catch (err) {
        toast.error(err.message);
        return;
      }
    })();
  }, []);

  const submitMeterClass = async (data) => {
    const result = tou.map((item) => {
      return (
        "#" +
        item.st.replaceAll(":", "") +
        item.et.replaceAll(":", "") +
        item.bp
      );
    });

    try {
      setLoading(true);
      let user = await getToken("spiral_username");
      let res = await axios.post("/commands/createmeterclass", {
        dcuid: dcuid,
        meterclass: name,
        description: desc,
        transactiontopic: `mqtt_${user}`,
        tou: result.join(""),
      });
      let response = commandController(res);
      if (response.type !== "success") {
        toast[response.type](response.message);
        setLoading(false);
        return;
      }

      var time = setTimeout(() => {
        setLoading((prev) => false);
        toast.success("Your command is being processed");
        refreshBtn((prev) => !prev);
        setopen(false);
      }, process.env.REACT_APP_TIME);

      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${user}`) {
          if (!message.payloadString) return null;
          if (JSON.parse(message.payloadString).type == "CMC") {
            setLoading((prev) => false);
            setopen(false);
            return clearTimeout(time);
          }
        }
      });
      return;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setLoading(false);
      }
      toast.error(err.message);
      setLoading(false);
    }
  };
  return (
    <Drawer
      open={open}
      setopen={setopen}
      title="Add meter class"
      caption="Fill the fields below to create a meterclass"
    >
      <div className="px-3 mt-4 relative border-[1px] border-gray-300 rounded-md h-[85vh]">
        {step == 1 && (
          <div className="pt-3">
            <label className="text-primary font-semibold text-sm">
              Start by selecting a submeters
            </label>
            <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px]">
              <Select
                style={{
                  width: "100%",
                  borderRadius: "3px",
                }}
                allowClear
                showArrow
                showSearch
                value={dcuid}
                onChange={(e) => setdcuid(e)}
                bordered={false}
                placeholder={"Select a command type"}
              >
                {dcu.map((item, i) => (
                  <Option
                    key={i}
                    value={item.deviceid}
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {item.devicealias}
                  </Option>
                ))}
              </Select>
            </div>
            {errors.name && <ErrorText text={"Select a submeter "} />}
            <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
          </div>
        )}
        {step == 2 && (
          <div className="pt-3">
            <label className="text-primary font-semibold text-sm">
              Meter class name
            </label>
            <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px]">
              <div className="d-inline flex items-center justify-center px-2">
                <IoFlash
                  size={20}
                  color="text-primary"
                  className="text-primary"
                />
              </div>
              <div className="ml-2">
                <input
                  type={"text"}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  maxLength="3"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder={"what should be the name of your meter "}
                  className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                />
              </div>
            </div>
            {errors.name && (
              <ErrorText text={"what should be the name of your meter"} />
            )}
            <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
          </div>
        )}

        {step == 3 && (
          <div className="mt-4">
            <label className="text-primary font-semibold text-sm">
              Meter class description
            </label>
            <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[auto] p-2">
              <textarea
                value={desc}
                onChange={(e) => setdesc(e.target.value)}
                placeholder={"Enter a description"}
                className=" placeholder:text-sm h-[100px] resize-none placeholder:font-thin w-full outline-none border-[0px]"
              />
            </div>

            <div classsName="italic pt-1 text-gray-600 text-sm font-weight-light"></div>
          </div>
        )}
        {step == 4 && (
          <div className="pt-3">
            <label className="text-primary font-semibold text-sm">
              Create time of use
            </label>
            <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px] overflow-hidden">
              <div className="border-r-[1px] border-r-gray-700">
                <input
                  value={billPercentage}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  maxLength={3}
                  onChange={(e) => {
                    setBillPercentage(e.target.value);
                  }}
                  placeholder="billing %"
                  className="p-1 w-[100px] placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                />
              </div>
              <div className="">
                <TimePicker.RangePicker
                  bordered={false}
                  value={[startTime, endTime]}
                  onChange={(e) => {
                    setStartTime(e[0]);
                    setEndTime(e[1]);
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <Button text={"Add time of use"} onClick={handleAdd} />
            </div>
            {!tou.length ? (
              <div className="bg-gray-100 my-3 border-dashed border-[1px] rounded-md h-[100px] border-gray-600 flex items-center justify-center">
                <div className="">No time of use added yet</div>
              </div>
            ) : (
              tou.map(({ st, et, bp }, index) => (
                <div
                  className="flex even:bg-gray-100 p-2 my-2 h-[auto] items-start overflow-y-scroll"
                  key={index}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex ">
                      <div className="flex">
                        <div className="font-light text-[12px]">
                          Start time:
                        </div>
                        <div className="font-light text-[12px]"> {st}</div>
                      </div>
                      <div className="flex ml-2">
                        <div className="font-light text-[12px]">End time:</div>
                        <div className="font-light text-[12px]"> {et}</div>
                      </div>
                      <div className="flex ml-2">
                        <div className="font-light text-[12px]">Bill %:</div>
                        <div className="font-light text-[12px]"> {+bp}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => handleDelete(index)}
                    className="flex items-center justify-end pt-[2px] pl-[5px] cursor-pointer"
                  >
                    <AiOutlineDelete size={18} color="tomato" />
                  </div>
                </div>
              ))
            )}

            <div
              onClick={() => console.log(tou, "time of use")}
              classsName="italic pt-1 text-gray-600  hidden text-sm font-weight-light"
            ></div>
          </div>
        )}

        <div className="absolute bottom-2 grid grid-cols-2 gap-6 left-0 mx-auto w-full">
          {step !== 1 && (
            <div
              className="border-[1px] text-center border-gray-600 drop-shadow-md px-6 rounded-md py-2 mx-2 bg-white cursor-pointer"
              onClick={() => {
                if (step == 1) return null;
                setstep((prev) => prev - 1);
              }}
            >
              Prev
            </div>
          )}
          {step !== 4 ? (
            <div
              className="drop-shadow-md text-center px-6 rounded-md py-2 mx-2 bg-gray-900 text-white cursor-pointer"
              onClick={() => setstep((prev) => prev + 1)}
            >
              Next
            </div>
          ) : (
            <div className="">
              <Button
                text={"Save"}
                loading={loading}
                onClick={submitMeterClass}
              />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default AddMeterClass;
