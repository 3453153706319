import { Switch } from "antd";
import ChannelImg from "../../../assets/png/channelimg.png";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import ChannelOption from "../ChannelOption";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import RenameModal from "../Modals/RenameModal";
import { commandController } from "../../../controllers/CommandController";
import { getToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import axios from "../../../utils/axios";
import { useMQTT } from "../../../context/MqttContext";
import { Button, Checkbox } from "antd";
const ChannelCard = ({
  data,
  refreshBtn,
  showrename,
  setshowrename,
  setselectedchannel,
  showgroup,
  toggleCheckbox,
  setshowgroup,
  handleAdd,
}) => {
  const [modal, setmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedmodal, setselectedmodal] = useState(null);
  const option = ["Add to group", "Rename", "Recharge"];
  const location = useLocation();
  const { mqttEvents } = useMQTT();
  const [powerstatus, setpowerstatus] = useState(1);
  // TON
  const handleOn = async () => {
    try {
      setloading(true);
      let user = await getToken("spiral_username");
      let res = await axios.post("/commands/immediateturnon", {
        meterid: data?.channelid,
        transactiontopic: `mqtt_${user}`,
      });
      const result = commandController(res);
      if (result.type !== "success") {
        toast[result.type](`${result.message}`);
        return;
      }

      var time = setTimeout(() => {
        toast.success("Your command is being processed");
        setloading(false);
        return;
      }, Number(process.env.REACT_APP_TIME));

      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${user}`) {
          if (!message.payloadString) return null;
          if (JSON.parse(message.payloadString).type == "TON") {
            if (JSON.parse(message.payloadString).deviceid == data?.channelid) {
              // setpowerstatus(
              //   JSON.parse(message.payloadString).status == "SUCCESS" ? 1 : 0
              // );
              setloading(false);
            }
            return clearTimeout(time);
          }
        }
      });
    } catch (err) {
      if (err?.response) {
        toast.error(err.response.data.response);
      }
      toast.message(err?.message);
    }
  };

  const handleOff = async () => {
    try {
      setloading(true);
      let user = await getToken("spiral_username");
      let res = await axios.post("/commands/immediateturnoff", {
        meterid: data?.channelid,
        transactiontopic: `mqtt_${user}`,
      });
      const result = commandController(res);

      if (result.type !== "success") {
        toast[result.type](`${result.message}`);
        setloading(false);
        return;
      }

      var time = setTimeout(() => {
        toast.success("Your command is being processed");
        setloading(false);
        return;
      }, Number(process.env.REACT_APP_TIME));

      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${user}`) {
          if (!message.payloadString) return null;
          if (JSON.parse(message.payloadString).type == "OFF") {
            if (JSON.parse(message.payloadString).deviceid == data?.channelid) {
              // setpowerstatus(
              //   JSON.parse(message.payloadString).status == "SUCCESS" ? 0 : 1
              // );
              setloading(false);
            }
            return clearTimeout(time);
          }
        }
      });

      return;
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };

  const handleSwitch = (e) => {
    if (e == 1) {
      // off
      handleOff();
    } else if (e == 0) {
      // on
      handleOn();
    }
  };

  return (
    <div className="drop-shadow-sm h-[180px] rounded-lg border-[1px] border-gray-400 bg-white p-1 flex flex-col justify-between ">
      {selectedmodal == data?.channelid ? (
        <ChannelOption
          selectedmodal={selectedmodal}
          channelId={data?.channelid}
          setselectedmodal={setselectedmodal}
          showrename={showrename}
          setshowrename={setshowrename}
          setselectedchannel={setselectedchannel}
          showgroup={showgroup}
          setshowgroup={setshowgroup}
        />
      ) : null}

      {/* {showrename && (
        <RenameModal
          open={showrename}
          setopen={setshowrename}
          selectedmodal={setshowrename}
        />
      )} */}

      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start">
          <div className="w-[45px] overflow-hidden flex items-center justify-start">
            {toggleCheckbox ? (
              <img src={ChannelImg} className="w-100 h-100" />
            ) : (
              <div className="w-full pl-2 h-full">
                <Checkbox
                  checked={data?.checked}
                  onChange={() => {
                    handleAdd(data?.alias);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div
          // border-[1px] border-teal-800
          className="mt-[-10px] cursor-pointer rounded-full p-2"
          onClick={() => {
            if (selectedmodal) {
              setselectedmodal(null);
            } else if (!selectedmodal) {
              setselectedmodal(data?.channelid);
              setselectedchannel(data);
            }
          }}
        >
          <BiDotsHorizontalRounded size={28} color={"black"} />
        </div>
      </div>

      <Link
        to={
          data?.type == "single"
            ? `/channel/${data?.channelid}?name=${data?.alias}`
            : data.type == "group"
            ? `/channel/group${window.location.pathname.substring(
                14
              )}/${data.alias.replaceAll(" ", "_").trim()}`
            : `/channel/group/${data.alias.replaceAll(" ", "_").trim()}`
        }
        // onClick={() => {
        //   refreshBtn((prev) => !prev);
        // }}
        className="pl-3 flex items-center justify-start h-[100px] w-full"
      >
        <div className="text-start text-gray-800 font-semibold text-[16px]">
          {data?.alias}
        </div>
      </Link>
      <div className="px-[10px] pb-2 w-full flex justify-between items-center">
        <div className="text-[12px] text-gray-600 font-normal">
          {/* {data?.type == "group" ? "Group" : "Single"}
          {"  "} device */}
          {data?.type == "group" ? "Grouped meters" : "Ikeja"}
        </div>
        <div className="pl-3">
          {data.type == "single" && (
            <Switch
              unCheckedChildren="off"
              checkedChildren="on"
              size="default"
              loading={loading}
              onChange={() => handleSwitch(data?.powerstate)}
              checked={data?.powerstate == 1 ? true : false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChannelCard;
