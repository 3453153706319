import { useState } from "react";
import { useParams } from "react-router-dom";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { measurementCommands } from "./data";
import { BsSoundwave } from "react-icons/bs";
import EnergyTab from "./Tabs/EnergyTab";
import PowerSupplyTab from "./Tabs/PowerSupplyTab";
import HardwareTab from "./Tabs/HardwareTab";
import EnquiryTab from "./Tabs/EnquiryTab";
import ProcessingLoader from "../../../ProcessingLoader";
import ProcessingCard from "../../../Loader/ProcessingCard";
const CommandsTab = ({ open, setopen }) => {
  const [tabstate, settabstate] = useState(1);
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  return loading ? (
    <ProcessingCard open={loading} />
  ) : (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[500px] lg:w-[650px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start justify-content-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Advanced commands
              </div>
              <div className="w-[80%] text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div className="grid grid-cols-4">
            <div
              className={
                tabstate == 1
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(1)}
            >
              Energy commands
            </div>
            <div
              className={
                tabstate == 2
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(2)}
            >
              Power supply
            </div>
            <div
              className={
                tabstate == 3
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(3)}
            >
              Hardware
            </div>
            <div
              className={
                tabstate == 4
                  ? "font-semibold text-gray-900 p-2 text-center cursor-pointer border-b-[1px] border-gray-900"
                  : "font-normal text-gray-400 p-2 text-center cursor-pointer"
              }
              onClick={() => settabstate(4)}
            >
              Enquiry commands
            </div>
          </div>
          <hr className="border-[0.5px] border-gray-400 w-full" />
          {tabstate == 1 && (
            <EnergyTab
              loading={loading}
              setloading={setloading}
              closeBtn={setopen}
            />
          )}
          {tabstate == 2 && (
            <PowerSupplyTab
              loading={loading}
              setloading={setloading}
              closeBtn={setopen}
            />
          )}
          {tabstate == 3 && (
            <HardwareTab
              loading={loading}
              setloading={setloading}
              closeBtn={setopen}
            />
          )}
          {tabstate == 4 && (
            <EnquiryTab
              loading={loading}
              setloading={setloading}
              closeBtn={setopen}
            />
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default CommandsTab;
