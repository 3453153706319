import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useEffect, useState, useRef } from "react";
import { Link, navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken, setToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import { errorBlock } from "../../../controllers/errorBlock";
import AuthBg from "../../../assets/png/1162369_ORH93N1345678.png";
import Typed from "typed.js";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "../../../redux/actions/auth.action";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { headers } from "../../../utils/headers";

const SignIn = () => {
  const controller = new AbortController();
  const [resetpassword, setresetpassword] = useState(true);
  const authState = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const [eye, seteye] = useState(false);
  const [warninglabel, setwarninglabel] = useState({
    color: "orange",
    text: "",
  });
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const token = await getToken("spiral_token");
      if (authState?.loggedIn && token) {
        navigate("/dashboard");
      }
    })();
  }, []);
  const [installPrompt, setInstallPrompt] = useState(null);

  const [prompt, setPrompt] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      console.log(e, "event");
      //  const { outcome } = await installPrompt.userChoice;
      setInstallPrompt((prev) => e);
      // console.log(e);
      setPrompt((prev) => true);
      // console.log(`'beforeinstallprompt' event was fired.`);
    });
  }, []);

  const handleInstall = async () => {
    try {
      // setPrompt((prev) => false);
      // console.log(installPrompt);
      installPrompt.prompt();
      const { outcome } = await installPrompt.userChoice;
      if (outcome.includes("accepted")) {
        toast.success("App installed succesfully");
        return;
      } else if (outcome.includes("dismissed")) {
        return null;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const [tabState, settabState] = useState(1);
  let domain = "IKJ";

  const element = useRef(null);
  const typed = useRef(null);
  // useEffect(() => {
  //   const options = {
  //     strings: [
  //       "Circular Economy.",
  //       "Net Zero goal.",
  //       "Energy Transition.",
  //       "Sustainability.",
  //     ],
  //     typeSpeed: 50,
  //     backSpeed: 50,
  //     loop: true,
  //   };
  //   typed.current = new Typed(element.current, options);
  //   return () => {
  //     // Make sure to destroy Typed instance during cleanup
  //     // to prevent memory leaks
  //     typed.current.destroy();
  //   };
  // }, []);

  const handleLogin = async () => {
    try {
      if (!(email && password)) {
        toast.error("Both values are required.");
        return;
      }

      setloading(true);
      let res = await axios.post(
        "https://api.app.spiral.systems/auth/login",
        {
          username: email,
          password: password,
          subdomain: "app",
        },
        headers
      );

      if (res.data.response == "failed login warning") {
        setwarninglabel({
          color: "orange",
          text: `You have 4 attempts left to login.
           If login fails after that you account will be locked for 24hours.`,
        });
        setloading(false);
        setTimeout(() => {
          setwarninglabel({
            color: "",
            text: "",
          });
        }, 4000);
        return;
      }

      // console.log(res, "result");
      if (res.data.response === "success") {
        // toast.success("Logged in successfully");
        await setToken("spiral_token", res.data.sessionid);
        await setToken("spiral_username", res.data.username);
        if (!res.data.codeverification && res.data.registration) {
          await setToken("spiral_email", "notverified");
          await setToken("spiral_registration", "verified");
          setloading(false);
          // send otp code
          let res = await axios.post(
            "https://api.app.spiral.systems/authselfhelp/resendverificationcode",
            {
              callerid: email,
              subdomain: "app",
            },
            headers
          );

          if (res.data.response == "success") {
            toast.success("A code has been sent to your email address.");
            navigate(`/emailverification?email=${email}`);
            return;
          } else if (res.data.response == "failure") {
            setloading(false);
            toast.error(res.data.response, {
              toastId: "32uwcasc",
            });
            return;
          }
        }
        if (res.data.codeverification && !res.data.registration) {
          await setToken("spiral_email", "verified");
          await setToken("spiral_registration", "notverified");
          await setToken("spiral_role", "User");
          setloading(false);
          dispatch(postLogin());
          navigate("/dashboard");
          return;
        }
        if (res.data.registration && res.data.codeverification) {
          await setToken("spiral_email", "verified");
          await setToken("spiral_registration", "verified");
          await setToken("spiral_role", "User");

          setloading(false);
          dispatch(postLogin());
          window.location.href = "/dashboard";
          // navigate("/dashboard");
          return;
        }
        if (!res.data.registration && !res.data.codeverification) {
          await setToken("spiral_email", "notverified");
          await setToken("spiral_registration", "notverified");
          let res = await axios.post(
            "https://api.app.spiral.systems/authselfhelp/resendverificationcode",
            {
              callerid: email,
              subdomain: "app",
            },
            headers
          );

          if (res.data.response == "success") {
            toast.success("A code has been sent to your email address.");
            setloading(false);
            navigate(`/emailverification?email=${email}&username=${email}`);
            return;
          } else if (res.data.response == "failure") {
            setloading(false);
            toast.error(res.data.response);
            return;
          }
        }
      }
    } catch (err) {
      setloading(false);
      toast.error(err.message);
      // if (err.response.status == 401) {
      //   toast.error("Incorrect details please try again.");
      //   return;
      // }
      // // errorBlock(err);
      // toast.error(err.message);
    }
  };

  const handleLoginAmpere = async () => {
    try {
      if (!username || !password) {
        toast.error("All values are required.");
        return;
      }

      setloading(true);
      let res = await axios.post("https://api.mms.ampere.plus/auth/login", {
        username,
        password,
        subdomain: "we",
      });

      if (res.data.response == "invalid identity") {
        setloading(false);
        return toast.error("Invalid details. Please try.");
      }
      if (res.data.response == "reset password") {
        toast.error("Please reset your default password.");
        setloading(false);
        await setToken("spiral_registration", 0);
        await setToken("spiral_token", res.data?.sessionid);
        await setToken("spiral_username", username);
        navigate("/resetpassword");
        return;
      }
      if (res.data.response === "disable") {
        toast.error("You account has been disabled");
        setloading(false);
        return;
      }
      if (res.data.response === "success") {
        if (!res.data?.registration) {
          toast.success("Please complete your registration");
          await setToken("spiral_registration", 0);
          await setToken("spiral_token", res.data?.sessionid);
          await setToken("spiral_username", username);
          setloading(false);
          navigate("/completeregistration");
          return;
        } else {
          setloading(false);
          await setToken("spiral_registration", 1);
          await setToken("spiral_token", res.data?.sessionid);
          await setToken("spiral_username", username);
          // navigate("/dashboard/systemadmin");

          // Make request to get User Role
          let rolesReq = await axios.post(
            "https://api.mms.ampere.plus/roles/getuserroles",
            {
              callerid: username,
              targetusername: username,
              sessionid: res.data?.sessionid,
            }
          );
          let userRoles = rolesReq.data.body;
          if (!userRoles.length) {
            await setToken("spiral_role", "User");
            navigate("/dashboard");
            setloading(false);
            return;
          } else {
            await setToken("spiral_role", "");
            navigate("/userroles");
            setloading(false);
          }
          // controller.abort();
          // rolesReq.data.body.find((item) => item.role == "Customers")
          //   ? navigate("/dashboard/customerdashboard")
          //   : navigate("/userroles");
        }
      }
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };

  return (
    // bg-[#f4f4f4]
    <div className="h-screen  grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="w-full z-50 md:w-[500px] mx-auto bg-white rounded-md shadow-md">
        {prompt && (
          <div className="flex p-2 bg-[#e8effa] items-center justify-between border-b-[1px] border-blue-300">
            <div className="">Install this software on your device ?</div>
            <div className="flex items-center">
              <button
                onClick={() => handleInstall()}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-green-600 mx-3 "
              >
                Install
              </button>
              <button
                onClick={() => setPrompt(!prompt)}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-red-600 mx-3 "
              >
                No
              </button>
            </div>
          </div>
        )}
        <div className="px-4">
          <div className="pt-6">
            <div className="text-center text-primary font-semibold text-2xl">
              Welcome to Spiral
            </div>
          </div>
          <div className="w-[full] h-auto rounded-md p-6">
            <div className="font-light text-center text-gray-600">
              Fill the fields below to log into your account.
            </div>
            {/* hidden */}
            <div className="hidden grid-cols-2 gap-10 mt-2">
              {/* <div className="  grid-cols-2 gap-10 mt-2"> */}
              <div
                className={
                  tabState == 1
                    ? "cursor-pointer text-center p-2 bg-gray-800 text-white"
                    : "cursor-pointer text-center p-2"
                }
                onClick={() => settabState(1)}
              >
                User
              </div>
              <div
                className={
                  tabState == 2
                    ? "cursor-pointer text-center p-2 bg-gray-800 text-white"
                    : "cursor-pointer text-center p-2"
                }
                onClick={() => settabState(2)}
              >
                System Admin
              </div>
            </div>
            <hr className="mt-1" />
            {tabState == 1 && (
              <div className="">
                {warninglabel.text && (
                  <div
                    className={
                      warninglabel.color == "orange"
                        ? "mt-2 text-red-500 text-center"
                        : ""
                    }
                  >
                    You have a few attempts before further login attempts are
                    frozen for 24 hours.
                    <Link
                      to="/forgotpassword"
                      className="px-2 underline text-blue-300"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                )}
                {/* Lastname */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Username
                  </label>
                  <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[55px]">
                    <div className="d-inline flex items-center justify-center px-2">
                      <AiOutlineUser
                        size={20}
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2 w-full">
                      <input
                        required
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        type={"text"}
                        placeholder={"Enter your username"}
                        className="block placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                      />
                    </div>
                  </div>
                </div>

                {/* Password */}
                <div className="mt-4">
                  <div className="flex items-center justify-between">
                    <label className="text-primary font-normal text-sm">
                      Password
                    </label>
                    <label
                      onClick={() => navigate("/forgotpassword")}
                      className="text-primary underline cursor-pointer font-normal text-sm"
                    >
                      Forgot Password
                    </label>
                  </div>

                  <div className="flex items-center justify-between w-100 border-[1px] rounded-sm bg-white border-gray-300 mt-2 h-[55px]">
                    <div className="flex w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineKey
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-2 w-full">
                        <input
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          type={eye ? "text" : "password"}
                          placeholder={"Enter your password"}
                          className=" block placeholder:text-sm placeholder:font-thin outline-none border-[0px] w-full"
                        />
                      </div>
                    </div>

                    <div
                      className="px-3 cursor-pointer"
                      onClick={() => seteye(!eye)}
                    >
                      {eye ? (
                        <AiOutlineEye
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Button */}
                <div className=" w-100 mt-7">
                  <Button
                    text={"Login"}
                    loading={loading}
                    onClick={handleLogin}
                  />
                </div>

                <div className="w-100 mt-4">
                  <div className="text-primary text-center font-normal py-3">
                    {/* Need help with your account? */}
                  </div>
                  <Link
                    to="/createaccount"
                    className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
                  >
                    <span className="font-semibold text-1xl pl-2">
                      Don't have an account? Create one
                    </span>
                  </Link>
                </div>
              </div>
            )}

            {tabState == 2 && (
              <div className="">
                {/* Lastname */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Username
                  </label>
                  <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[55px]">
                    <div className="d-inline flex items-center justify-center px-2 ">
                      <AiOutlineUser
                        size={20}
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2 w-full">
                      <input
                        required
                        value={username}
                        onChange={(e) => setusername(e.target.value)}
                        type={"text"}
                        placeholder={"Enter your username"}
                        className=" placeholder:text-sm w-full placeholder:font-thin outline-none border-[0px]"
                      />
                    </div>
                  </div>
                </div>

                {/* Password */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Password
                  </label>
                  <div className="flex items-center justify-between w-100 border-[1px] rounded-sm bg-white border-gray-300 mt-2 h-[55px]">
                    <div className="flex w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineKey
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-2 w-full">
                        <input
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          type={eye ? "text" : "password"}
                          placeholder={"Enter your password"}
                          className=" placeholder:text-sm placeholder:font-thin outline-none border-[0px] w-full"
                        />
                      </div>
                    </div>

                    <div
                      className="px-3 cursor-pointer"
                      onClick={() => seteye(!eye)}
                    >
                      {eye ? (
                        <AiOutlineEye
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Button */}
                <div className=" w-100 mt-7">
                  <Button
                    text={"Login"}
                    loading={loading}
                    onClick={handleLoginAmpere}
                  />
                </div>

                <div className="w-100 mt-4">
                  <div className="text-primary text-center font-normal py-3">
                    {/* Need help with you account? */}
                  </div>
                  <Link
                    to="/createaccount"
                    className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
                  >
                    Don't have an account?
                    <span className="font-semibold text-1xl pl-2">
                      Create an account instead.
                    </span>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
