import { Link, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { React, useRef, useEffect, useState } from "react";
import { useMQTT } from "../../../../context/MqttContext";
import Layout from "../../../../components/Layout";
import PageTitle from "../../../../components/PageTitle";
import BackBtn from "../../../../components/BackBtn";
import Rule from "../../../../components/Rule/Index";
// import ChartStreaming from "chartjs-plugin-streaming";
import * as mqtt from "react-paho-mqtt";
import moment from "moment";
import "chartjs-adapter-moment";
import { Button } from "../../../../components/Button";
import { message } from "antd";

const RealtimeConsumptionMeter = () => {
  const entryFormat = [
    {
      color: "blue",
      label: "Frequency",
      entry: "frequencyl1",
    },
    {
      color: "greenn",
      label: "Voltage",
      entry: "maxvoltagel1",
    },
    {
      color: "yellow",
      label: "Current",
      entry: "maxcurrentl1",
    },
    {
      color: "red",
      label: "Energy",
      entry: "activeenergyln1",
    },
    {
      color: "purple",
      label: "Power",
      entry: "apparentpowerl1",
    },
  ];
  const myChartRef = useRef();
  const [pqDate, setPqDate] = useState([]);
  const [cur, setCur] = useState([]);
  const [vol, setVol] = useState([]);
  const [freq, setFreq] = useState([]);
  const [limit, setLimit] = useState(30);
  const [ace, setAce] = useState([]);
  const [acp, setAcp] = useState([]);
  const { client, mqttEvents } = useMQTT();
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      try {
        mqttEvents.on("message", (message) => {
          // console.log("entered here", message);
          if (message.topic == `r_${id}`) {
            let value = message.payloadString;
            if (vol.length == limit && pqDate.length == limit) {
              setPqDate((prev) => [
                ...prev.slice(1),
                value
                  .split("##")
                  [value.split("##").length - 1].substring(0, 19),
              ]);
              // Vol
              setVol((prev) => [
                ...prev.slice(1),
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("vol"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              // Vol
              setFreq((prev) => [
                ...prev.slice(1),
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("fre"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              setCur((prev) => [
                ...prev.splice(1),
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("cur"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              setAce((prev) => [
                ...prev.splice(1),
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("ace"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              setAcp((prev) => [
                ...prev.splice(1),
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("acp"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
            } else {
              console.log("got here");
              setPqDate((prev) => [
                ...prev,
                value
                  .split("##")
                  [value.split("##").length - 1].substring(0, 19),
              ]);

              setFreq((prev) => [
                ...prev,
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("fre"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              setCur((prev) => [
                ...prev,
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("cur"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              console.log([
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("vol"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              setVol((prev) => [
                ...prev,
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("vol"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              setAce((prev) => [
                ...prev,
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("ace"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
              setAcp((prev) => [
                ...prev,
                Number(
                  value
                    .split("##")
                    .filter((item) => item.includes("acp"))
                    .map((item) => item.split(":")[1])
                    .join("")
                ),
              ]);
            }

            // dispatch(
            //   UPDATE_VALUE({
            //     value: {},
            //     alert: false,
            //   })
            // );
          }
        });
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      // clearInterval(time);
      // Remeber to add a button to unscribed from RUP when the user leaves the page
      client?.unsubscribe(`r_${id}`);
    };
  }, []);

  return (
    <Layout>
      <div className="mt-6 mb-[100px]">
        <div className="bg-white drop-shadow-md p-3 rounded-md h-auto  ">
          <BackBtn text={"Go back to energy consumption page"} />
          <div className="flex align-items justify-between">
            <PageTitle
              title={"Real consumption"}
              caption={"Analyze consumption on your meter in realtime"}
            />
            {/* <Button
              color={"white"}
              text={"Result"}
              onClick={() => console.log(vol, "///values")}
            /> */}
          </div>
          <Rule />
          <div className="h-[600px]">
            <Line
              ref={myChartRef}
              data={{
                labels: !pqDate.length
                  ? [0]
                  : pqDate.map((i) => moment(i).format("HH:mm")),
                datasets: [
                  {
                    type: "line",
                    label: "Voltage",
                    fill: false,
                    backgroundColor: "green",
                    data: !vol.length ? [0] : [...new Set(vol)],
                    borderColor: "green",
                    radius: 1,
                    borderWidth: 1,
                    pointBorderWidth: 1,
                    tension: 0.4,
                  },
                  {
                    type: "line",
                    label: "Frequency",
                    fill: false,
                    backgroundColor: "red",
                    data: !freq.length ? [0] : [...new Set(freq)],
                    borderColor: "green",
                    radius: 1,
                    borderWidth: 1,
                    pointBorderWidth: 1,
                    tension: 0.4,
                  },
                  {
                    type: "line",
                    label: "Current",
                    fill: false,
                    backgroundColor: "blue",
                    data: !cur.length ? [0] : [...new Set(cur)],
                    borderColor: "green",
                    radius: 1,
                    borderWidth: 1,
                    pointBorderWidth: 1,
                    tension: 0.4,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                animation: {},
                plugins: {
                  legend: {
                    position: "bottom",
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    grid: {
                      display: true,
                      borderDash: [10],
                      borderDashOffset: 20,
                      borderWidth: 1,
                      color: "#eee",
                      z: 4,
                    },
                    title: {
                      display: false,
                      text: "people seated",
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="pb-[100px]"></div>
      </div>
    </Layout>
  );
};

export default RealtimeConsumptionMeter;
