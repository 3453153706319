import { useContext, useState } from "react";
import CloseButton from "../../../CloseButton";
import ErrorText from "../../../Errors/ErrorText";
import Rule from "../../../Rule/Index";
import { AiOutlineUser } from "react-icons/ai";
import Backdrop from "../../../Backdrop";
import { getToken } from "../../../../utils/token";
import { useParams } from "react-router-dom";
import { channelController } from "../../../../controllers/channelController";
import axios from "../../../../utils/axios";
import { errorBlock } from "../../../../controllers/errorBlock";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Button } from "../../../Button";
import { Select, Space } from "antd";
import moment from "moment";

import { TimeContext } from "../../../../context/TimeContext";

const FreezeModal = ({
  open,
  setopen,
  refreshBtn,
  data,
  opendis,
  setopendis,
}) => {
  const { timeObject } = useContext(TimeContext);
  const [name, setname] = useState("");
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  // construct the full statement
  const [delayFor, setdelayFor] = useState({
    value: 0,
    label: "",
  });

  const [freezeFor, setfreezeFor] = useState({
    value: 0,
    label: "",
  });

  let statement = () => {
    let word = "You have selected ";
    // filter
    let aliases = data.filter((i) => i?.checked);

    if (aliases.length == 1) {
      return aliases[0]?.alias;
    } else {
      for (var i = 0; i < aliases.length - 1; i++) {
        word += `${i == 0 ? "" : ", "}${aliases[i]?.alias}`;
      }

      word = word + `, and ${aliases[aliases.length - 1].alias}`;
      return word;
    }
  };

  const getDayBinaries = () => {
    // Get the current date
    const currentDate = moment();

    switch (currentDate.format("dddd")) {
      case "Sunday":
        return "1000000";
      case "Monday":
        return "0100000";
      case "Tuesday":
        return "0010000";
      case "Wednesday":
        return "0001000";
      case "Thursday":
        return "0000100";
      case "Friday":
        return "0000010";
      case "Saturday":
        return "00000001";
      default:
        return "Invalid time";
    }
  };

  const calculateTime = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // const starttime  =  moment.

    console.log("User's current timezone:", userTimeZone);
  };

  const handleCommand = async () => {
    try {
      let format = "YYYY-MM-DD HH:mm";

      let aliases = data?.map((i) => i?.alias);
      // get the current time

      let starttime, endtime;
      // add this to the starttime
      if (delayFor.label == "min") {
        // add value to the current using the variable
        starttime = moment(Date.now())
          .add(delayFor.value, "minutes")
          .format(format);
      } else {
        starttime = moment(Date.now())
          .add(delayFor.value, "hours")
          .format(format);
      }
      const zone_time_starttime = timeObject(starttime, "", "HH:mm");

      if (freezeFor.label == "min") {
        // add value to the current using the variable
        endtime = moment(starttime)
          .add(freezeFor.value, "minutes")
          .format(format);
      } else {
        endtime = moment(starttime)
          .add(freezeFor.value, "hours")
          .format(format);
      }

      const zone_time_endtime = timeObject("", endtime, "HH:mm");

      starttime = zone_time_starttime?.startdate;
      endtime = zone_time_endtime?.enddate;

      const currentDate = moment();

      const currentDay = currentDate.format("dddd");
      const binaries = getDayBinaries();

      let user = await getToken("spiral_username");

      for await (const alias of aliases) {
        let request = await axios.post("/commands/scheduleddisconnect", {
          meterid: alias,
          daybinaries: binaries,
          starttime: starttime.replaceAll(":", ""),
          endtime: endtime.replaceAll(":", ""),
          onetimeaction: 0,
          transactiontopic: `mqtt_${user}`,
        });
        let result = channelController(request);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setopen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="w-[450px] bg-white rounded-md p-3 animate__animated animate__fadeInUp">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Freeze meters
              </div>
              <div className=" text-gray-700 font-ligh ">
                {statement()}
                <br />
              </div>
            </div>
            <div className="">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div className="h-[30px] px-4 bg-gray-100 flex justify-end items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                // setshowfreezemodal(true);
                setopen(false);
                setopendis(true);
              }}
            >
              <div className="">Show advanced option</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-6">
            <div className="">
              <div className="font-semibold">Delay for</div>
              <div className="flex mt-2 w-full justify-between border-[1.5px] border-gray-400 rounded-md overflow-hidden">
                <input
                  type="number"
                  onChange={(e) => {
                    setdelayFor((prev) => {
                      return {
                        ...prev,
                        value: e.target.value,
                      };
                    });
                  }}
                  className="px-2 placeholder:font-light w-[100px] outline-none "
                  placeholder="enter duration"
                />
                <div className="">
                  <Select
                    defaultValue="min"
                    style={{
                      width: 100,
                    }}
                    onChange={(e) => {
                      setdelayFor((prev) => {
                        return {
                          ...prev,
                          label: e,
                        };
                      });
                    }}
                    allowClear
                    options={[
                      {
                        value: "min",
                        label: "Minutes",
                      },
                      {
                        value: "hrs",
                        label: "Hours",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="font-semibold text-end">Freeze for</div>
              <div className="flex mt-2 w-full justify-between border-[1.5px] border-gray-400 rounded-md overflow-hidden">
                <input
                  type="number"
                  onChange={(e) => {
                    setfreezeFor((prev) => {
                      return {
                        ...prev,
                        value: e.target.value,
                      };
                    });
                  }}
                  className="px-2 placeholder:font-light w-[100px] outline-none "
                  placeholder="enter duration"
                />
                <div className="">
                  <Select
                    defaultValue="min"
                    style={{
                      width: 100,
                    }}
                    onChange={(e) => {
                      setfreezeFor((prev) => {
                        return {
                          ...prev,
                          label: e,
                        };
                      });
                    }}
                    allowClear
                    options={[
                      {
                        value: "min",
                        label: "Minutes",
                      },
                      {
                        value: "hrs",
                        label: "Hours",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 mb-3">
            <Button text={"Submit"} loading={loading} onClick={handleCommand} />
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default FreezeModal;
