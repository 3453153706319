import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ChannelContext } from "../../../../context/channelContext";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { IoFlash } from "react-icons/io5";
import { Button } from "../../../Button";
import { useSafeDispatch } from "../../../../utils/Hooks/useSafeDispatch";
import Skimmer from "../../../Loader/Skimmer";
import BillImg from "../../../../assets/svg/7952173_receipt_paper_transation_check_sale_icon.svg";
import { getToken } from "../../../../utils/token";
import { commandController } from "../../../../controllers/CommandController";
import axios from "../../../..//utils/axios";
import { cardController } from "../../../../controllers/CardController";
import { useMQTT } from "../../../../context/MqttContext";
const END = ({ open, setopen }) => {
  const { mqttEvents } = useMQTT();
  const { getBillingData } = useContext(ChannelContext);
  const { id } = useParams();
  const [loading, setloading] = useState(true);
  const [sending, setsending] = useState(false);
  const [billdetails, setbilldetails] = useState(null);
  const [total, settotal] = useState(0);
  const [assignedunit, setassignedunit] = useState(0);
  const [amount, setamount] = useState(0);
  const [tranxfee, settranxfee] = useState(100);
  const [vat, setvat] = useState(0);
  const [grandtotal, setgrandtotal] = useState(0);
  const [showbilling, setshowbilling] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let result = await getBillingData(id);
        setbilldetails(result);
        if (!Object(result).keys || Object(result).keys == 1) {
          setshowbilling(false);
        }
        setloading(false);
      } catch (err) {
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {};
  }, []);

  useEffect(() => {
    // Calculate and update assignedunit after amount state is updated
    setassignedunit(amount * billdetails?.ratekwh);
    // console.log(billdetails, "-- units");
    // ... (other calculations)
  }, [amount, billdetails?.ratekwh]);

  const handleSubmit = async () => {
    try {
      if (+amount < 500)
        return toast.error("Minumum unit you can purchase is 500 units");

      let creator = await getToken("spiral_username");
      setsending((prev) => true);
      let res = await axios.post("/submeter/cardpayment/acquiretransactionid", {
        creator,
        planalias: "END##Untimed",
        channelid: id,
        paymentgateway: "demo",
        amount: parseInt(grandtotal),
      });
      let result = cardController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setsending((prev) => false);
        return;
      }

      if (result.message.transactionid == "failed") {
        toast.error("Operation failed. Please try again.");
        setsending((prev) => false);
        return;
      }

      let commit = await axios.post("/submeter/cardpayment/committransaction", {
        transactionid: result.message.transactionid,
      });
      let resCommit = cardController(commit);
      if (resCommit.type !== "success") {
        toast[resCommit.type](resCommit.message);
        setsending((prev) => false);
        return;
      }
      setsending((prev) => false);
      toast[resCommit.type](resCommit.message.status);
      setopen(false);

      var time = setTimeout(() => {
        toast.success("Your request is being processed", {
          toastId: "vw389",
        });
        setopen(false);
      }, 5000);
      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${creator}`) {
          if (!message.payloadString) return null;
          if (JSON.parse(message.payloadString).type == "END") {
            setopen(false);
            return clearTimeout(time);
          }
        }
      });
    } catch (err) {
      toast.error(err.message);
      setsending(false);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[400px] bg-white rounded-md p-3">
          <div className="flex items-start justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Buy energy
              </div>
              <div className="text-gray-700 hidden font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className="w-[200px] flex items-center justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />

          {loading ? (
            <div className="">
              <div className="w-[70%] mx-auto my-2">
                <Skimmer heigth={"35px"} />
              </div>
              <div className="w-[100%] mx-auto my-2">
                <Skimmer heigth={"35px"} />
              </div>
              <Rule />
              <div className="w-[100%] mx-auto my-2">
                <Skimmer heigth={"35px"} />
              </div>
              <div className="w-[100%] mx-auto my-2">
                <Skimmer heigth={"35px"} />
              </div>
              <div className="w-[100%] mx-auto my-2">
                <Skimmer heigth={"35px"} />
              </div>
              <div className="w-[100%] mx-auto my-2">
                <Skimmer heigth={"35px"} />
              </div>
              <Rule />
              <div className="w-[100%] mx-auto my-2">
                <Skimmer heigth={"35px"} />
              </div>
            </div>
          ) : billdetails?.kwhbilling == "false" ? (
            <div className="h-[200px] flex items-center justify-center flex-col">
              <div className="flex items-center justify-center w-full">
                <img src={BillImg} className="w-[40px] h-[40px]" alt="" />
              </div>
              <div className="font-Kanit text-gray-800 text-[17px] text-center">
                This device has not been activated for billing.
              </div>
            </div>
          ) : (
            <div className="">
              <div className="font-Kanit text-gray-800 text-[17px] text-center">
                Price per unit kWh is {billdetails ? billdetails?.ratekwh : 0}
              </div>
              <div className="mt-1">
                <label className="text-primary font-normal text-sm">
                  Amount
                </label>
                <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-1 h-[45px]">
                  <div className="d-inline flex items-center justify-center px-2">
                    <IoFlash
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2 w-full">
                    <input
                      required
                      value={amount}
                      onChange={(e) => {
                        setamount(e.target.value);
                        // setassignedunit(
                        //   Number(e.target.value) * billdetails?.ratekwh
                        // );
                        // console.log(
                        //   Number(e.target.value) * billdetails?.ratekwh
                        // );
                        // amount + transaction fee
                        settotal(Number(e.target.value) + tranxfee);
                        //( amount + transaction fee ) * VAT
                        setvat((Number(e.target.value) + tranxfee) * 0.1);

                        setgrandtotal(
                          (Number(e.target.value) + tranxfee) * 0.1 +
                            (Number(e.target.value) + tranxfee)
                        );
                      }}
                      type={"number"}
                      placeholder={
                        "Minimum unit you can purchase is" +
                        " " +
                        billdetails?.minvend
                      }
                      className=" placeholder:text-sm placeholder:font-normal w-full outline-none border-[0px]"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-1 text-light text-[15px]">
                Transaction summary
              </div>
              <div className="">
                <div className="flex item-center justify-between py-2">
                  <div className="">Units you will get:</div>
                  <div className="">{assignedunit.toLocaleString()} units</div>
                </div>
                <div className="flex item-center justify-between py-2">
                  <div className="">Amount entered:</div>
                  <div className="">₦{amount}</div>
                </div>
                <div className="flex item-center justify-between py-2">
                  <div className="">Transaction fee</div>
                  <div className="">{tranxfee}</div>
                </div>
                <Rule />
                <div className="flex item-center justify-between">
                  <div className="">Total</div>
                  <div className="">{total}</div>
                </div>
                <Rule />
                <div className="flex item-center justify-between">
                  <div className="">VAT(10%)</div>
                  <div className="">{vat.toFixed("2")}</div>
                </div>
                <Rule />
                <div className="flex item-center justify-between">
                  <div className="">Subtotal(10%)</div>
                  <div className="">₦{grandtotal.toLocaleString()}</div>
                </div>
                <Rule />
                <Rule />

                <div className="">
                  <Button
                    text={"Buy"}
                    onClick={handleSubmit}
                    loading={sending}
                  />
                </div>
                <div className="italic text-gray-600 text-[13px]">
                  A transaction fee ₦100 will be added to the fee for this
                  transaction.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default END;
