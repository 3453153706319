import { useEffect, useState } from "react";
import BackBtn from "../../../BackBtn";
import Layout from "../../../Layout";
import PaymentPlanTable from "../../../PaymentPlan/PaymentPlanTable";
import PaymentPlanCard from "../../Cards/PaymentPlanCard";
import axios from "../../../../utils/axios";
import TableSkimmer from "../../../TableSkimmer";
import Skimmer from "../../../Loader/Skimmer";
import NoPlanImg from "../../../../assets/svg/7952173_receipt_paper_transation_check_sale_icon.svg";
import { useParams } from "react-router-dom";
import { errorBlock } from "../../../../controllers/errorBlock";
import BuyPlan from "../../Modals/BuyPlan";
const ChannelPaymentPlan = () => {
  const [loadingPlan, setloadingPlan] = useState(false);
  const [plans, setplans] = useState([]);
  // ==== plan start
  const [plandetails, setplandetails] = useState({});
  const [planmodal, setplanmodal] = useState(false);
  // ==== plan end
  const { id } = useParams();
  // plans
  useEffect(() => {
    (async () => {
      try {
        setloadingPlan(true);
        let res = await axios.post(
          "/submeter/paymentplanassignment/getallassignedplans",
          {
            channelid: id,
          }
        );

        setplans(res?.data.body);
        // setusers();
        // console.log(res?.data, "////data");
        setloadingPlan(false);
      } catch (err) {
        errorBlock(err);
        setloadingPlan(false);
      }
    })();
    return () => {};
  }, []);
  return (
    <Layout>
      <div className="mt-2">
        <div className="flex items-center justify-between mb-4">
          <BackBtn text={"Go back"} />
        </div>
        <div className="h-[80vh] border-[1px] rounded-md border-gray-300 mt-2 px-2">
          {loadingPlan ? (
            <>
              <div className="hidden md:block lg:block">
                <TableSkimmer entries={10} />
              </div>
              <div className="block md:hidden">
                {Array.from(Array(6)).map((_, i) => (
                  <div key={i} className="rounded-3 overflow-hidden my-2">
                    <Skimmer heigth={70} />
                  </div>
                ))}
              </div>
            </>
          ) : plans.length == 0 ? (
            <div className="flex h-[400px] flex-col items-center justify-center">
              <div className="w-[60px] h-[60px] ">
                <img src={NoPlanImg} className="w-full h-full" />
              </div>
              <div className="pt-2 font-Kanit font-semibold text-center">
                No payment plan available on this device at the moment.
              </div>
            </div>
          ) : (
            <>
              <div className="hidden md:block">
                <PaymentPlanTable
                  data={plans.length == 0 ? [] : plans}
                  plandetails={plandetails}
                  setplandetails={setplandetails}
                  planmodal={planmodal}
                  setplanmodal={setplanmodal}
                />
              </div>
              <div className="block md:hidden">
                {plans.map((i, index) => (
                  <PaymentPlanCard
                    data={i}
                    key={index}
                    plandetails={plandetails}
                    setplandetails={setplandetails}
                    planmodal={planmodal}
                    setplanmodal={setplanmodal}
                  />
                ))}
              </div>
            </>
          )}
        </div>{" "}
        {planmodal && (
          <BuyPlan plan={plandetails} open={planmodal} setopen={setplanmodal} />
        )}
      </div>
      {/* plan */}
    </Layout>
  );
};

export default ChannelPaymentPlan;
