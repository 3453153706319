import Rule from "../../../../../Rule/Index";
import { TbFileInvoice } from "react-icons/tb";
import { themeColor } from "../../../../../../constant/color";
import { logData } from "./data";
import { GiMoneyStack } from "react-icons/gi";
import { toast } from "react-hot-toast";
import { useMQTT } from "../../../../../../context/MqttContext";
import { getToken } from "../../../../../../utils/token";
import axios from "../../../../../../utils/axios";
import { commandController } from "../../../../../../controllers/CommandController";
import { useParams } from "react-router-dom";
const RechargeTab = ({ loading, setloading, closeBtn }) => {
  const { mqttEvents } = useMQTT();
  const { id } = useParams();

  const handleCommand = (cmd) => {
    switch (cmd) {
      case "len":
        (async () => {
          try {
            console.log("clicked");
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/lastrechargeenquiry", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "LEN" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      case "ben":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/balanceenquiry", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (
                  JSON.parse(message.payloadString).type == "BEN" &&
                  JSON.parse(message.payloadString).deviceid == id
                ) {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      default:
        break;
    }
  };
  return (
    <div className="w-[80%] mx-auto">
      <div className="mt-4 w-full">
        <div className="font-Kanit text-center text-secondary font-semibold text-[17px]">
          Request for recharge history on your channel.
        </div>
        <div className=" text-center text-gray-700 font-light">
          Click on any of the button below request for the balance or recharge
          history on your channel
        </div>
      </div>
      <Rule />
      <div className="grid grid-cols-2 gap-10 mt-6">
        {logData.map(({ title, id, desc, icon, cmdType }) => (
          <div
            onClick={() => handleCommand(cmdType)}
            key={id}
            className="bg-white rounded-md drop-shadow-md p-2 border-b-[2px] transition-all ease-out border-gray-600 hover:border-secondary cursor-pointer relative"
          >
            <div className="">
              {icon == "money" ? (
                <GiMoneyStack size={21} color={themeColor.primary} />
              ) : (
                <TbFileInvoice size={21} color={themeColor.primary} />
              )}
            </div>
            <div className="font-semibold pt-1">{title}</div>
            <div className="font-light">{desc}</div>
          </div>
        ))}
      </div>
      <div className="mt-20 mb-3"></div>
    </div>
  );
};

export default RechargeTab;
