import LayoutMobile from "../../../components/LayoutMobile";
import { BsPlusCircle } from "react-icons/bs";
import { themeColor } from "../../../constant/color";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChannelContext } from "../../../context/channelContext";
import Skimmer from "../../../components/Loader/Skimmer";
import ChannelCard from "../../../components/Channels/ChannelCard";
import { updateChannels } from "../../../redux/slice/channelSlice";
import { toast } from "react-hot-toast";
import Layout from "../../../components/Layout";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import BackBtn from "../../../components/BackBtn";
import { useLocation, Link } from "react-router-dom";
import PaginationButton from "../../../components/PaginationButton";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";
import AddToGroup from "../../../components/Channels/Groups/AddToGroup";
import RenameModal from "../../../components/Channels/Modals/RenameModal";
import AddMeter from "../../../components/Meter/AddMeter";
import ChannelGroups from "../../../components/Channels/Drawer/ChannelGroups";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FcHome } from "react-icons/fc";
const Groupedchannels = () => {
  const dispatch = useDispatch();
  const { getAllChannels } = useContext(ChannelContext);
  const [limit, setlimit] = useState(10);
  const [offset, setoffset] = useState(0);
  const [page, setpage] = useState(1);
  const [loading, setloading] = useState(false);
  const [save, setsave] = useState(false);
  const [data, setdata] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  let formattedPath = pathname.substring(15).replaceAll("_", " ");
  // modals
  const [showrename, setshowrename] = useState(false);
  const [renamemodal, setrenamemodal] = useState(true);
  const [selectedchannel, setselectedchannel] = useState({});
  const [showgroup, setshowgroup] = useState(false);
  const [groupmodal, setgroupmodal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [metermodal, setmetermodal] = useState(false);

  const [refreshBtn, setrefreshBtn] = useState(false);
  const controller = new AbortController();
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let res = await axios.post("/submeter/getallchannels", {
          groupprefix: location.pathname.substring(15).replaceAll("_", " "),
          limit: limit,
          offset: offset,
        });

        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setloading(false);
          return;
        }

        const item = result.message.body.map((item, index) => {
          if (item.type == "group") {
            return {
              alias: item.alias,
              channelid: item.alias,
              type: item.type,
              billingactive: false,
            };
          } else if (item.type == "single") {
            return {
              alias: item.alias,
              channelid: item.channelid,
              type: item.type,
              billingactive: item.billingactive,
            };
          }
        });
        setdata(item);
        setloading(false);
      } catch (err) {
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [refresh, pathname]);

  return (
    <>
      {groupmodal && <AddToGroup closeBtn={setgroupmodal} />}
      {metermodal && <AddMeter closeBtn={setmetermodal} />}
      {showrename && (
        <RenameModal
          open={showrename}
          setopen={setshowrename}
          refreshBtn={setrefresh}
          closeBtn={setrenamemodal}
          selectedchannel={selectedchannel}
          setselectedchannel={setselectedchannel}
        />
      )}

      {showgroup && (
        <ChannelGroups
          title={"Device Groups"}
          open={showgroup}
          setopen={setshowgroup}
          refreshBtn={setrefresh}
          selectedchannel={selectedchannel}
          setselectedchannel={setselectedchannel}
        />
      )}
      <Layout pageTitle={"Channels"}>
        <div className="pt-[10px] cursor-pointer ">
          <div
            className="hidden items-center justify-center border-[1px] border-secondary rounded-full p-1 "
            //   onClick={() => setaddmetermodal(true)}
          >
            <div className="">
              <BsPlusCircle size={20} color={themeColor.secondary} style={{}} />
            </div>
            <div className="text-secondary pl-2">Add device</div>
          </div>
        </div>

        <div className="h-auto bg-white mb-[60px] rounded-md drop-shadow">
          {/* <div className="">
            <BackBtn text={"Go back"} />
          </div> */}
          <div className="px-3 py-2">
            <div className="font-semibold font-Kanit text-[17px]">
              Grouped Channels.
            </div>
            <div className="text-gray-700 text-start font-normal">
              List of all the channels you have in{" "}
              {pathname.split("/")[3].replaceAll("_", " ")}.
            </div>
          </div>
          <div className="px-2 py-2 flex items-center border ">
            <Link
              to="/allchannels"
              className="underline text-blue-600 flex items-center jusity-center"
            >
              <FcHome />
              <span className="px-[2px]">Home</span>
            </Link>
            <div className="flex items-center justify-center">
              <MdKeyboardArrowRight size={14} />
            </div>
            <div className="overflow-x-scroll flex overflow-y-none w-full">
              {formattedPath.split("/").map((i, index) => (
                <div className="flex">
                  <Link
                    key={index}
                    to={
                      "/channel/group/" +
                      pathname
                        .substring(15)
                        .split("/")
                        .slice(0, index + 1)
                        .join("/")
                    }
                    className="font-normal text-blue-600 underline text-[14px] mx-2"
                  >
                    {i}
                  </Link>
                  <div className="flex items-center justify-center">
                    <MdKeyboardArrowRight size={14} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr className="border-[0.5px] border-gray-200 " />
          {loading ? (
            <div className=" p-2 grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 mt-4">
              {Array.from(Array(15)).map((_, i) => (
                <div className="h-[190px] drop-shadow-sm" key={i}>
                  <Skimmer heigth={"100%"} />
                </div>
              ))}
            </div>
          ) : !data.length ? (
            <div className="mt-[50px] flex flex-col h-[70vh] justify-center items-center">
              <div className=""></div>
              <div className="text-center font-semibold text-gray-700">
                You don't have any meter at the moment
              </div>
              <div
                className="w-[fit-content] mt-4 flex items-center justify-center border-[1px] border-secondary rounded-full p-1"
                // onClick={() => setaddmetermodal(true)}
              >
                <div className="">
                  <BsPlusCircle
                    size={20}
                    color={themeColor.secondary}
                    style={{}}
                  />
                </div>
                <div className="text-secondary pl-2">Add meter</div>
              </div>
            </div>
          ) : (
            <div className="mt-4 p-3">
              <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-8 h-[80vh] place-content-start  ">
                {data.map((i, index) => (
                  <ChannelCard
                    id={index}
                    data={i}
                    showrename={showrename}
                    setshowrename={setshowrename}
                    selectedchannel={selectedchannel}
                    setselectedchannel={setselectedchannel}
                    showgroup={showgroup}
                    setshowgroup={setshowgroup}
                    refreshBtn={setrefresh}
                  />
                ))}
              </div>
              <PaginationButton
                page={page}
                setoffset={setoffset}
                offset={offset}
                limit={limit}
                setlimit={setlimit}
                data={data}
              />
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Groupedchannels;
