import { useContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineUser } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { BillingContext } from "../../../../../../context/BillingContext";
import { billingController } from "../../../../../../controllers/BillingController";
import { Button } from "../../../../../Button";
import { MdOutlineAdUnits } from "react-icons/md";
import { IoIosCash } from "react-icons/io";
import { MeterClassContext } from "../../../../../../context/MeterClassContext";
import { channelController } from "../../../../../../controllers/channelController";
import { meterClassController } from "../../../../../../controllers/MeterClassController";
import { Select } from "antd";
import axios from "../../../../../../utils/axios";
import { getToken } from "../../../../../../utils/token";
import { stringShorter } from "../../../../../../helperFuncs";
import { useMQTT } from "../../../../../../context/MqttContext";
const MeterClassTab = ({ loading, setloading, closeBtn }) => {
  const { Option } = Select;
  const { id } = useParams();
  const { mqttEvents } = useMQTT();
  const [selectedMeterclass, setSelectedMeterClass] = useState("");
  const [mclassdetails, setmclassdetails] = useState(null);
  const [meterClass, setMeterClass] = useState([]);
  const { getAllMeterClass, addMeterClassToMeter } =
    useContext(MeterClassContext);
  const { setBilling, disableBilling } = useContext(BillingContext);
  useEffect(() => {
    (async () => {
      try {
        let res = await getAllMeterClass();
        let result = meterClassController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setMeterClass((prev) => [...result.message.body]);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const handleMeterclass = async () => {
    try {
      setloading(true);
      let user = await getToken("spiral_username");
      let res = await axios.post("/commands/addmetertometerclass", {
        meterid: id,
        meterclass: selectedMeterclass,
        transactiontopic: `mqtt_${user}`,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }
      var time = setTimeout(() => {
        setloading(false);
        toast.success("Your command is being processed");
        closeBtn(false);
        return;
      }, process.env.REACT_APP_TIME);

      mqttEvents.on("message", (message) => {
        if (message.topic === `mqtt_${user}`) {
          if (!message.payloadString) return null;
          if (
            JSON.parse(message.payloadString).type == "AMC" &&
            JSON.parse(message.payloadString).deviceid == id
          ) {
            setloading(false);
            closeBtn(false);
            return clearTimeout(time);
          }
        }
      });
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
        return;
      }
      toast.error(err.message);
      setloading(false);
    }
  };

  return (
    <div className="w-[80%] mx-auto">
      <div className="mt-10 w-full">
        <div className="font-Kanit text-center text-secondary font-semibold text-[17px]">
          Meter class
        </div>
        <div className="text-center text-gray-700 font-light">
          Select a meter class from the options below to add a meter class on
          your device.
        </div>
      </div>
      <div className="mt-3">
        <label className="text-primary font-normal text-sm">
          Select meter class
        </label>
        <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-1 h-[45px]">
          <div className="w-full ">
            <Select
              className=""
              bordered={false}
              placeholder="Select access type"
              style={{
                width: "100%",
                height: "100%",
              }}
              onChange={(e) => {
                setSelectedMeterClass(e);
                let result = meterClass.find((i) => i?.meterclass == e);
                setmclassdetails(result);
              }}
            >
              {meterClass.map((item) => (
                <Option value={item?.meterClass} key={item.meterclass}>
                  <span className="font-semibold">Name: </span>
                  {item.meterclass}-{" "}
                  <span className="font-semibold">Description: </span>
                  {item?.description}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        {mclassdetails && (
          <div className="">
            <div className="flex items-center justify-start pt-1">
              <div className="text-gray-600 font-light">Name: </div>
              <div className="">{mclassdetails?.meterclass}</div>
            </div>
            <div className="flex items-center justify-start pt-1">
              <div className="text-gray-600 font-light">Description: </div>
              <div className="">{mclassdetails?.description}</div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-16 mb-3">
        <Button
          text={"Add meter class"}
          loading={loading}
          onClick={handleMeterclass}
        />
      </div>
    </div>
  );
};

export default MeterClassTab;
