import Drawer from "../../../Drawer";
import { AiOutlineUser } from "react-icons/ai";
import { useState } from "react";
import Rule from "../../../Rule/Index";
import { DatePicker, Select } from "antd";
import { BsClock } from "react-icons/bs";
import DaysSelector from "../../../Commands/DaysSelector";
import { Button } from "../../../Button";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getToken } from "../../../../utils/token";
import { commandController } from "../../../../controllers/CommandController";
import axios from "../../../../utils/axios";
const CummulativeEnergyLimit = ({ title, open, setopen, caption }) => {
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [step, setstep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [daybinaries, setdaybinaries] = useState([
    {
      id: 1,
      title: "Sunday",
      value: 0,
    },
    {
      id: 2,
      title: "Monday",
      value: 0,
    },
    {
      id: 3,
      title: "Tuesday",
      value: 0,
    },
    {
      id: 4,
      title: "Wednesday",
      value: 0,
    },
    {
      id: 5,
      title: "Thursday",
      value: 0,
    },
    {
      id: 6,
      title: "Friday",
      value: 0,
    },
    {
      id: 7,
      title: "Saturday",
      value: 0,
    },
  ]);
  const { RangePicker } = DatePicker;
  const { id } = useParams();
  const handleSubmit = async () => {
    try {
      if (!(startTime && endTime))
        return toast.error("Please select a date range");
      if (!number) return toast.error("Please enter a limit");
      setLoading(true);
      let user = await getToken("spiral_username");
      let request = await axios.post("/commands/cumulativeenergylimit", {
        meterid: id,
        daybinaries: daybinaries.map((i) => i.value).join(""),
        starttime: moment(startTime._d)
          .format("HH:mm")
          .replaceAll(":", "")
          .trim(),
        endtime: moment(endTime._d).format("HH:mm").replaceAll(":", "").trim(),
        startdate: moment(startTime._d).format("DD:MM:YY").replaceAll(":", ""),
        enddate: moment(endTime._d).format("DD:MM:YY").replaceAll(":", ""),
        transactiontopic: `mqtt_${user}`,
        kwh: number,
      });

      const result = commandController(request);
      if (result.type !== "success") {
        toast[result.type](`${result.message}`);
        setLoading(false);
        return;
      }
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
      return;
    }
  };
  return (
    <Drawer
      setopen={setopen}
      open={open}
      title={title}
      caption={
        "Use the option below to set a daily energy limit on your channel."
      }
    >
      <Rule />
      <div className="px-3 relative border-[1px] border-gray-300 rounded-md h-[85vh]">
        {step == 1 && (
          <div className="mt-4 mb-4">
            <label className="text-gray-800 font-semibold">
              Select a time range for this action to occur.
            </label>
            <div className="flex bg-white items-center rounded-md w-100 border-[1px] border-gray-200 mt-2 h-[45px]">
              <div className="d-inline flex items-center justify-center px-2">
                <BsClock
                  size={20}
                  color="text-primary"
                  className="text-primary"
                />
              </div>
              <div className="ml-2 w-full">
                <RangePicker
                  showTime
                  showHour
                  value={[startTime, endTime]}
                  showMinute
                  bordered={false}
                  showSecond={false}
                  format={"YYYY-MM-DD HH:mm"}
                  onChange={(e) => {
                    setStartTime(e[0]);
                    setEndTime(e[1]);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {step == 2 && (
          <div className="mt-4">
            <label className="text-gray-800 font-semibold">
              What days of the week should this action tak effect?
            </label>
            <div className="flex flex-wrap bg-white items-center rounded-md w-100 border-[1px] border-gray-200 mt-4 ">
              <DaysSelector setFd={setdaybinaries} fd={daybinaries} />
            </div>
          </div>
        )}

        {step == 3 && (
          <div className="mt-4">
            <label className="text-gray-800 font-semibold">
              Enter the limit you want to set on the channel
            </label>
            <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
              <div className="d-inline flex items-center justify-center px-2">
                <AiOutlineUser
                  size={20}
                  color="text-primary"
                  className="text-primary"
                />
              </div>
              <div className="ml-2">
                <input
                  required
                  value={number}
                  onChange={(e) => setnumber(e.target.value)}
                  type={"number"}
                  placeholder={"Enter limit"}
                  className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                />
              </div>
            </div>
          </div>
        )}

        <div className="absolute bottom-2 grid grid-cols-2 gap-6 left-0 mx-auto w-full">
          {step !== 1 && (
            <div
              className="border-[1px] text-center border-gray-600 drop-shadow-md px-6 rounded-md py-2 mx-2 bg-white cursor-pointer"
              onClick={() => {
                if (step == 1) return null;
                setstep((prev) => prev - 1);
              }}
            >
              Prev
            </div>
          )}
          {step !== 3 ? (
            <div
              className="drop-shadow-md text-center px-6 rounded-md py-2 mx-2 bg-gray-900 text-white cursor-pointer"
              onClick={() => setstep((prev) => prev + 1)}
            >
              Next
            </div>
          ) : (
            <div className="">
              <Button text={"Save"} loading={loading} onClick={handleSubmit} />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default CummulativeEnergyLimit;
