export const logData = [
  {
    id: 1,
    icon: "money",
    title: "Balance enquiry",
    desc: "View balance on your channel.",
    cmdType: "ben",
  },
  {
    id: 2,
    icon: "bill",
    title: "Last Recharge enquiry",
    desc: "View your last recharge enquiry.",
    cmdType: "len",
  },
];
