import React from "react";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import EmptyState from "../../../EmptyState";

const ChannelUsersTable = ({ data = [], setuserdetails, open, setopen }) => {
  return (
    <div>
      {/* {modal && (
          <RequestDetails data={details} open={opne} setopen={setmodal} />
        )} */}
      {!data.length ? (
        <EmptyState>
          <div className="flex felx-col item-center justify-center h-[400px]">
            <HiUserGroup size={25} />
            <div className="font-semibold">
              You don't any user on your channel
            </div>
          </div>
        </EmptyState>
      ) : (
        <table className="w-full border-collapse">
          <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
            <th className="font-semibold text-gray-800">S/N</th>
            <th className="font-semibold text-gray-800">Full Name</th>
            <th className="font-semibold text-gray-800">Username</th>
            <th className="font-semibold text-gray-800">Access</th>
            <th className="font-semibold text-gray-800">Date joined</th>
            {/* <th className="font-semibold text-gray-800">Action</th> */}
          </thead>
          <tbody>
            {data.map((i, index) => (
              <tr
                className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
                key={i?.roleid}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  {i.name?.firstname} {i.name?.lastname}
                </td>
                <td className="text-center">{i?.username}</td>
                <td className="text-secondary rounded-lg text-center">
                  {i?.accesscontrol == "Multiple" ? "Shared" : "Single"}
                </td>
                <td className="text-center">
                  <div className="flex items-center justify-center">
                    <div className="flex items-center justify-center pt-[2px]">
                      <FcCalendar size={21} />
                    </div>
                    <div className="pl-2">
                      {moment(i?.dateentered).format("lll")}
                    </div>
                  </div>{" "}
                </td>

                {/* <td className="text-center">
                  <div className="border-[1px] rounded border-orange-500 text-[12px] text-orange-500 font-light px-4 py-2">
                    Edit
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ChannelUsersTable;
