import { useParams } from "react-router-dom";

const UserCard = ({ value = 0, caption, title }) => {
  const { id } = useParams();
  return (
    <div className="h-[120px] p-2 bg-white rounded-md drop-shadow-md relative">
      <div className="pt-1">
        <div className="font-light text-gray-700">{title}</div>
        <div className="font-semibold w-[80%]">{caption}</div>
      </div>
      <div
        to={`/channel/usermanagement/${id}`}
        className="font-normal hover:text-secondary hidden text-[13px] pt-1 absolute bottom-1 left-2  text-[#7e6eda] underline cursor-pointer w-[60%]"
      >
        Manage users
      </div>
      <div className="flex items-center absolute bottom-[15px] right-[15px] w-[50px] h-[50px] bg-white drop-shadow-lg cursor-pointer justify-center p-2 rounded-full border-[2px] border-teal-400 p-2">
        <div className="font-bold text-2xl">{value}</div>
      </div>
    </div>
  );
};

export default UserCard;
