import "./style.css";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import Backdrop from "../../Backdrop";
import { getToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import CloseButton from "../../CloseButton";
import Rule from "../../Rule/Index";
import { Button } from "../../Button";
import { useMQTT } from "../../../context/MqttContext";
import { IoCloseOutline } from "react-icons/io5";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";
import { FiPower } from "react-icons/fi";
import Skimmer from "../../Loader/Skimmer";
import ConnectionImg from "../../../assets/svg/5760417_off_on_power_switch_icon.svg";

const BEN = ({
  responsedata,
  setresponsedata,
  responseType,
  setresponseType,
  restype,
  open,
  setopen,
  handleNoti,
}) => {
  const { mqttEvents } = useMQTT();
  const [loading, setLoading] = useState(false);
  const [genState, setGenState] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [length, setLength] = useState(0);
  const [name, setname] = useState("");

  const { meterid } = responsedata;
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let username = await getToken("spiral_username");
        let res = await axios.post("/submeter/getchannelinfo", {
          username,
          channelid: meterid,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }
        setname(result.message.body[0].devicealias);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
    return () => {};
  }, []);

  const planType = (type) => {
    switch (type) {
      case "ENA":
        return "Daily energy plan";
      case "ENB":
        return "Daily power plan";
      case "ENC":
        return "Cummulative energy plan";
      case "END":
        return "Untimed energy";
      default:
        break;
    }
  };

  const formatDate = (p) => {
    return (
      p.substring(0, 2) +
      "-" +
      p.substring(2, 4) +
      "-" +
      p.substring(p.length - 2, p.length)
    );
  };

  var formatTime = (d) => {
    return d.substring(0, 2) + ":" + d.substring(2, d.length);
  };

  const DateUI = (dateArr) => {
    return (
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #eee",
          borderRadius: "5px",
          width: "fit-content",
          background: "white",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            color: dateArr[0] == 0 ? "#000" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[0] == 0 ? "white" : "#000",
          }}
        >
          S
        </div>
        <div
          style={{
            color: dateArr[1] == 0 ? "#000" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[1] == 0 ? "white" : "#000",
          }}
        >
          M
        </div>
        <div
          style={{
            color: dateArr[2] == 0 ? "#000" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[2] == 0 ? "white" : "#000",
          }}
        >
          T
        </div>
        <div
          style={{
            color: dateArr[3] == 0 ? "#000" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[3] == 0 ? "white" : "#000",
          }}
        >
          W
        </div>
        <div
          style={{
            color: dateArr[4] == 0 ? "#000" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",

            background: dateArr[4] == 0 ? "white" : "#000",
          }}
        >
          T
        </div>
        <div
          style={{
            color: dateArr[5] == 0 ? "#000" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",

            background: dateArr[5] == 0 ? "white" : "#000",
          }}
        >
          F
        </div>
        <div
          style={{
            color: dateArr[6] == 0 ? "green" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[6] == 0 ? "white" : "green",
          }}
        >
          S
        </div>
      </div>
    );
  };

  // console.log(responsedata, "////");
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[500px] bg-white drop-shadow-md roundeds-sm p-3">
          {/* top */}
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Balance Enquiry
              </div>
              <div className="hidden w-[100%] text-gray-700 font-light">
                Request to get balance on your meter.
                <br />
              </div>
            </div>

            <div className=" w-[200px] flex justify-end">
              <div
                onClick={() => {
                  setresponseType(null);
                  setresponsedata("");
                  setopen(false);
                  handleNoti();
                }}
                className="flex items-center justify-center pt-2 text-secondary cursor-pointer font-light text-[13px]"
              >
                Close
              </div>
            </div>
          </div>
          <Rule />
          <div className="mt-2">
            <div className="text-gray-700">Meter name:</div>
            <div className="font-semibold font-Kanit text-gray-800">
              {loading ? <Skimmer heigth={30} /> : name}
            </div>
          </div>
          <Rule />
          <div className="pt-1">
            <div className="">
              {!responsedata.INS.length ? (
                <div className="">
                  You don't have any active plan on your meter
                </div>
              ) : (
                responsedata.INS.map((item, i) =>
                  item.includes("END") ? (
                    <div className="mt-2" key={i}>
                      <div className="font-semibold font-Kanit">
                        {planType(item.substring(0, 3))}
                      </div>
                      <div className="">
                        {item.split("##")[1] <= 0
                          ? "You have exhausted your untimed energy"
                          : `You have ${
                              item.split("##")[1]
                            }units left on your device`}
                      </div>
                      <div className=""></div>
                    </div>
                  ) : (
                    <div className="mt-1" key={i}>
                      <div className="font-kanit font-semibold">
                        {planType(item.substring(0, 3))}:
                      </div>
                      <div className="">
                        {item.substring(30).split("##")[1] <= 0
                          ? `You have exhausted your ${
                              item.substring(30).split("##")[0]
                            }W daily energy plan for today. Kindly wait for the next active day.`
                          : `You have about ${
                              item.substring(30).split("##")[0]
                            }watts left for your consumption today`}
                      </div>
                      <div className="">
                        <span className="font-Kanit font-semibold">Time:</span>{" "}
                        Active between on {formatTime(item.substring(10, 14))}{" "}
                        and {formatTime(item.substring(14, 18))}
                      </div>
                      <div>
                        <div className="font-kanit font-semibold">
                          Active days:
                        </div>
                        <div>{DateUI(item.substring(3, 10))}</div>
                      </div>
                    </div>
                  )
                )
              )}
              <div className="pb-4"></div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default BEN;
