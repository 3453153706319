import "./style.css";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import Backdrop from "../../Backdrop";
import { getToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import CloseButton from "../../CloseButton";
import Rule from "../../Rule/Index";
import { Button } from "../../Button";
import { useMQTT } from "../../../context/MqttContext";
import { IoCloseOutline } from "react-icons/io5";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";
import { FiPower } from "react-icons/fi";
import Skimmer from "../../Loader/Skimmer";
import ConnectionImg from "../../../assets/svg/5760417_off_on_power_switch_icon.svg";

const LEN = ({
  responsedata,
  setresponsedata,
  responseType,
  setresponseType,
  restype,
  open,
  setopen,
  handleNoti,
}) => {
  const { mqttEvents } = useMQTT();
  const [loading, setLoading] = useState(false);
  const [genState, setGenState] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [length, setLength] = useState(0);
  const [name, setname] = useState("");

  const { meterid } = responsedata;
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let username = await getToken("spiral_username");
        let res = await axios.post("/submeter/getchannelinfo", {
          username,
          channelid: meterid,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }
        setname(result.message.body[0].devicealias);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
    return () => {};
  }, []);

  const formatDayBinaries = (arr) => {
    let finalArr = [];
    let su = arr[0] == 1 ? finalArr.push("Sunday") : "";
    let m = arr[1] == 1 ? finalArr.push("Monday") : "";
    let t = arr[2] == 1 ? finalArr.push("Tuesday") : "";
    let w = arr[3] == 1 ? finalArr.push("Wednesday") : "";
    let th = arr[4] == 1 ? finalArr.push("Thursday") : "";
    let f = arr[5] == 1 ? finalArr.push("Friday") : "";
    let s = arr[6] == 1 ? finalArr.push("Saturday") : "";
    return finalArr.join(",");
  };
  const formatDate = (p) => {
    return (
      p.substring(0, 2) +
      "-" +
      p.substring(2, 4) +
      "-" +
      p.substring(p.length - 2, p.length)
    );
  };

  var formatTime = (d) => {
    return d.substring(0, 2) + ":" + d.substring(2, d.length);
  };

  // const formatDate = (d) => {};
  const planType = (type) => {
    switch (type) {
      case "ENA":
        return "Daily energy plan";
      case "ENB":
        return "Daily power plan";
      case "ENC":
        return "Cummulative energy plan";
      case "END":
        return "Untimed energy";
      default:
        break;
    }
  };

  // const rest = test.INS.map((item) => {
  //   if (/END/.test(item)) {
  //     return {
  //       plan: "Untimed Energy",
  //       unit: item.split("##")[0].substring(3),
  //       days: "1100011",
  //     };
  //   } else {
  //     return {
  //       plan: planType(item.substring(0, 3)),
  //       days: item.substring(3, 10),
  //       startTime: formatTime(item.substring(10, 14)),
  //       endTime: formatTime(item.substring(14, 18)),
  //       startDate: formatDate(item.substring(18, 24)),
  //       endDate: formatDate(item.substring(24, 30)),
  //       unit: item.split("##")[0].substring(30),
  //     };
  //   }
  // });

  const DateUI = (dateArr) => {
    return (
      <div
        className="lp-bg__color"
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #eee",
          borderRadius: "5px",
          width: "fit-content",
          background: "white",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            color: dateArr[0] == 0 ? "black" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[0] == 0 ? "white" : "black",
          }}
        >
          S
        </div>
        <div
          style={{
            color: dateArr[1] == 0 ? "black" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[1] == 0 ? "white" : "black",
          }}
        >
          M
        </div>
        <div
          style={{
            color: dateArr[2] == 0 ? "black" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[2] == 0 ? "white" : "black",
          }}
        >
          T
        </div>
        <div
          style={{
            color: dateArr[3] == 0 ? "black" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[3] == 0 ? "white" : "black",
          }}
        >
          W
        </div>
        <div
          style={{
            color: dateArr[4] == 0 ? "black" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",

            background: dateArr[4] == 0 ? "white" : "black",
          }}
        >
          T
        </div>
        <div
          style={{
            color: dateArr[5] == 0 ? "black" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",

            background: dateArr[5] == 0 ? "white" : "black",
          }}
        >
          F
        </div>
        <div
          style={{
            color: dateArr[6] == 0 ? "black" : "white",
            padding: "8px 15px",
            border: "1px solid #eee",
            fontSize: "13px",
            background: dateArr[6] == 0 ? "white" : "black",
          }}
        >
          S
        </div>
      </div>
    );
  };

  console.log(responsedata, "////");
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[500px] bg-white drop-shadow-md roundeds-sm p-3">
          {/* top */}
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Last recharge Enquiry
              </div>
              <div className="hidden w-[100%] text-gray-700 font-light">
                Request to power supply status on your device .<br />
              </div>
            </div>

            <div className=" w-[200px] flex justify-end">
              <div
                onClick={() => {
                  setresponseType(null);
                  setresponsedata("");
                  setopen(false);
                  handleNoti();
                }}
                className="flex items-center justify-center pt-2 text-secondary cursor-pointer font-light text-[13px]"
              >
                Close
              </div>
            </div>
          </div>
          <Rule />
          <div className="mt-2">
            <div className="text-gray-700">Meter name:</div>
            <div className="font-semibold font-Kanit text-gray-800">
              {loading ? <Skimmer heigth={30} /> : name}
            </div>
          </div>
          <Rule />
          <div className="pt-1">
            <div className="">
              {!responsedata.INS.length ? (
                <div className="">
                  You don't have any active data plan on your meter
                </div>
              ) : (
                responsedata?.INS.map((item, i) =>
                  item.includes("END") ? (
                    <div className="" key={i}>
                      <div className="font-semibold font-Kanit">
                        {planType(item.substring(0, 3))}
                      </div>
                      <div className="">
                        Date activated:{" "}
                        {moment(item.split("##")[1]).format("lll")}
                      </div>
                      <div className="">
                        Units: {item.split("##")[0].substring(3)}Watts
                      </div>
                    </div>
                  ) : (
                    <div className="mt-1" key={i}>
                      <div className="font-semibold font-Kanit">
                        {planType(item.substring(0, 3))}:
                      </div>
                      <div className="">
                        Date activated:{" "}
                        {moment(item.split("##")[1]).format("lll")}.
                      </div>
                      <div className="">
                        Time: Active between on{" "}
                        {formatTime(item.substring(10, 14))} and{" "}
                        {formatTime(item.substring(14, 18))}
                      </div>
                      <div>
                        <div
                          className=""
                          style={{
                            padding: "0",
                          }}
                        >
                          Days active:
                        </div>
                        <div
                          style={{
                            margin: "3px 0",
                          }}
                        >
                          {DateUI(item.substring(3, 10))}
                        </div>
                      </div>
                    </div>
                  )
                )
              )}
              <div className="pb-4"></div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default LEN;
