import Drawer from "../../../Drawer";
import { useForm } from "react-hook-form";
import { AiOutlineUser } from "react-icons/ai";
import DaysSelector from "../../../Commands/DaysSelector";
import { useState } from "react";
import { DatePicker, TimePicker, Select } from "antd";
import DailyPowerplan from "../../DailyPowerplan";
import DailyEnergyLimit from "../../../Channels/Drawer/DailyEnergyLimit";
import DailyEnergyplan from "../../DailyEnergyplan";
import CummulativeEnergyLimit from "../../../Channels/Drawer/CummulativeEnergyLimit";
import CumulativeEnergyplan from "../../CumulativeEnergyplan";

const AddPlan = ({ open, selectedplantype, refreshBtn, setopen }) => {
  const { Option } = Select;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [daysCount, setDaysCount] = useState("");
  const [daysLength, setDaysLength] = useState("");
  const [step, setstep] = useState(1);

  const { RangePicker } = DatePicker;
  return (
    <Drawer
      title={
        selectedplantype == "powerlimit"
          ? "Daily Power limit"
          : selectedplantype == "energylimit"
          ? "Daily energy limit"
          : selectedplantype == "cummulativelimit"
          ? "Cummulative Energy plan"
          : ""
      }
      caption="fill in the fields below to add a payment plan"
      open={open}
      setopen={setopen}
    >
      {selectedplantype == "powerlimit" && (
        <DailyPowerplan refreshBtn={refreshBtn} setopen={setopen} />
      )}
      {selectedplantype == "energylimit" && (
        <DailyEnergyplan refreshBtn={refreshBtn} setopen={setopen} />
      )}

      {selectedplantype == "cummulativelimit" && (
        <CumulativeEnergyplan refreshBtn={refreshBtn} setopen={setopen} />
      )}
    </Drawer>
  );
};

export default AddPlan;
